import React from "react";
import { Link } from "react-router-dom";
import AccountSetting from "../../Components/Settings/AccountSetting";
import NotificationSetting from "../../Components/Settings/NotificationSetting";
import PastInvoices from "../../Components/Settings/PastInvoices";
import PrivacySetting from "../../Components/Settings/PrivacySetting";


const Settings = () => {
  return (
    <div id="setting_page">
      <div className="welcome-user">
        <h2 className=" content-title mb-0 exDemiBold mb-2">Settings</h2>
      </div>
      <ul className="nav nav-tabs mb-3" id="settingTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18 active"
            id="account-tab"
            data-bs-toggle="tab"
            data-bs-target="#account"
            type="button"
            role="tab"
            aria-controls="account"
            aria-selected="true"
          >
            Account
          </button>
        </li>
        <li>
          <Link
            to={process.env.REACT_APP_STRIPE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
          >
            Billing
            <i className="pi pi-external-link ms-2" style={{ fontSize: "13px" }}></i>
          </Link>
        </li>
        {/* <li className="nav-item" role="presentation">
                  <button
                    className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
                    id="billing-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#billing"
                    type="button"
                    role="tab"
                    aria-controls="billing"
                    aria-selected="false"
                  >
                    Billing Settings
                  </button>
                </li> */}
        {/* <li className="nav-item" role="presentation">
                  <button
                    className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
                    id="pastInvoices-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pastInvoices"
                    type="button"
                    role="tab"
                    aria-controls="pastInvoices"
                    aria-selected="false"
                  >
                    Past Invoices
                  </button>
                </li> */}
        <li className="nav-item" role="presentation">
          <button
            className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
            id="notification-tab"
            data-bs-toggle="tab"
            data-bs-target="#notification"
            type="button"
            role="tab"
            aria-controls="notification"
            aria-selected="false"
          >
            Notification
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
            id="privacy-tab"
            data-bs-toggle="tab"
            data-bs-target="#privacy"
            type="button"
            role="tab"
            aria-controls="privacy"
            aria-selected="false"
          >
            Privacy
          </button>
        </li>
        {/* {User?.roleId === 2 && (
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
                      id="subscription-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#subscription"
                      type="button"
                      role="tab"
                      aria-controls="subscription"
                      aria-selected="false"
                    >
                      Subscription Plan
                    </button>
                  </li>
                )} */}
      </ul>
      <div className="tab-content" id="settingTabContent">
        {/* <!-- ----------------------Account setting tab content----------------------------- --> */}
        <AccountSetting />
        {/* <!-- ----------------------Past Invoices tab content----------------------------- -->
        <PastInvoices
          downloadInvoiceData={downloadInvoiceData?.data?.data}
        /> */}
        {/* <!-- ----------------------Notification setting tab content----------------------------- --> */}
        <NotificationSetting />
        {/* <!-- ----------------------Pirivacy setting tab content----------------------------- --> */}
        <PrivacySetting />
      </div>
    </div>
  );
};

export default Settings;
