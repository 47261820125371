import React from "react";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import CreateCoupon from "./CreateCoupon";
import CouponList from "./CouponList";
import { useDispatch, useSelector } from "react-redux";
import { getCouponApi } from "../../Redux/Features/couponReducer/couponSlice";


const Coupon = () => {

    const dispatch = useDispatch();
    const { getCouponApiData } = useSelector((state) => state.Coupon);

    const handleTabChange = (event) => {
        if (event.target.id === 'couponList-tab') {
            dispatch(getCouponApi());
        }
    };

    return (
        <>
            <div id="setting_page">
                <Header />
                <div id="wrapper">
                    <SideBar />
                    <section id="content-wrapper">
                        <div class="welcome-user">
                            <h2 className=" content-title mb-0 exDemiBold mb-2">Create Discount Coupon</h2>
                        </div>
                        <ul className="nav nav-tabs mb-3" id="settingTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18 active"
                                    id="createCoupon-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#createCoupon"
                                    type="button"
                                    role="tab"
                                    aria-controls="createCoupon"
                                    aria-selected="true"
                                >
                                    Create Coupon
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link color-muted px-3 pb-2 border-0 exMedium fs-18"
                                    id="couponList-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#couponList"
                                    type="button"
                                    role="tab"
                                    aria-controls="couponList"
                                    aria-selected="false"
                                    onClick={handleTabChange}
                                >
                                    Coupon List
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="couponTabContent">
                            {/* <!-- ----------------------CreateCoupon tab content----------------------------- --> */}
                            <CreateCoupon />

                            {/* <!-- ----------------------CouponList tab content----------------------------- --> */}
                            <CouponList
                                getCouponApiData={getCouponApiData.data}
                            />
                        </div>

                    </section>
                </div>
            </div>
        </>
    );
};

export default Coupon;
