import { InputSwitch } from "primereact/inputswitch";
import React, { useState } from "react";
import { useActivateEmailNotifications, useActivateWebNotifications } from "../../../hooks/mutations/notification";
import { useUserData } from "../../../hooks/queries/auth";

function NotificationSetting() {
  const [disable, setDisable] = useState(false);

  const getUserData = useUserData();
  const userDetails = getUserData?.data?.result;

  const activateWebNotifications = useActivateWebNotifications();
  const activateEmailNotifications = useActivateEmailNotifications();

  const handleNotification = (e) => {
    setDisable(true);
    switch (e.target.name) {
      case "web":
        activateWebNotifications.mutate({
          webNotification: e.target.value,
        });
        break;
      case "email":
        activateEmailNotifications.mutate({
          emailNotification: e.target.value,
        });
        break;
      default:
    }
    setTimeout(() => {
      setDisable(false);
    }, 1000);
  };

  return (
    <div
      className="tab-pane fade"
      id="notification"
      role="tabpanel"
      aria-labelledby="notification-tab"
    >
      <div className=" py-3 border-bottom">
        <div>
          <h4 className="fs-22 exMedium">Notification Settings</h4>
          <p className="fs-14 text-navy-light exLight mb-2 mb-md-0">
            View and update your notification settings.
          </p>
        </div>
      </div>
      <div className="row py-3 border-bottom mx-1">
        <div className="col-md-6 col-10 px-0">
          <label className="form-check-label exMedium mb-2" for="webToggle">
            Web Notifications
          </label>
          {/* <p className="fs-14 mb-0 text-navy-light exLight">
            Get updates on your slack account.
          </p> */}
        </div>
        <div className="col-md-6 col-2 px-0">
          <div className="toggle-switch form-check form-switch float-end toggleinput">
            <InputSwitch
              name="web"
              checked={userDetails?.webNotification}
              onChange={handleNotification}
              disabled={disable}
            />
          </div>
        </div>
      </div>
      <div className="row py-3 border-bottom mx-1">
        <div className="col-md-6 col-10 px-0">
          <label className="form-check-label exMedium mb-2" for="emailToggle">
            Email Notifications
          </label>
          {/* <p className="fs-14 mb-0 text-navy-light exLight">
            Email are sent to test@gmail.com
          </p> */}
        </div>
        <div className="col-md-6 col-2 px-0">
          <div className="toggle-switch form-check form-switch float-end toggleinput">
            <InputSwitch
              name="email"
              checked={userDetails?.emailNotification}
              onChange={handleNotification}
              disabled={disable}
            />
          </div>
        </div>
      </div>
      {/* <div className="row py-3 border-bottom mx-1">
        <div className="col-md-6 col-10 px-0">
          <label className="form-check-label exMedium mb-2" for="slackToggle">
            Slack Notifications
          </label>
        </div>
        <div className="col-md-6 col-2 px-0">
          <div className="toggle-switch form-check form-switch float-end toggleinput">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="slackToggle"
              // checked={userDetails?.result?.emailNotification}
              name="slack"
              // onChange={handleNotification}
              // disabled={disable}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}
export default NotificationSetting;
