import axios from "axios";
import {
  BASE_URL,
  CHANGEPASSWORD,
  FORGETPASSWORD_API,
  GETUSERDETAILS,
  UPDATEUSERPROFILE,
  GETUSERLIST,
  HOSTAWAYAUTH,
  GENRATEACCESSTOKENGUESTY,
  HOSTAWAYCHECKCONNECTION,
  HOSTAWAYLISTING,
  LOGIN_API,
  LOGOUT_API,
  REGISTER_AUTH,
  REGISTER_API,
  RESETPASSWORD_API,
  MANUALUPDATELISTING,
  AUTOUPDATELISTING,
  INACTIVATEUSER,
  INCLUDEEXCLUDEDISCRIPTION,
  CHECK_PAYMENT_ABOUT_PAYMENT_ID,
  INACTIVE_HOSTAWAY_LISTING,
  ADD_PAYMENT_CARD,
  GET_BILLIG_DETAILS,
  ADD_AND_UPDATE_BILLIG_DETAILS,
  RESET_SINGLE_AND_MULTIPLE_PROPERTY_DATA,
  GOOGLE_LOGIN,
  GOOGLE_LOGIN_CALLBACK,
  MAKE_ADMIN_IMPERSONATE_USER,
  ADMIN_INCRESSES_LIMIT_LISTING

} from "../../../Config/Config";
import { getToken, setEmail, setToken, setUser, setToken2, getToken2 } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";

// Register Auth API
const registerAuth = async (userData) => {
  const response = await axios.post(
    BASE_URL + REGISTER_AUTH,
    userData
  );
  return response.data;
};

// User Registration API
const userRegistrationApi = async (userData) => {
  const response = await axios.post(
    BASE_URL + REGISTER_API,
    userData
  );
  return response.data;
};

// login API
const LoginAPI = async (userData) => {
  const response = await axios.post(BASE_URL + LOGIN_API, userData);
  if (response?.data?.success) {
    setToken(response?.data?.token);
    setUser({ ...response?.data?.data });
  }
  return response?.data?.data;
};

// Logout API
const LogoutAPI = async (loginId) => {
  localStorage.removeItem("otamiserToken");
  localStorage.removeItem("otamiserUser");
  window.location.reload(false);
  const response = await axios.post(BASE_URL + LOGOUT_API, loginId);
  return response;
};

// Forget password API
const ForgetpasswordAPI = async (userData) => {
  const response = await axios.post(
    BASE_URL + FORGETPASSWORD_API,
    userData
  );
  if (response.data) {
    setEmail(userData.email)
  }
  return response.data;
};

// Reset password API
const ResetpasswordAPI = async (userData) => {
  const response = await axios.post(
    BASE_URL + RESETPASSWORD_API,
    userData
  );
  return response.data;
};

// Get User Profile details  API
const GetUserDetailsApi = async () => {
  const response = await axios.get(
    BASE_URL + GETUSERDETAILS,
    HeaderToken(getToken())
  );
  return response?.data;
};

// Get User Profile details  API
const GetUserListApi = async () => {
  const response = await axios.get(
    BASE_URL + GETUSERLIST,
    HeaderToken(getToken())
  );
  return response?.data;
};

// Change password API
const ChangePasswordAPI = async (userData) => {
  const response = await axios.post(
    BASE_URL + CHANGEPASSWORD,
    userData,
    HeaderToken(getToken())
  );
  return response?.data;
};

// Hostaway Auth API
const HostawayAuthAPI = async (userData) => {
  const response = await axios.post(
    BASE_URL + HOSTAWAYAUTH,
    userData,
    HeaderToken(getToken())
  );
  return response.data;
};


// Hostaway Auth API
const GenrateAccessTokenForGuestyAPI = async (userData) => {
  const response = await axios.post(
    BASE_URL + GENRATEACCESSTOKENGUESTY,
    userData,
    HeaderToken(getToken())
  );
  return response.data;
};

//  HostawayListingsAPI API
const HostawayListingsAPI = async (filters) => {
  const response = await axios.post(
    BASE_URL + HOSTAWAYLISTING,
    filters,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response.data;
};

// Update Profile  API
const EditProfileAPI = async (userData) => {
  const response = await axios.post(
    BASE_URL + UPDATEUSERPROFILE,
    userData,
    {
      headers: {
        ...(HeaderToken(getToken()).headers),
        'Content-Type': 'multipart/form-data'
      }
    }

  );
  return response.data;
};

// CheckHoatawayConnectionApi  API
const CheckHoatawayConnectionApi = async () => {
  const response = await axios.get(
    BASE_URL + HOSTAWAYCHECKCONNECTION,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response.data;
};

// Manual Update Listing  API
const manualUpdateListing = async (userData) => {
  const response = await axios.post(
    BASE_URL + MANUALUPDATELISTING,
    userData,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response.data;
};

// Automatically Update Listing  API
const autoUpdateListing = async (userData) => {
  const response = await axios.post(
    BASE_URL + AUTOUPDATELISTING,
    userData,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response.data;
};

// Include/Exclude discription update listing  API
const includeExcludeUpdateListing = async (data) => {
  const response = await axios.post(
    BASE_URL + INCLUDEEXCLUDEDISCRIPTION,
    data,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response.data;
};

// InActivate user by Admin API
const inActivateUser = async (userData) => {
  const response = await axios.post(
    BASE_URL + INACTIVATEUSER,
    userData,
    HeaderToken(getToken())
  );
  return response.data;
};

// Check Payment About Payment Id  API
const checkPaymentAboutPaymentId = async (userData) => {
  const response = await axios.post(
    BASE_URL + CHECK_PAYMENT_ABOUT_PAYMENT_ID,
    userData,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response.data;
};

// Inactive Hostaway Listing  API
const inactiveHostawayListing = async (userData) => {
  const response = await axios.post(
    BASE_URL + INACTIVE_HOSTAWAY_LISTING,
    userData,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response.data;
};

//add Payment Card
const addPaymentCard = async (userData) => {
  const response = await axios.post(
    BASE_URL + ADD_PAYMENT_CARD,
    userData,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response.data;
};

// get Billing Details
const getBillingDetails = async () => {
  const response = await axios.get(
    BASE_URL + GET_BILLIG_DETAILS,
    HeaderToken(getToken())
  );
  return response.data;
}

//add And update Billing Details
const addAndupdateBillingDetails = async (userData) => {
  const response = await axios.post(
    BASE_URL + ADD_AND_UPDATE_BILLIG_DETAILS,
    userData,
    HeaderToken(getToken())
  );
  return response.data;
};

//reset Single And Multiple Property Data
const resetSingleAndMultipleProperty = async (userData) => {
  const response = await axios.post(
    BASE_URL + RESET_SINGLE_AND_MULTIPLE_PROPERTY_DATA,
    userData,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response.data;
};

// GOOGLE lOGIN API
const googleLogin = async () => {
  const response = await axios.get(
    BASE_URL + GOOGLE_LOGIN,
    HeaderToken(getToken())
  );
  return response?.data;
};

// GOOGLE lOGIN CALL BACK API
const googleLoginCallback = async () => {
  const response = await axios.get(
    BASE_URL + GOOGLE_LOGIN_CALLBACK,
    HeaderToken(getToken())
  );
  return response?.data;
};

//make Admin Impersonate User API
const makeAdminImpersonateUser = async (userData) => {
  const response = await axios.post(
    BASE_URL + MAKE_ADMIN_IMPERSONATE_USER,
    userData,
    HeaderToken(getToken())
  );
  if (response?.data?.success) {
    setToken2(response?.data?.token);
  }
  return response.data;
};

//make Admin Increase Limit API
const adminIncressesLimitOfListing = async (userData) => {
  const response = await axios.post(
    BASE_URL + ADMIN_INCRESSES_LIMIT_LISTING,
    userData,
    HeaderToken(getToken())
  );
  return response.data;
};

const authService = {
  registerAuth,
  userRegistrationApi,
  LoginAPI,
  LogoutAPI,
  ForgetpasswordAPI,
  ResetpasswordAPI,
  GetUserDetailsApi,
  ChangePasswordAPI,
  GetUserListApi,
  HostawayAuthAPI,
  GenrateAccessTokenForGuestyAPI,
  HostawayListingsAPI,
  EditProfileAPI,
  CheckHoatawayConnectionApi,
  manualUpdateListing,
  autoUpdateListing,
  inActivateUser,
  includeExcludeUpdateListing,
  checkPaymentAboutPaymentId,
  inactiveHostawayListing,
  addPaymentCard,
  getBillingDetails,
  addAndupdateBillingDetails,
  resetSingleAndMultipleProperty,
  googleLogin,
  googleLoginCallback,
  makeAdminImpersonateUser,
  adminIncressesLimitOfListing
};

export default authService;
