import { BreadCrumb } from "primereact/breadcrumb";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Tag } from "primereact/tag";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import ImgNotAvailable from "../../Assets/images/no_image.png";
import { LISTING } from "../../Routes/Constant";
import { DateFormat, formatedDateTime } from "../../Utils/constant/commonFunction";
import MapComponent from './MapComponent';

import { useAddAttraction, useAddEvent } from "../../hooks/mutations/properties";
import { useAllAttractions, useAllEvents, useAudits, useEventsById, usePopertyById, usePropertyStats } from "../../hooks/queries/properties";
import { useDashboardRefresh } from "../../hooks/queries/userDashboard";
import PropertyActions from "./actions";
import AutomateListing from "./actions/automate";
import ResetListing from "./actions/reset";
import ListingSettings from "./actions/settings";
import SyncNow from "./actions/sync";
import Description from "./description";

function PropertyDetail({ id }) {
  useDashboardRefresh();
  const [expandedRows, setExpandedRows] = useState(null);
  const [attCheckboxDisable, setAttCheckboxDisable] = useState(false);
  const [eventCheckboxDisable, setEventCheckboxDisable] = useState(false);

  const navigate = useNavigate();

  const getPropertyById = usePopertyById(id);
  const propertyListing = getPropertyById?.data?.result?.listing;
  const originalProperty = getPropertyById?.data?.result?.originalContent;
  const currentProperty = getPropertyById?.data?.result?.currentContent;
  const getEvents = useEventsById(propertyListing?.id); // TODO: use id property when it becomes listingId
  const eventData = getEvents?.data?.result;
  const getAudits = useAudits(id);
  const audits = getAudits?.data?.result;

  const getAllEvents = useAllEvents(id);
  const allEvents = getAllEvents?.data?.result;

  const getAllAttractions = useAllAttractions(id);
  const allAttractions = getAllAttractions?.data?.result;

  const getPropertyStats = usePropertyStats(propertyListing?.id); // TODO: use id property when it becomes listingId
  const propertyStats = getPropertyStats?.data?.result;

  const imageLength = propertyListing?.images?.length || 0;
  const imageUrl1 = propertyListing?.images?.[0]?.url || process.env.PUBLIC_URL + ImgNotAvailable;
  const imageUrl2 = propertyListing?.images?.[1]?.url || process.env.PUBLIC_URL + ImgNotAvailable;
  const imageUrl3 = propertyListing?.images?.[2]?.url || process.env.PUBLIC_URL + ImgNotAvailable;
  const imageUrl4 = propertyListing?.images?.[3]?.url || process.env.PUBLIC_URL + ImgNotAvailable;
  const imageUrl5 = propertyListing?.images?.[4]?.url || process.env.PUBLIC_URL + ImgNotAvailable;
  const airbnbListingUrl = propertyListing?.airbnbUrl;
  const airbnbListingId = airbnbListingUrl?.split("/").pop();

  const isLoading = !propertyListing;

  const attractionMutation = useAddAttraction();

  const handleAttractionsCheckbox = async (e, attractionsItem) => {
    setAttCheckboxDisable(true);
    attractionMutation.mutateAsync({
      "recordId": id,
      "attraction": attractionsItem,
    })
    setTimeout(() => {
      setAttCheckboxDisable(false);
    }, 1000);
  };

  const eventMutation = useAddEvent();

  const handleEventCheckbox = async (e, eventItem) => {
    setEventCheckboxDisable(true);
    eventMutation.mutateAsync({
      "recordId": id,
      "listingId": propertyListing?.id,
      "eventId": eventItem?.id,
    })
    setTimeout(() => {
      setEventCheckboxDisable(false);
    }, 1000);
  };

  const goToNotFoundPage = () => {
    navigate("/not-found");
  };

  const tableFooter = () => {

    return (
      <>
        <div className="text-end p-2 me-3">
          <NavLink
            to={`/updated_history/${id}`}
            className="text-purple ms-auto"
          >
            View All
          </NavLink>
        </div>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        {/* <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField> */}
      </div>
    );
  };

  const titleBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p className="fs-14 m-0 lh-lg">{rowData.title}</p>
        </div>
      </>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p className="fs-14 m-0 lh-lg">{DateFormat(rowData?.createdAt)}</p>
        </div>
      </>
    );
  };

  const includedData = (rowData) => {
    return (
      <>
        <Tag
          className={
            rowData.included == "Event"
              ? "px-3 py-2 mint-30 text-navy"
              : rowData.included == "Attraction"
                ? "px-3 py-2 lime-30 text-navy"
                : "px-3 py-2 purple-15 text-navy"
          }
          value={rowData?.logType.replace(/\b\w/g, char => char.toUpperCase())}
          rounded
        ></Tag>
      </>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData;
  };

  const onRowClick = (e) => {
    const rowId = e.data.id;
    const currentExpandedRows = { ...expandedRows };

    if (currentExpandedRows[rowId] === true) {
      delete currentExpandedRows[rowId];
    } else {
      currentExpandedRows[rowId] = true;
    }
    setExpandedRows(Object.keys(currentExpandedRows)?.length ? currentExpandedRows : null);
  }

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="p-3">
        <h6 className="exMedium fs-18 color-black">Summary</h6>
        <div className="font-size-16">
          {rowData?.airbnbSummary?.split('\n').map((item, index) => (
            <div key={index}>
              {item?.split(': ')[0]} {item?.split(': ')[1]}
            </div>
          ))}
        </div>
        <h6 className="exMedium fs-18 color-black margin-top-20">Space</h6>
        <div className="font-size-16">
          {rowData?.airbnbSpace?.split('\n\n').map((section, index) => (
            <div key={index}>
              {section?.split('\n')[0]}
              {section?.split('\n').slice(1).map((item, index) => (
                <div key={index}>{item}</div>
              ))}
              <br />
            </div>
          ))}
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const items = [
    { label: "Listings", url: LISTING },
    { label: "Listing Details" },
  ];
  const home = { icon: "pi pi-home", url: "/" };

  return (
    <>
      <div className="property_details">
        <BreadCrumb
          model={items}
          home={home}
          className="border-0 text-decoration-none mb-2"
        />
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="welcome-user">
            <h2 className="content-title mb-0 exDemiBold mb-2">
              Property Details
            </h2>
            <p className="text-navy-light mb-3">
              Last updated: {formatedDateTime(propertyListing?.updatedAt)}
            </p>
          </div>
          <PropertyActions actions={[
            { Component: AutomateListing, props: { properties: [propertyListing] } },
            { Component: SyncNow, props: { properties: [propertyListing?.recordId], isPaid: propertyListing?.isPaid } },
            { Component: ListingSettings, props: { id } },
            { Component: ResetListing, props: { primaryIds: [id] } },
          ]} />
        </div>

        {!isLoading ? (
          <div className="img-gallery ">
            <div className="row g-3 ">
              <div className="col-6">
                <img
                  src={imageUrl1}
                  onError={(e) => {
                    e.target.src =
                      process.env.PUBLIC_URL +
                      "/Assets/images/img_placeholder.svg"; // replace with a default image
                  }}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-6 ">
                <div className="row g-3">
                  <div className="col-6 d-md-block d-none">
                    <img
                      src={imageUrl2}
                      onError={(e) => {
                        e.target.src =
                          process.env.PUBLIC_URL +
                          "/Assets/images/img_placeholder.svg"; // replace with a default image
                      }}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <img
                      src={imageUrl3}
                      onError={(e) => {
                        e.target.src =
                          process.env.PUBLIC_URL +
                          "/Assets/images/img_placeholder.svg"; // replace with a default image
                      }}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-6 d-md-block d-none">
                    <img
                      src={imageUrl4}
                      onError={(e) => {
                        e.target.src =
                          process.env.PUBLIC_URL +
                          "/Assets/images/img_placeholder.svg"; // replace with a default image
                      }}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="position-relative">
                      <img
                        src={imageUrl5}
                        onError={(e) => {
                          e.target.src =
                            process.env.PUBLIC_URL +
                            "/Assets/images/img_placeholder.svg"; // replace with a default image
                        }}
                        className="img-fluid "
                        alt=""
                      />
                      <div className="position-absolute w-100 h-100 top-0 left-0"></div>
                      <span
                        className="position-absolute top-50 start-50 translate-middle display-4 exDemiBold fw-bold text-white">
                        {imageLength > 4 ? `+${imageLength - 4}` : "+0"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "8%",
            }}
          >
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <ProgressSpinner
                style={{ height: "50px" }}
                strokeWidth="3"
                animationDuration="0.8s"
              />
            </div>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between my-4 flex-wrap">
          <h4 className="exMedium">{propertyListing?.name}</h4>
          <div>
            {airbnbListingUrl ? (
              <a
                style={{ textDecoration: "none" }}
                href={airbnbListingUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-lg button-navy-outline rounded-pill me-3 mb-sm-0 mb-2">
                  Airbnb Listing
                </button>
              </a>
            ) : (
              <button
                className="btn btn-lg button-navy-outline rounded-pill me-3 mb-sm-0 mb-2"
                onClick={goToNotFoundPage}
                disabled={isLoading}
              >
                Airbnb Listing
              </button>
            )}

            <a
              style={{ textDecoration: "none" }}
              href={`https://www.airbnb.com/performance/conversion/p3_impressions?lid%5B%5D=${airbnbListingId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn btn-lg button-navy rounded-pill mb-sm-0 mb-2">
                Airbnb Performance
              </button>
            </a>
          </div>
        </div>

        <div className="property-detail_analytics">
          <div className="row gy-4">
            <div className="col-md-4">
              <div
                className="card border-0 shadow-sm d-flex align-items-center justify-content-evenly flex-row rounded-17 p-3 purple-15">
                <h3 className="exBold fs-2 mb-0 me-1">
                  $ {isNaN(Number(propertyStats?.monthlyRevenue)) ? "-" : Number(propertyStats?.monthlyRevenue).toFixed(2)}
                </h3>
                <div>
                  <p className="mb-1 exMedium text-nowrap">
                    This Month's Revenue
                  </p>
                  {/* <button className="btn btn-sm rounded-pill me-2 btn-secondary purple-25 text-navy border-0">
                      <img
                        className="me-1"
                        src={
                          process.env.PUBLIC_URL +
                          "/Assets/images/icons/growth.svg"
                        }
                        alt=""
                      />
                      20%
                    </button> */}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="card border-0 shadow-sm d-flex align-items-center justify-content-evenly flex-row rounded-17 p-3 lime-20 h-100">
                <h3 className="exBold fs-2 mb-0 me-2">
                  {isNaN(Number(propertyStats?.avgReviewScore)) ? "-" : Number(propertyStats?.avgReviewScore).toFixed(1)}/5
                </h3>
                <div>
                  <p className="mb-1 exMedium">Average Review Score</p>
                  {/* <div className="rating_star d-flex align-items-center">
                      <InputIcon className="pi pi-star-fill fs-5" />
                      <InputIcon className="pi pi-star-fill fs-5" />
                      <InputIcon className="pi pi-star-fill fs-5" />
                      <InputIcon className="pi pi-star-fill fs-5" />
                      <InputIcon className="pi pi-star fs-5" />
                      <button className="btn btn-sm rounded-pill mx-2 btn-secondary mint-40 text-navy border-0">
                        <img
                          className="me-1"
                          src={
                            process.env.PUBLIC_URL +
                            "/Assets/images/icons/growth.svg"
                          }
                          alt=""
                        />
                        18%
                      </button>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="card border-0 shadow-sm d-flex align-items-center justify-content-evenly flex-row rounded-17 p-3 mint-30">
                <h3 className="exBold fs-2 mb-0 me-1">
                  {propertyStats?.bookingPaceMomentum ?? "-"}
                </h3>
                <div>
                  <p className="mb-1 exMedium text-nowrap">
                    Amount of Bookings
                  </p>
                  {/* <button className="btn btn-sm rounded-pill me-2 btn-secondary lime-40 text-navy border-0">
                      <img
                        className="me-1 rotate-180"
                        src={
                          process.env.PUBLIC_URL +
                          "/Assets/images/icons/growth.svg"
                        }
                        alt=""
                      />
                      34%
                    </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-8 col-lg-7">
            <Description
              id={id}
              ogTitle={originalProperty?.title}
              title={currentProperty?.title}
              ogSummary={originalProperty?.airbnbSummary}
              summary={currentProperty?.airbnbSummary}
              ogSpace={originalProperty?.airbnbSpace}
              space={currentProperty?.airbnbSpace}
              initialValues={{
                recordId: id,
                descriptionAdditional: propertyListing?.descriptionAdditional,
                descriptionInclude: propertyListing?.descriptionInclude,
                descriptionExclude: propertyListing?.descriptionExclude,
              }}
              touristAttractions={propertyListing?.addedTouristAttraction}
              events={eventData}
            />

            {/* <!-- -------------------------------------Accordions start---------------------------- --> */}
            <div className="accordion" id="accords">
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="updateAccords">
                  <button
                    className="accordion-button collapsed exMedium fs-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseUpdate"
                    aria-expanded="false"
                    aria-controls="collapseUpdate"
                  >
                    Update History
                  </button>
                </h2>
                <div
                  id="collapseUpdate"
                  className="accordion-collapse collapse"
                  aria-labelledby="updateAccords"
                  data-bs-parent="#accords"
                >
                  <div className="accordion-body">
                    <DataTable
                      className="shadow-sm rounded-17 my-3 "
                      expandedRows={expandedRows}
                      onRowToggle={(e) => setExpandedRows(e.data)}
                      rowExpansionTemplate={rowExpansionTemplate}
                      header={header}
                      showGridlines
                      stripedRows
                      removableSort
                      value={audits}
                      dataKey="id"
                      tableStyle={{ minWidth: "50rem" }}
                      responsiveLayout="scroll"
                      size="small"
                      rows={5}
                      paginator
                      rowsPerPageOptions={[5, 20, 30, 50]}
                      paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      footer={tableFooter}
                      onRowClick={onRowClick}
                    >
                      <Column
                        field="title"
                        header="Title"
                        sortable
                        body={titleBodyTemplate}
                      ></Column>
                      <Column
                        field="date"
                        header="Date"
                        body={dateBodyTemplate}
                      ></Column>

                      <Column
                        field="included_updates"
                        header="Update Mode"
                        sortable
                        body={includedData}
                      ></Column>

                      {/*<Column*/}
                      {/*  field="update_mode"*/}
                      {/*  header="Update Mode"*/}
                      {/*  sortable*/}
                      {/*  body="Automated"*/}
                      {/*></Column>*/}
                      <Column
                        className=""
                        header="-"
                        expander={allowExpansion}
                      />
                    </DataTable>
                  </div>
                </div>
              </div>


              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="additionalAccords">
                  <button
                    className="accordion-button collapsed exMedium fs-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseAdditional"
                    aria-expanded="false"
                    aria-controls="collapseAdditional"
                  >
                    Additional Info
                  </button>
                </h2>
                <div
                  id="collapseAdditional"
                  className="accordion-collapse collapse"
                  aria-labelledby="additionalAccords"
                  data-bs-parent="#accords"
                >
                  <div className="accordion-body">
                    <div className="row mb-4 gy-3 flex-wrap">
                      <h6 className="fs-18 exMedium">What this place offers</h6>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Name: {propertyListing?.name || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Person Capacity: {propertyListing?.capacity || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Room Type: {propertyListing?.roomType || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Bathroom Type: {propertyListing?.bathroomType || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Guests Included: {propertyListing?.guestsIncluded || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Min Nights: {propertyListing?.minNights || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Max Nights: {propertyListing?.maxNights || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Cancellation Policy: {propertyListing?.cancellationPolicy || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Cleaning Fee: $ {propertyListing?.cleaningFee || 0}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        CheckIn TimeStart: {propertyListing?.checkInTimeStart || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        CheckIn TimeEnd: {propertyListing?.checkInTimeEnd || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        CheckOut Time: {propertyListing?.checkOutTime || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Checkin Fee: $ {propertyListing?.checkinFee || 0}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Weekly Discount: {propertyListing?.weeklyDiscount || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Time Zone: {propertyListing?.timeZoneName || "N/A"}
                      </div>
                    </div>
                    <div className="row mb-4 gy-3 flex-wrap">
                      <h6 className="fs-18 exMedium">Address</h6>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Country: {propertyListing?.country || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        State: {propertyListing?.state || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        City: {propertyListing?.city || "N/A"}
                      </div>
                      <div className="col-md-4 d-flex align-items-center ">
                        <span>
                          <i className="pi pi-circle-fill"
                            style={{ fontSize: '0.7rem', marginRight: "10px", color: "var(--purple)" }}></i>
                        </span>
                        Zip Code: {propertyListing?.zipCode || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item mt-4">
                <h2 className="accordion-header" id="amenitiesAccords">
                  <button
                    className="accordion-button collapsed exMedium fs-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseAmenities"
                    aria-expanded="false"
                    aria-controls="collapseAmenities"
                  >
                    Amenities & Features
                  </button>
                </h2>
                <div
                  id="collapseAmenities"
                  className="accordion-collapse collapse"
                  aria-labelledby="amenitiesAccords"
                  data-bs-parent="#accords"
                >
                  <div className="accordion-body accordion-bodyss">

                    {(() => {
                      switch (propertyListing?.dataSource) {
                        case "guesty":
                          return (
                            <>
                              {
                                propertyListing?.amenities?.length > 0 ? (
                                  propertyListing?.amenities?.map((amenities, index) => (
                                    <div key={index} className="col-md-4 d-flex align-items-center">
                                      <span>
                                        <i className="pi pi-circle-fill" style={{
                                          fontSize: '0.7rem',
                                          marginRight: "10px",
                                          color: "var(--purple)"
                                        }}></i>
                                      </span>
                                      {amenities}
                                    </div>
                                  ))
                                ) : (
                                  <div className="col-md-4 d-flex align-items-center">
                                    <span>No amenities available.</span>
                                  </div>
                                )
                              }
                            </>
                          );

                        case "hostaway":
                          return (
                            <>
                              {
                                propertyListing?.amenities?.length > 0 ? (
                                  propertyListing?.amenities?.map((amenities, index) => (
                                    <div key={index} className="col-md-4 d-flex align-items-center">
                                      <span>
                                        <i className="pi pi-circle-fill" style={{
                                          fontSize: '0.7rem',
                                          marginRight: "10px",
                                          color: "var(--purple)"
                                        }}></i>
                                      </span>
                                      {amenities?.amenityName}
                                    </div>
                                  ))
                                ) : (
                                  <div className="col-md-4 d-flex align-items-center">
                                    <span>No amenities available.</span>
                                  </div>
                                )
                              }
                            </>
                          );

                        default:
                          return null; // Optional: handle the default case if needed.
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- -------------------------------------Accordions end---------------------------- --> */}

            <div className="border rounded-17 mt-4 p-4">
              <div className="d-flex justify-content-between">
                <h5 className="exMedium"> Location</h5>
                <h5 className="exMedium">
                  <i className="pi pi-circle-fill icon-style"></i>
                  Current Property &nbsp;&nbsp;&nbsp;
                  <i className="pi pi-circle-fill icon-style2"></i>
                  Activated Properties
                </h5>
              </div>
              <MapComponent recordId={id} />
            </div>
          </div>
          <div className="col-xl-4 col-lg-5">
            <aside className="aside_section sticky-top">
              <div className="border rounded-17 mt-4 ">
                <div className="d-flex align-items-center justify-content-between p-4">
                  <h4 className="exMedium mb-0">Local Selling Points</h4>
                </div>
                <hr className="mx-4 mt-2 mb-4" />

                {/* <!-- -------------------------------------------------Nearby tourist carousel start-------------------------------- --> */}

                <div
                  id="attractionCarousel"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="d-flex align-items-center justify-content-between px-4">
                    <h5 className="exMedium mb-0">Nearby Tourist Attractions</h5>
                    <div className="d-flex justify-content-end">
                      <button
                        className="carousel-control-prev rounded-circle position-static me-3"
                        type="button"
                        data-bs-target="#attractionCarousel"
                        data-bs-slide="prev"
                      >
                        <span className=" fs-1 color-dark" aria-hidden="true">
                          &#8249;{" "}
                        </span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next rounded-circle position-static"
                        type="button"
                        data-bs-target="#attractionCarousel"
                        data-bs-slide="next"
                      >
                        <span className=" fs-1 color-dark" aria-hidden="true">
                          &#8250;{" "}
                        </span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                  <div className="carousel-inner p-4 pb-2">
                    {
                      allAttractions ? (
                        allAttractions?.length > 0 ? (
                          allAttractions?.map((attractionsItem, index) => (
                            <div
                              className={`carousel-item ${index === 0 ? "active" : ""}`}
                              key={index}
                            >
                              <div className="card mb-3 rounded-2 ">
                                <div className="row g-0">
                                  <div className="col-4 ps-3 py-3">
                                    <img
                                      src={attractionsItem?.thumbnailUrl || process.env.PUBLIC_URL + "/Assets/images/attractions_placeholder.svg"}
                                      className="attractImg img-fluid rounded-start rounded-2"
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-8">
                                    <div className="card-body">
                                      <h5 className="card-title exMedium color-black ">
                                        {attractionsItem?.name}
                                      </h5>
                                      <p className="card-text mb-5">
                                        {attractionsItem?.description}
                                      </p>
                                      <div className="position-absolute end-0 bottom-0 p-3">
                                        <input
                                          className="form-check-input ms-2 m-0"
                                          type="checkbox"
                                          id={`checkbox-${index}`}
                                          value={attractionsItem?.name}
                                          disabled={attCheckboxDisable}
                                          checked={propertyListing?.addedTouristAttraction?.some((attraction) => attraction.name === attractionsItem.name)}
                                          onChange={(e) =>
                                            handleAttractionsCheckbox(e, attractionsItem)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="carousel-item active">
                            <div className="card mb-3 rounded-2">
                              <div className="card-body">
                                <h5 className="card-title exMedium color-black">No tourist attractions found.</h5>
                                <p className="card-text">Please try searching again or check back later.</p>
                              </div>
                            </div>
                          </div>
                        )
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "auto",
                            marginRight: "auto",
                            justifyContent: "center"
                          }}
                        >
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ProgressSpinner
                              style={{ height: "50px" }}
                              strokeWidth="3"
                              animationDuration="0.8s"
                            />
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>

                {/* <!-- -------------------------------------------------Nearby tourist carousel end-------------------------------- -->

            <!-- -------------------------------------------------Nearby events carousel start-------------------------------- --> */}

                <hr className="mx-4 mb-4" />
                <div
                  id="eventCarousel"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="d-flex align-items-center justify-content-between px-4">
                    <h5 className="exMedium mb-0">Nearby Events</h5>
                    <div className="d-flex justify-content-end">
                      <button
                        className="carousel-control-prev rounded-circle position-static me-3"
                        type="button"
                        data-bs-target="#eventCarousel"
                        data-bs-slide="prev"
                      >
                        <span className="fs-1 color-dark" aria-hidden="true">
                          &#8249;
                        </span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next rounded-circle position-static"
                        type="button"
                        data-bs-target="#eventCarousel"
                        data-bs-slide="next"
                      >
                        <span className="fs-1 color-dark" aria-hidden="true">
                          &#8250;
                        </span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                  <div className="carousel-inner p-4 pb-1">
                    {
                      allEvents?.length > 0 ? (
                        allEvents?.map((eventItem, index) => {
                          const isChecked = eventData?.some(event => event.id === eventItem.id);
                          return (
                            <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
                              <div className="card mb-3 rounded-2">
                                <div className="row g-0">
                                  <div className="col-4 ps-3 py-3">
                                    <img
                                      src={JSON.parse(eventItem?.imageUrls?.[0]).url || process.env.PUBLIC_URL + "/Assets/images/events_placeholder.svg"}
                                      className="evenImag img-fluid rounded-start rounded-2"
                                      alt="..."
                                    />
                                  </div>
                                  <div className="col-8">
                                    <div className="card-body h-100">
                                      <h5 className="card-title exMedium color-black mb-5">
                                        {eventItem?.name}
                                      </h5>
                                      <div className="position-absolute end-0 bottom-0 p-3">
                                        <div className="d-flex align-items-center justify-content-end h-100">
                                          <div className="d-flex align-items-center">
                                            {eventItem?.priceRanges ? (
                                              <p className="card-text mb-0 pb-0">
                                                ${eventItem?.priceRanges[0]?.min || 0} - ${eventItem?.priceRanges[0]?.max || 0}
                                              </p>
                                            ) : (
                                              <p className="card-text mb-0 pb-0">Price unknown</p>
                                            )}
                                            <input
                                              className="form-check-input ms-2 m-0"
                                              type="checkbox"
                                              id={`checkbox-${index}`}
                                              value={eventItem.eventName}
                                              disabled={eventCheckboxDisable}
                                              checked={isChecked}
                                              onChange={(e) => handleEventCheckbox(e, eventItem)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="carousel-item active">
                          <div className="card mb-3 rounded-2">
                            <div className="card-body">
                              <h5 className="card-title exMedium color-black">No Events found.</h5>
                              <p className="card-text">Please try searching again or check back later.</p>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>

                {/* <!-- -------------------------------------------------Nearby events carousel end-------------------------------- --> */}
              </div>
              <div className="border rounded-17 mt-4 p-4">
                <h5 className="exMedium">{propertyListing?.name}</h5>
                <p className="exMedium">
                  Listing ID:{" "}
                  <span className="exLight">{id}</span>
                </p>
                <div>
                  {propertyListing?.dataSource == "hostaway" ? (
                    <button className="btn rounded-pill purple-15 me-3 exMono tags my-2" style={{ cursor: "auto" }}>
                      Hostaway{" "}
                    </button>
                  ) : (
                    <>
                      <button className="btn rounded-pill lime-30 me-3 exMono tags my-2" style={{ cursor: "auto" }}>
                        Guesty{" "}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </aside>
          </div>
        </div>
        <ConfirmDialog className="toggleOffAlert" />
      </div>
    </>
  );
}

export default PropertyDetail;
