import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import { fetchLogsApi } from "../../Redux/Features/logReducer/logSlice";
import { DateFormat } from "../../Utils/constant/commonFunction";

function Log() {
  const dispatch = useDispatch();
  const { logsData } = useSelector((state) => state.Log);

  useEffect(() => {
    dispatch(fetchLogsApi());
  }, []);

  return (
    <>
      <div id="connection_page">
        <Header />
        <div id="wrapper">
          <SideBar />
          <section id="content-wrapper">
            <table className="table  ">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">User Id</th>
                  <th scope="col">Log Type</th>
                  <th scope="col">Updated Date</th>
                  <th scope="col">Message</th>
                  <th scope="col">Airbnb Summary</th>
                  <th scope="col">Airbnb Space</th>
                </tr>
              </thead>
              <tbody>
                {logsData &&
                  logsData?.data.map((items, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{items?.userId}</td>
                        <td>{items?.logType}</td>
                        <td>{DateFormat(items.updatedAt)}</td>
                        <td>{items?.message}</td>
                        <td>{items?.airbnbSummary}</td>
                        <td>{items?.airbnbSpace}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </>
  );
}

export default Log;
