import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Button } from "primereact/button";
// import amplitude from "../../../amplitude/amplitude";

import {
  UserRegistration,
  reset,
  registerAuth,
} from "../../../Redux/Features/authReducer/authSlice";
import TextError from "../../../Components/TextError/index";
import { SignUpSchema } from "../../../Utils/Validation/Validation";
import { INDEX, SIGN_IN } from "../../../Routes/Constant";
import Otp from "../../../Components/OtpInput";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [visible, setVisible] = useState(false);
  const [userDetail, setUserDetail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [buttonVisible, setButtonVisible] = useState(false);

  const { userRegistrationData, isSuccess } = useSelector(
    (state) => state.Auth
  );
  const InitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    termsCond: false,
  };

  useEffect(() => {
    let timer;
    if (isSuccess && userRegistrationData) {
      dispatch(reset());
      setVisible(false);
     timer =  setTimeout(() => {
        navigate(INDEX);
      }, 1000);
    }
    return ()=> clearTimeout(timer);
  }, [isSuccess, userRegistrationData]);

  const onSubmit = (values, { resetForm }) => {
    setLoading(true); // Start loading indicator
    const data = {
      fname: values.firstName,
      lname: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      password: values.password,
    };

    // amplitude.track('BUTTON_CLICKED', { buttonName: 'Subscribe' });

    setUserDetail(data);
    dispatch(registerAuth({ email: data.email }))
      .unwrap()

      .then((result) => {
        if (result?.success) {
          setVisible(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false); // Stop loading indicator
        // resetForm();
      });
    // resetForm();
  };
  const submitUser = async () => {
    if (otp != "") {
      setButtonVisible(true);
      const data = { ...userDetail, otp: otp };
      const res = await dispatch(UserRegistration(data));
      if (res) {
        setButtonVisible(false);
      }
    }
  };
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePhoneNumberChange = (e, setFieldValue) => {
    // Only allow digits
    const value = e.target.value.replace(/[^0-9\+\-]/g, "");
    // Set the value back to the field
    e.target.value = value;
    // Update Formik's value
    setFieldValue("phoneNumber", value);
  };
  return (
    <>
      <Otp
        otp={otp}
        setOtp={setOtp}
        visible={visible}
        setVisible={setVisible}
        submitUser={submitUser}
        data={userDetail}
        buttonVisible={buttonVisible}
      />
      <ToastContainer />
      <div className="container-fluid h-100">
        <section id="login_page" className="h-100">
          <div className="row h-100 ">
            <div className="col-lg-6 px-0 h-100 d-none d-md-block px-0 h-100">
              <div className="left-box w-100 h-100 text-center position-relative">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/client_images/horizontal_logo/Logo navy-purple.png"
                  }
                  className="   w-25 text-center py-5"
                  alt=""
                />
                <div className="client-img">
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="card border-purple cardimage">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/Assets/images/signup_page_user.png"
                            }
                            className="card-img-top   "
                            alt="..."
                          />
                          <div className="card-body cardparagraph">
                            <p className="card-text fs-md-4 fst-italic fw-normal ">
                            “After using the solution our bookings and views skyrocketed.”
                            </p>
                            <h5 className="card-title fs-md-4 fw-bold text-end">
                            JB Spisso
                            </h5>
                            <h6 className="text-end fw-light fst-italic">
                            Casago
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="card border-purple cardimage">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/Assets/images/user.jpg"
                            }
                            className="card-img-top   "
                            alt="..."
                          />
                          <div className="card-body cardparagraph">
                            <p className="card-text fs-md-4 fst-italic fw-normal">
                              “Lorem ipsum dolor sit amet, consectetur vel
                              dipiscing elit.Ut lorem diam, sagittis vel dolor
                              sit amet, porta sollicitudin urna.”
                            </p>
                            <h5 className="card-title fs-md-4 fw-bold text-end">
                              Jassie Robert
                            </h5>
                            <h6 className="text-end fw-light fst-italic">
                              CEO diam
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="card border-purple cardimage">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/Assets/images/user.jpg"
                            }
                            className="card-img-top   "
                            alt="..."
                          />
                          <div className="card-body cardparagraph">
                            <p className="card-text fs-md-4 fst-italic fw-normal">
                              “Lorem ipsum dolor sit amet, consectetur vel
                              dipiscing elit.Ut lorem diam, sagittis vel dolor
                              sit amet, porta sollicitudin urna.”
                            </p>
                            <h5 className="card-title fs-md-4 fw-bold text-end">
                              Jassie Robert
                            </h5>
                            <h6 className="text-end fw-light fst-italic">
                              CEO diam
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-0 h-100">
              <div className="right-box h-100 display-center text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/client_images/horizontal_logo/Logo-white.png"
                  }
                  className="img-fluid w-25 text-center py-5 position-absolute top-0 d-md-none"
                  alt=""
                />
                <div className="inner-box m-auto h-auto">
                  <h2 className="text-white mb-4">Register</h2>
                  <Formik
                    initialValues={InitialValues}
                    validationSchema={SignUpSchema}
                    onSubmit={onSubmit}
                  >
                    {({ setFieldValue }) => (
                      <Form>
                        <div className="mb-1">
                          <div className="input-group">
                            <span
                              className="input-group-text bg-transparent border-end-0 input-border"
                              id="basic-addon1"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/images/icons/person.svg"
                                }
                                alt=""
                              />
                            </span>
                            <Field
                              type="text"
                              className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                              id="firstName"
                              name="firstName"
                              placeholder="First Name"
                              maxLength={20}
                            />
                          </div>
                        </div>
                        <ErrorMessage name="firstName" component={TextError} />
                        <div className="mb-1 mt-3">
                          <div className="input-group">
                            <span
                              className="input-group-text bg-transparent border-end-0 input-border"
                              id="basic-addon1"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/images/icons/person.svg"
                                }
                                alt=""
                              />
                            </span>
                            <Field
                              type="text"
                              className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                              id="lastName"
                              name="lastName"
                              placeholder="Last Name"
                              maxLength={20}
                            />
                          </div>
                        </div>
                        <ErrorMessage name="lastName" component={TextError} />
                        <div className="mb-1 mt-3">
                          <div className="input-group">
                            <span
                              className="input-group-text bg-transparent border-end-0 input-border"
                              id="basic-addon1"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/images/icons/email.svg"
                                }
                                alt=""
                              />
                            </span>
                            <Field
                              type="email"
                              className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                              id="loginEmail"
                              name="email"
                              autoComplete="off"
                              placeholder="E-mail address"
                            />
                          </div>
                        </div>
                        <ErrorMessage name="email" component={TextError} />
                        <div className="mb-1 mt-3">
                          <div className="input-group">
                            <span
                              className="input-group-text bg-transparent border-end-0 input-border"
                              id="basic-addon1"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/images/icons/lock.svg"
                                }
                                alt=""
                              />
                            </span>
                            <Field
                              type={showPassword ? "text" : "password"}
                              className="form-control bg-transparent border-start-0 border-end-0 rounded-0 py-3 ps-0 input-border"
                              id="loginPassword"
                              name="password"
                              autoComplete="off"
                              placeholder="Password"
                              maxLength={16}
                            />
                            <span>
                              <Button
                                type="button"
                                className={` passEye pi border-start-0  ${
                                  showPassword ? "pi-eye-slash" : "pi-eye"
                                }`}
                                onClick={handlePasswordVisibility}
                              />
                            </span>
                          </div>
                        </div>
                        <ErrorMessage name="password" component={TextError} />
                        <div className="mb-1 mt-3">
                          <div className="input-group">
                            <span
                              className="input-group-text bg-transparent border-end-0 input-border"
                              id="basic-addon1"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/images/icons/call.svg"
                                }
                                alt=""
                              />
                            </span>
                            <Field
                              type="text"
                              className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                              id="phoneNumber"
                              name="phoneNumber"
                              placeholder="Phone Number"
                              maxLength={14}
                              onChange={(e) =>
                                handlePhoneNumberChange(e, setFieldValue)
                              } 
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="phoneNumber"
                          component={TextError}
                        />
                        <div className="mb-1 mt-3 form-check text-start">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="termsCond"
                            name="termsCond"
                          />
                          <label
                            className="form-check-label text-secondary"
                            for="exampleCheck1"
                          >
                            I accept&nbsp;
                            <a className="text-white text-decoration-none" href="https://www.autorank.com/general-terms-and-conditions" target="blank">Terms and conditions.</a>
                          </label>
                        </div>
                        <ErrorMessage name="termsCond" component={TextError} />
                        <button
                          type="submit"
                          className="btn button-light btn-lg form-control rounded-pill mt-3"
                          disabled={loading} // Disable button while loading
                        >
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm me-2 text-dark"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Sign Up"
                          )}
                        </button>
                      </Form>
                    )}
                  </Formik>
                  <p className="text-secondary mt-3 mb-0">
                    Already have an account?
                    <NavLink
                      to={SIGN_IN}
                      className="text-white ms-2 text-decoration-none"
                    >
                      Login
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Register;
