import axiosInstance from "../axiosInstance";

export const fetchListings = async (params) => {
  const url = "/listing";
  return axiosInstance.get(url, { params });
};

export const fetchListingCountries = async () => {
  const url = "/dashboard/countries";
  return axiosInstance.get(url);
};

export const fetchListingCities = async (params) => {
  const url = "/listing/cities";
  return axiosInstance.get(url, { params });
};

export const fetchTags = async () => {
  const url = "/listing/tags";
  return axiosInstance.get(url);
};