import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";

const ExpandableDescriptionElement = ({ elementName, element }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <Button
        onClick={() => setExpanded(!expanded)}
        className="d-flex align-items-center mt-3 mb-2 w-100 settings-btn text-start exMedium fs-4 color-dark"
        text
      >
        {elementName}
        <i className={`${expanded ? "pi pi-chevron-down" : "pi pi-chevron-right"} ms-3 fs-6`}></i>
      </Button>
      <div
        className="font-size-16 text-navy-light hover-to-edit"
      >
        {element ? (
          expanded ? (
            element?.split('\n\n').map((section, index) => (
              <div key={index}>
                <p>{section?.split('\n')[0]}</p>
                {section?.split('\n').slice(1).map((item, idx) => (
                  <p key={idx}>{item}</p>
                ))}
              </div>
            ))
          ) : (
            <p>
              {element?.split('\n\n')[0]?.split('\n')[0]}...
            </p>
          )
        ) : (
          <p className="text-navy-light my-4">No {elementName} Information Available.</p>
        )}
      </div>
    </div>
  );
}

export default ExpandableDescriptionElement;