import { useState } from "react";
import SettingDialog from "../../../Components/Dialogs/settings";
import { Button } from "primereact/button";
import ColumnActionsButton from "./button";

const ColumnSettings = ({ rowData }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <ColumnActionsButton rowData={rowData} icon="pi-cog" tooltip="Settings" onClick={() => setVisible(true)} />
      <SettingDialog
        id={rowData.recordId}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  )
}

export default ColumnSettings;