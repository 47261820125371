import axiosInstance from "../axiosInstance";

export const generateGuestyToken = async (data) => {
  const url = "/guesty/generate/token";
  return axiosInstance.post(url, data);
}

export const generateHostawayToken = async (data) => {
  const url = "/hostaway/generate/token";
  return axiosInstance.post(url, data);
}