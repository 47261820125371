import { Chart } from "primereact/chart";
import React, { useEffect, useState } from "react";
import { useAddListings } from "../../../hooks/mutations/listings";

function DoughnutChart(props) {
  const { automatedAndUnautomatedListing } = props;
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  const addListingsMutation = useAddListings();
  useEffect(() => {
    addListingsMutation.mutate();
  }, []);

  useEffect(() => {
    if (automatedAndUnautomatedListing) {
      const documentStyle = getComputedStyle(document.documentElement);
      const data = {
        datasets: [
          {
            data: [
              automatedAndUnautomatedListing?.activeUnautomatedListings,
              automatedAndUnautomatedListing?.activeListings,
              automatedAndUnautomatedListing?.inactiveListings,
            ],
            backgroundColor: [
              documentStyle.getPropertyValue("--grey"),
              documentStyle.getPropertyValue("--purple"),
              documentStyle.getPropertyValue("--lime"),
            ],
            hoverBackgroundColor: [
              documentStyle.getPropertyValue("--grey"),
              documentStyle.getPropertyValue("--purple"),
              documentStyle.getPropertyValue("--lime"),
            ],
          },
        ],
      };
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 1,
        cutout: "65%",
      };

      setChartData(data);
      setChartOptions(options);
    }
  }, [automatedAndUnautomatedListing]);

  return (
    <div className="booking_sites card p-3 border-0 shadow-sm rounded-17 h-100">
      <h5 className="mb-3 exDemiBold fs-22 ">Active Listings</h5>
      <div className="row gy-4 align-items-center">
        <div className="col-sm-4 position-relative my-auto">
          <Chart
            type="doughnut"
            data={chartData}
            options={chartOptions}
            className=""
          />
          <div className="position-absolute top-50 start-50 translate-middle mt-1">

            <h5 className=" exMedium text-center mb-0 text-purple">
              {automatedAndUnautomatedListing?.automatedPercentage ?? "-"}%
            </h5>
          </div>
        </div>
        <div className="col-sm-8 ">
          <ul className="mb-0">
            <li className="purple-bullet pb-0 mb-2 d-flex align-items-center ">
              Automated Listings{" "}
              <p className="text-navy exMedium fs-18 mb-0 ms-auto">
                {automatedAndUnautomatedListing?.activeListings ?? "-"}
              </p>
            </li>
            <li className="grey-bullet pb-0 mb-2  d-flex align-items-center ">
              Unautomated Listings{" "}
              <p className="text-navy exMedium fs-18 mb-0 ms-auto">
                {automatedAndUnautomatedListing?.activeUnautomatedListings ?? "-"}
              </p>
            </li>
            <li className="lime-bullet pb-2 mb-3 d-flex align-items-center color-dark">

              Inactive on Autorank{" "}

              <p className="text-navy exMedium fs-18 mb-0 ms-auto">
                {automatedAndUnautomatedListing?.inactiveListings ?? "-"}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default DoughnutChart;
