import React from "react";
import { useParams } from "react-router-dom";
import PropertyDetail from "../../Components/PropertyDetail";

function PropertyDetails() {

  const { id } = useParams();

  return (
    <div className="admin-section">
      <PropertyDetail id={id} />
    </div>
  );
}

export default PropertyDetails;
