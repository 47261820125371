import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useMemo, useRef } from 'react';
import { usePropertyMap } from '../../hooks/queries/properties';

const MapComponent = ({ recordId }) => {
  const mapRef = useRef(null);
  const markersRef = useRef([]);

  const getPropertyMap = usePropertyMap();
  const propertyMap = getPropertyMap?.data?.result;

  const propertyMapObject = useMemo(() => {
    return propertyMap?.reduce((acc, item) => {
      acc[item.recordId] = item;
      return acc;
    }, {});
  }, [propertyMap]);

  const property = propertyMapObject?.[recordId];

  useEffect(() => {
    if (!property) return;

    if (!mapRef.current) {
      mapRef.current = L.map("map", {
        center: [property.latitude || 50.822388, property.longitude || 2.7080566],
        zoom: 10,
      });

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(mapRef.current);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [property]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map || !propertyMap) return;

    for (const marker of markersRef.current) {
      map.removeLayer(marker);
    }
    markersRef.current = [];

    for (const item of propertyMap) {
      if (item?.latitude && item?.longitude) {
        const iconPath =
          item.recordId === recordId
            ? "/Assets/images/location-marker.png"
            : "/Assets/images/location-blue-marker.png";

        const icon = L.icon({
          iconUrl: process.env.PUBLIC_URL + iconPath,
          iconSize: [32, 32],
          iconAnchor: [16, 32],
        });

        const marker = L.marker([item.latitude, item.longitude], { icon }).addTo(map);
        markersRef.current.push(marker);
      }
    };
  }, [propertyMap, recordId]);

  return <div id="map" style={{ width: '100%', height: '400px' }}></div>;
};

export default MapComponent;
