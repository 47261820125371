import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { successToast, errorToast } from "../../../Utils/constant/commonFunction";
import couponService from "../couponReducer/couponService";

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    createCouponApiData: "",
    getCouponApiData: "",
    checkUserHaveActiveTransactionData: ""
}

// Create coupon API
export const createCouponApi = createAsyncThunk(
    "createCouponApiData",
    async (data, thunkAPI) => {
        try {
            return await couponService.createCouponApi(data);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            errorToast(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Get all coupon API
export const getCouponApi = createAsyncThunk(
    "getCouponApiData",
    async (thunkAPI) => {
        try {
            return await couponService.getCouponApi();
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// check User Have Active Transaction On Behalf Of Property
export const checkUserHaveActiveTransaction = createAsyncThunk(
    "checkUserHaveActiveTransaction",
    async (data, thunkAPI) => {
        try {
            return await couponService.checkUserHaveActiveTransaction(data);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const couponSlice = createSlice({
    name: "coupon",
    initialState,
    reducers: {
        reset: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isLoading = false;
            state.message = "";
        },
    },
    extraReducers: (builder) => {
        builder
           
            // Create coupon API
            .addCase(createCouponApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createCouponApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.createCouponApiData = payload;
                successToast(payload.message);
            })
            .addCase(createCouponApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload;
            })

             // Get all coupon API
            .addCase(getCouponApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCouponApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.getCouponApiData = payload;
            })
            .addCase(getCouponApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload;
            })

              // check User Have Active Transaction On Behalf Of Property
              .addCase(checkUserHaveActiveTransaction.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(checkUserHaveActiveTransaction.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.checkUserHaveActiveTransactionData = payload;
            })
            .addCase(checkUserHaveActiveTransaction.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload;
            })
    },
});

export const { reset } = couponSlice.actions;
export default couponSlice.reducer;