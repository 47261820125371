import { useQuery } from "@tanstack/react-query";
import { fetchListingCities, fetchListingCountries, fetchListings, fetchTags } from "../../services/api/listings/queries";

export const useListings = (params, enabled = true) => {
  return useQuery({
    queryKey: ['listings', params],
    queryFn: () => fetchListings(params),
    enabled
  });
};

export const useListingCountries = () => {
  return useQuery({
    queryKey: ['countries'],
    queryFn: fetchListingCountries
  });
};

export const useListingCities = (params) => {
  return useQuery({
    queryKey: ['cities', params],
    queryFn: () => fetchListingCities(params)
  });
};

export const useListingTags = () => {
  return useQuery({
    queryKey: ['tags'],
    queryFn: fetchTags
  });
}