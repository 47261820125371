import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routes/Routing";
function App() {

  // for removing all the console
  // console.log = function() {};

  return (
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
  );
}
export default App;