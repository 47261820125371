import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'auth',
  storage,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isLoggedIn: false,
    impersonateUser: null,
    email: null
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload
    },
    setImpersonateUser: (state, action) => {
      state.impersonateUser = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
  },
})

const persistedReducer = persistReducer(persistConfig, authSlice.reducer)

export const { setUser, setLoggedIn, setImpersonateUser, setEmail } = authSlice.actions;

export default persistedReducer
