import { useQueryClient } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useActivateListings } from "../../hooks/mutations/listings";
import { useAutomateProperties } from "../../hooks/mutations/properties";
import { LISTING } from "../../Routes/Constant";
import { errorToast, successSwal } from "../../Utils/constant/commonFunction";

const PropertyListingModal = ({ closeAddNewListing, confirmationVisible, setConfirmationVisible, getProperties }) => {
  const queryClient = useQueryClient();

  const [automate, setAutomate] = useState(true);

  const { selectedListings } = useSelector((state) => state.AddListings);

  const navigate = useNavigate();

  const automateListingsMutation = useAutomateProperties(selectedListings);
  const activateListingsMutation = useActivateListings();

  const handleCheckbox = (e) => {
    setAutomate(e.target.checked);
  }

  const handleSuccess = async () => {
    queryClient.invalidateQueries('checkSubscription');
    try {
      if (automate) {
        await automateListingsMutation.mutateAsync({ listingIds: selectedListings });
      }
    } catch (error) {
      errorToast(error.response?.data?.message);
    } finally {
      setConfirmationVisible(false);
      closeAddNewListing();
      successSwal("Listings added successfully");
      getProperties.refetch();
      navigate(LISTING);
    }
  };

  const addListings = () => {
    activateListingsMutation.mutate(
      { listingIdList: selectedListings },
      { onSuccess: handleSuccess }
    );
  }

  const footerContent = (
    <div className="d-flex px-4 justify-content-between my-2 align-items-center" style={{ padding: "25px" }}>
      <div className="d-flex align-items-center justify-content-center inputcheck">
        <input
          className="form-check-input green-checkbox mt-0"
          type="checkbox"
          checked={automate}
          aria-label="..."
          onChange={(e) => handleCheckbox(e)}
        />
        <label className="mx-2">Automate Selected Listings</label>
      </div>

      <Button
        className="btn button-purple rounded-pill font-normal"
        disabled={activateListingsMutation.isPending}
        onClick={addListings}
      >
        {activateListingsMutation.isPending ? (
          <span
            className="spinner-border spinner-border-sm text-dark"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          "Add Listings"
        )}
      </Button>

    </div>
  );

  const headerContent = (
    <div className="SmartListing">
      <h4>Smart Listing Automation</h4>
    </div>
  )

  return (
    <>
      <Dialog
        className="rounded-17 add_listing"
        header={headerContent}
        visible={confirmationVisible}
        style={{ width: "36vw", fontSize: "0.9rem" }}
        onHide={() => {
          if (!confirmationVisible) return;
          setConfirmationVisible(false);
          setAutomate(true);
        }}
        footer={footerContent}
      >
        <div style={{ padding: "25px" }}>
          <p className="m-0">
            AutoRank leverages AI to enhance the visibility, performance, and
            appeal of your listings. Here's how it attracts more customers and
            increases views & bookings:
          </p>
          <div className="d-flex justify-content-center align-items-center mt-2">
            <ul className="dotted-list">
              <li className="d-flex mb-2">
                <i
                  className="pi pi-circle-fill"
                  style={{
                    fontSize: "5px",
                    marginTop: "9px",
                    marginRight: "5px",
                  }}
                />{" "}
                <p className="SmartListingbold me-2">
                  <span className="spanname"> Automated Keyword Optimization - </span>
                  Ensures listings are packed with high-impact keywords to
                  boost search visibility.
                </p>
              </li>

              <li className="d-flex mb-2">
                <i
                  className="pi pi-circle-fill"
                  style={{
                    fontSize: "5px",
                    marginTop: "9px",
                    marginRight: "5px",
                  }}
                />{" "}
                <p className="SmartListingbold me-2">
                  {" "}
                  <span> Personalized Descriptions - </span>
                  Crafts tailored descriptions that resonate with potential
                  guests or buyers, creating a stronger appeal.
                </p>
              </li>
              <li className="d-flex mb-2">
                <i
                  className="pi pi-circle-fill"
                  style={{
                    fontSize: "5px",
                    marginTop: "9px",
                    marginRight: "5px",
                  }}
                />{" "}
                <p className="SmartListingbold me-2">
                  <span className="SmartListingbold">Competitor Tracking -</span>
                  Monitors top competing listings, allowing you to stay ahead
                  of trends and adapt to changes in listing demand.
                </p>
              </li>
            </ul>
          </div>
          <p>
            These automated features help you stay competitive and drive
            bookings with minimal manual effort.
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default PropertyListingModal;
