import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import addListingsSlice from "../Features/addListingsReducer/addListingsSlice";
import commonStateSlice from "../Features/commonStateSlice/commonStateSlice";
import stateSlice from "../Features/stateReducer/stateSlice";
import storage from "redux-persist/lib/storage";
import authSlice from "../Features/authReducer/authSlice";

const sessionPersistConfig = {
  key: 'addListings',
  storage: storageSession,
};

const persistConfig = {
  key: 'auth',
  storage,
};

const rootReducer = combineReducers({
  commonState: commonStateSlice,
  State: stateSlice,
  auth: persistReducer(persistConfig, authSlice),
  AddListings: persistReducer(sessionPersistConfig, addListingsSlice)
});

export default rootReducer;
