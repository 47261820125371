import { createSlice } from '@reduxjs/toolkit'

export const stateSlice = createSlice({
  name: 'state',
  initialState: {
    couponPopup:false,
    toOpenSubscriptionModel:false,

  },
  reducers: {
    setCouponPopup: (state, action) => {
      state.couponPopup = action.payload
    },
    setToOpenSubscriptionModel: (state, action) => {
      state.toOpenSubscriptionModel = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setCouponPopup,setToOpenSubscriptionModel } = stateSlice.actions

export default stateSlice.reducer