import { Skeleton } from 'primereact/skeleton';
import Freeicon from '../../Assets/images/free-icon.svg';

const ListingCard = ({ item, showCheckbox, onCheckboxChange, itemClass = "", checkboxitemClass = "", ItemdiscountClass = "", selectedListings = [] }) => (
  <div className={`card rounded-edge-17 addListing_popup ${itemClass}`}>
    <div className="col-12 h-100">
      <div className="row g-0 h-100">
        <div className="col-md-2 h-100 addListing-img-container">
          <img
            src={
              item?.thumbnailUrl ||
              process.env.PUBLIC_URL +
              "/Assets/images/img_placeholder.svg"
            }
            onError={(e) => {
              e.target.src =
                process.env.PUBLIC_URL +
                "/Assets/images/img_placeholder.svg";
            }}
            className="img-fluid w-auto"
            alt="..."
          />
        </div>
        <div className="col-md-10">
          <div className="card-body">
            <h5 className="card-title color-black fs-18 exMedium">
              {item?.name}
            </h5>
            <p className="card-text mb-2">
              Listing ID:{" "}
              <span className="exLight">{item?.recordId}</span>
            </p>
            <p className="card-text mb-0">
              <i className="pi pi-map-marker"></i>{" "}
              {item?.address}
            </p>
            {showCheckbox && (
              <>
                <div className={`discountclassnone ${ItemdiscountClass}`}>
                  <div className="discountImg  me-2">
                    <div className='freeicon-content'>
                      <img src={Freeicon} alt="" />
                      <div className="freecontent-inner">
                        <p className='mb-0 fs-12'>Free</p>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  className={`form-check-input list-card-checkbox ${checkboxitemClass}`}
                  type="checkbox"
                  id={`checkbox-${item?.id}`}
                  value={item?.id}
                  checked={selectedListings.includes(item?.id)}
                  onChange={(e) => onCheckboxChange(e, item)}
                  aria-label="..."
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const SkeletonListingCard = () => (
  <div className={`card rounded-edge-17 addListing_popup overflow-hidden`}>
    <div className="col-12 h-100">
      <div className="row g-0 h-100">
        <div className="col-md-2 addListing-img-container">
          <Skeleton height="100%" className="rounded-0" />
        </div>
        <div className="col-md-10">
          <div className="card-body">
            <Skeleton height="1.5rem" width="50%" className="mb-2" />
            <Skeleton height="1rem" width="25%" className="mb-2" />
            <Skeleton height="1rem" width="40%" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export { SkeletonListingCard };

export default ListingCard;