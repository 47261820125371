import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { store } from "../../Redux/Store";


/* Date format convert m-d-y */
export const DateFormat = (currentDAte) => {
  const date = moment(currentDAte);
  const state = store.getState();
  const type = state.auth.user?.dateFormat
  return date.format(type ? type : "MM-DD-YYYY");
};

export const formatedDateTime = (dateTime) => {
  const date = moment(dateTime);
  const state = store.getState();
  const type = state.auth.user?.dateFormat
  const dateFormatSpecifier = type === 'DD-MM-YYYY'
    ? 'ddd D MMM YYYY'
    : 'ddd MMM D YYYY'; // assuming only two formats are possible

  return `${date.format(dateFormatSpecifier)} at ${date.format("hh:mm A")}`;
};

export const formatDate = (apiDate) => {
  const date = new Date(apiDate);
  const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  const formattedDate = date.toLocaleDateString('en-US');
  return `${formattedTime}\n ${formattedDate}`;

};

export const convertTo12HourFormat = (time) => {
  // Split the time string into hours and minutes
  let [hour, minute] = time?.split(':').map(Number);

  // Determine the period (AM or PM)
  const period = hour >= 12 ? 'PM' : 'AM';

  // Convert hour from 24-hour format to 12-hour format
  hour = hour % 12 || 12; // Adjust hour to 12-hour format (0 becomes 12)

  // Pad minutes with leading zero if necessary
  minute = minute.toString().padStart(2, '0');

  // Return the formatted time
  return `${hour}:${minute} ${period}`;
}

/* Number and price with only 2 decimal */
export const PriceOnely2decimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

/* Set token in the header */
export const HeaderToken = (token) => {
  return { headers: { "authorization": `bearer ${token}` } }
};

/* First charatef cammel case */
export const FirstCamellCase = (name) => {
  return name[0].toUpperCase() + name.slice(1)
}

// Convert the binary data to a Base64 string
export const binaryToBase64String = (data) => {
  const base64Image = Buffer.from(data, 'binary').toString('base64');
  return `data:image/jpeg;base64,${base64Image}`;
}

export const arrayBufferToBase64 = (buffer) => {
  const bytes = new Uint8Array(buffer);
  const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
  return `data:image/jpeg;base64,${window.btoa(binary)}`;
};

/* Succcess notification */
export const successToast = (msg, autoClose = 2000) => {
  return toast.success(msg, { autoClose: autoClose });
}
/* Error notification */
export const errorToast = (msg) => {
  return toast.error(msg, { autoClose: 2000 });
}

export const warningToast = (msg) => {
  return toast.warning(msg, { autoClose: 5000 });
}

/* Success Swal */
export const successSwal = async (message) => {
  return await Swal.fire({
    title: "Success",
    text: message,
    icon: "success",
    timer: 2000,
    showConfirmButton: false,
    width: "300px",
    customClass: { popup: "small-swal" },
  });
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

