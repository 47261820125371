import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { DASHBOARD } from "../Constant";

const CheckAuthRoutes = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  return !isLoggedIn ? <Outlet /> : <Navigate to={DASHBOARD} />;
};

export default CheckAuthRoutes;
