import React from 'react';
import AllNotification from '../../Components/AllNotification';
import { useMarkAllRead } from '../../hooks/mutations/notification';
import { useNotifications } from '../../hooks/queries/notification';

function Notification() {
  const markAllReadMutation = useMarkAllRead();
  const getNotifications = useNotifications();
  const notifications = getNotifications?.data?.result;

  console.log(notifications)

  const handleMarkAllAsRead = (data) => {
    markAllReadMutation.mutate(data);
  }
  return (
    <div id="allNotifi_page">
      <h2 className=" content-title mb-0 exDemiBold mb-2">All Notifications</h2>
      <AllNotification
        data={notifications}
        handleMarkAllAsRead={handleMarkAllAsRead}
      />
    </div>
  )
}

export default Notification
