import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchAutomateProperties, patchDeleteProperty, postEvent, postTouristAttractions, putManualUpdateListing, putOriginalContent, putPropertySettings, putResetProperty } from "../../services/api/properties/mutations";
import { errorToast, successToast } from "../../Utils/constant/commonFunction";

export const useAutomateProperties = (recordIds) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => patchAutomateProperties(data),
    onSuccess: (response) => {
      for (const recordId of recordIds) {
        queryClient.invalidateQueries(["propertyById", recordId]);
      }
      queryClient.invalidateQueries("listings");
      successToast(response?.message);
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    }
  });
};

export const useResetProperty = (recordIds) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => putResetProperty(data),
    onSuccess: (response) => {
      successToast(response?.message);
      for (const recordId of recordIds) {
        queryClient.invalidateQueries(["propertyById", recordId]);
        queryClient.invalidateQueries(["audits", recordId]);
      };
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    }
  });
};

export const useDeleteProperty = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (ids) => patchDeleteProperty(ids),
    onSuccess: async (response) => {
      queryClient.invalidateQueries("listings");
      successToast(response?.message);
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    }
  })
}

export const useSavePropertySettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (values) => putPropertySettings(values),
    onSuccess: (response, values) => {
      // successToast(response?.message);
      queryClient.invalidateQueries(["propertySettings", values.recordId]);
      queryClient.invalidateQueries(["propertyById", values.recordId]);
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    },
  });
};

export const useAddAttraction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (values) => postTouristAttractions(values),
    onSuccess: (response, values) => {
      successToast(response?.message);
      queryClient.invalidateQueries(["propertyById", values.recordId]);
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    },
  });
}

export const useAddEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (values) => postEvent(values),
    onSuccess: (response, values) => {
      successToast(response?.message);
      queryClient.invalidateQueries(["propertyById", values.recordId]);
      queryClient.invalidateQueries(["events", values.listingId]);
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    },
  });
}

export const useManualUpdateListing = (setProgress, setProgressVisible, timerRef) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (recordIds) => putManualUpdateListing(recordIds),
    onSuccess: (response, { recordIds }) => {
      for (const recordId of recordIds) {
        queryClient.invalidateQueries(["propertyById", recordId]);
        queryClient.invalidateQueries(["audits", recordId]);
      };
      successToast(response?.message, response?.status === 207 ? 5000 : 2000);
      if (response?.status === 207) {
        const failedListings = response?.result?.failedListings;
        const formattedFailedListings = failedListings.map(listing => `${listing}`).join("\n");
        errorToast(`Failed Listings: \n${formattedFailedListings}\n`);
      }
    },
    onError: (error) => {
      setTimeout(() => {
        errorToast(error.response?.data?.message);
      });
    },
    onSettled: () => {
      console.log("in on settled");
      setProgress(100);
      clearInterval(timerRef.current);
      timerRef.current = null;
      setTimeout(() => {
        setProgressVisible(false)
        setProgress(0);
      }, 1000);
    },
  });
}

export const useUpdateOriginal = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => putOriginalContent(data),
    onSuccess: (response, data) => {
      const recordId = data.recordId;
      queryClient.invalidateQueries(["propertyById", recordId]);
      successToast(response?.message);
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    }
  });
}