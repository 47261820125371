import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectSubscriptionSchema } from "../../Utils/Validation/Validation";
import { Dialog } from "primereact/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../Components/TextError/index";
import { getUser } from "../../Redux/Features/LocalStorage";
import { insertUserSubscriptionType } from "../../Redux/Features/dashboard/dashboardSlice";

function SubscriptionModal() {
   
    const [cardVisible, setCardVisible] = useState(false);
    const [loading, setLoading] = useState(false); // State for loading indicator
    const dispatch = useDispatch();
    const User = getUser();
    
    const { manualUpdateListingData, autoUpdateListingData } = useSelector((state) => state.Auth);
    const { autoUnautoPropertyListingData } = useSelector((state) => state.List);
    const { toOpenSubscriptionModel } = useSelector((state) => state.State);
    console.log("toOpenSubscriptionModel??",toOpenSubscriptionModel)

     
    const initialValues = {
        subscriptionType: ""
    };

    let timer;

    useEffect(() => {
            if (User?.roleId == 2 && User?.subscriptionType == null) {
            const hasShownModal = localStorage.getItem('hasShownModal');
            // Open the modal on first load if it hasn't been shown yet
            if (!hasShownModal) {
               timer = setTimeout(() => {
                    setCardVisible(true);
                    localStorage.setItem('hasShownModal', 'true');
                    clearTimeout(timer);
                }, 2000); 
            }

            // Check if any API response indicates that a subscription is required
            // if (data?.data) {
            if (toOpenSubscriptionModel) {
                const shouldShowModal =
                    manualUpdateListingData?.message === "Please select subscription plan first." ||
                    autoUpdateListingData?.message === "Please select subscription plan first." ||
                autoUnautoPropertyListingData?.message === "Please select subscription plan first.";
                // Open the modal again if API response indicates subscription is needed

                // if (shouldShowModal && data?.data) {
                if (shouldShowModal && toOpenSubscriptionModel) {
                  timer =  setTimeout(() => {
                        setCardVisible(true);
                        clearTimeout(timer);
                    }, 2000); 
                   
                }
            }
        }
        return () => { clearTimeout(timer); };
    }, [autoUpdateListingData, manualUpdateListingData, autoUnautoPropertyListingData]);

    const handleSubmit = async (values) => {
        setLoading(true);
        const data = {
            subscriptionType: values.subscriptionType,
        };
        try {
            await dispatch(insertUserSubscriptionType(data));
            setLoading(false);
            setCardVisible(false);
        } catch (error) {
            setLoading(false);
        }
    };
    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2 mb-3 mr-10">
            <span className="font-bold white-space-nowrap">Please select the subscription plan</span>
        </div>
    );

    return (
        <>
            <Dialog
                className="p-3 background-white rounded-17"
                visible={cardVisible}
                modal
                header={headerElement}
                style={{ maxWidth: "500px", minWidth: "400px" }}
                onHide={() => {
                    if (!cardVisible) return;
                    setCardVisible(false);
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={SelectSubscriptionSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form className="px-2">
                            <div className="flex align-items-center mb-3">
                                <Field type="radio" name="subscriptionType" value="monthly" />
                                <label className="text-lg ms-2 gp-5">
                                    Monthly
                                </label>
                            </div>
                            <div className="flex align-items-center">
                                <Field type="radio" name="subscriptionType" value="yearly" />
                                <label className="text-lg ms-2 gp-5">
                                    Yearly
                                </label>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                                <ErrorMessage name="subscriptionType" component={TextError} />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-lg button-navy  form-control rounded-pill mt-3"
                                disabled={loading}
                            >
                                {loading ? (
                                    <span
                                        className="spinner-border spinner-border-sm me-2 text-dark"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    "Save"
                                )}
                            </button>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
export default SubscriptionModal;