export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_REDIRECT_URL;

export const LOGIN_API = "/checkLogin"
export const REGISTER_AUTH = "/registrationAuth"
export const REGISTER_API = "/userRegisteration"
export const FORGETPASSWORD_API = "/forgetPassword"
export const RESETPASSWORD_API = "/resetPassword"
export const LOGOUT_API = "/Api/signOut"
export const GETUSERDETAILS = `/getUserDataOnId`
export const UPDATEUSERPROFILE = `/updateUserProfile`
export const GETUSERLIST = `/getAllUserList`
export const CHANGEPASSWORD = `/changePassword`
export const HOSTAWAYAUTH = `/hostawayConnection`
export const GENRATEACCESSTOKENGUESTY = `/genrateAccessTokenForGuesty`
export const HOSTAWAYLISTING = `/getHostwayListingFromDatabase`
export const GETCOUNTRIES = `/getCountryData`
export const HOSTAWAYCHECKCONNECTION = `/checkValidConnection`
export const MANUALUPDATELISTING = `/manualUpdataData`
export const AUTOUPDATELISTING = `/updateListingDataStatus`
export const INACTIVATEUSER = `/inactiveUserByAdmin`
export const INCLUDEEXCLUDEDISCRIPTION = `/updateListingDataOnBehalfOfId`
export const LOGS = `/getUserAuditLogData`
export const INCBULLETPOINTS = `/changeBulletsPoint`
export const SUBSCRIPTION = `/getSubscriptionsData`
export const BUY_SUBSCRIPTION = `/buySubscriptions`
export const CHECK_PAYMENT_STATUS = `/checkPaymentStatus`
export const AUTOMATED_LISTING_DATA = `/automatedListingData`
export const REVIEW_SCORE_DATA = `/reviewScoreData`
export const BOOKING_PACE_MOMENTUM_DATA = `/bookingPaceMomentum`
export const TOTAL_REVENUE_FOR_MONTH_DATA = `/totalRevenueForMonth`
export const BOOKING_PACE_MOMENTUM_AIRBNB_DATA = `/bookingPaceMomentumAirbnbWeekly`
export const TOP_BOOKING_SITES_DATA = `/topBookingSites`
export const TOTAL_REVENUE_MONTH_AND_YEAR_DATA = `/totalRevenuMonthAndYearWise`
export const AVG_REVIEW_SCORE = `/getStarData`
export const WEB_NOTIFICATION_ACTIVATE = `/webNotificationActivate`
export const EMAIL_NOTIFICATION_ACTIVATE = `/emailNotificationActivate`
export const FETCH_NOTIFICATION = `/getNotification`
export const MARK_ALL_READ = `/markAllReadAndSingle`
export const PURCHASE_SUBSCRIPTIONS = `/getPurchagedSubscriptionData`
export const PROPERTY_BY_ID = `/getListingDataOnBehalfOfIdFromDatabase`
export const GET_LISTING_SETTING_OF_ID=`/getListingSettingOfId`
export const AUTO_UNAUTO_PROPERTY_LISTING = `/automatedAndUnautomatedListing`
export const USER_ACTIVATEPROPERTY = `/userActivateProperty`
export const CHECK_PAYMENT_ABOUT_PAYMENT_ID=`/checkPaymentAboutPaymentId`
export const INACTIVE_HOSTAWAY_LISTING=`/inactiveHostawayListing`
export const AUTOMATED_AND_UNAUTOMATED_LISTING_DATA=`/automatedAndUnautomatedListingData`
export const GET_ALL_EVENT=`/getAllEvent`
export const GET_TURIST_ATTRACTION_DATA=`/getturistAttrationData`
export const ADD_PAYMENT_CARD=`/addPaymentCard`
export const GET_LIST_OF_MANDETS=`/getListOfMandets`
export const GET_BILLIG_DETAILS = `/getBillingDetails`
export const ADD_AND_UPDATE_BILLIG_DETAILS = `/addAndupdateBillingDetails`
export const GET_USER_AUDIT_LOG_DATA_OF_PROPERTY_LISTING_ID=`/getUserAuditLogDataOfPropertyListingId`
export const GET_NOTIFICATION_INACTIVE_BY_USER=`/getNotificationInactiveByUser`
export const GET_CITY= `/getCity`
export const LATITUDE_LOGNITUDE_OF_LISTINGS= `/latitudeLognitudeOfListings`
export const CONNECTION_DATA_FOR_ADMIN= `/connectionDataForAdmin`
export const INSERT_USER_SUBSCRIPTION_TYPE= `/insertUserSubscriptionType`
export const RESET_SINGLE_AND_MULTIPLE_PROPERTY_DATA= `/resetSingleAndMultiplePropertyData`
export const GOOGLE_LOGIN= `/login`
export const GOOGLE_LOGIN_CALLBACK= `/callback` 
export const MAKE_ADMIN_IMPERSONATE_USER= `/makeAdminInpersonateUser`
export const ADMIN_INCRESSES_LIMIT_LISTING= `/adminIncressesLimitOfListing`
export const GET_TAGS= `/gettags`
export const GET_ALL_COUNTRY_NPM_DATA= `/getAllCountryNpmData`
export const ADD_TOURIST_ATTRACTIONS= `/addAttractionData`
export const ADD_EVENT_DATA= `/addEventData`
// coupon api 
export const CREATECOUPON = `/couponCreateApi`
export const GETCOUPON = `/getAllCouponByAdmin`
export const COUPON_PAYMENT_STATUS= `/checkUserHaveActiveTransactionOnBehalfOfProperty`


/* Server response message */
export const MESSAGE_NO_RECORD_FOUND = "Record not found"
export const MESSAGE_DUPLICATE_RECORD = "Duplicate record"
export const MESSAGE_ERROR_READING_RECORD = "Error reading record"
export const MESSAGE_SUCCESS_READING_RECORD = "Record get succes"
export const MESSAGE_ERROR_SERVER = " Internal Server Error !"
export const MESSAGE_SUCCESS_REGISTER = "User Register Successfull."
export const MESSAGE_ERROR_CREATING_RECORDS = "Error creating record"
export const MESSAGE_ERROR_UPDATING_RECORDS = "Error updating record"
export const MESSAGE_ERROR_DELETING_RECORDS = "Error deleting record"

export const MESSAGE_SUCCESS_CREATE_RECORD = "Record created"
export const MESSAGE_SUCCESS_UPDATE_RECORD = "Record updated"
export const MESSAGE_SUCCESS_DELETE_RECORD = "Record deleted"

export const MESSAGE_INVALID_LOGIN = "UserName and password not valid."
export const MESSAGE_VALID_LOGIN = "Login successful"

export const MESSAGE_NOT_LOGGED = "NOT LOGGED"
export const MESSAGE_BAD_REQUEST = "BAD REQUEST"

export const MESSAGE_DUPLICATE_EMAIL = "Email already exists"

/* Array of colors */
export const COLORS = ['blue', 'yellow']