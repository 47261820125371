import { SelectButton } from "primereact/selectbutton";
import { useState } from "react";

const SelectButtonSetting = ({ label, value, name, handleChange }) => {
  const options = [{ label: 'On', value: true }, { label: 'Off', value: false }];

  const [selectedValue, setSelectedValue] = useState(value);

  const handleSelectChange = (e) => {
    const newValue = e.value === null ? !selectedValue : e.value;
    setSelectedValue(newValue);
    handleChange({ ...e, value: newValue, target: { ...e.target, value: newValue } });
  };

  return (
    <div className="d-flex align-items-center justify-content-between border-bottom py-2">
      <label htmlFor={name} className="CstmListing-txtclr">{label}</label>
      <SelectButton
        value={selectedValue}
        id={name}
        name={name}
        onChange={handleSelectChange}
        options={options}
      />
    </div>
  );
};

export default SelectButtonSetting;
