import { useDispatch } from "react-redux";
import PmsCard from "../../Components/Connection/pms-card";
import PmsSteps from "../../Components/Connection/steps/pms-steps";
import { useCheckConnection } from '../../hooks/queries/auth';
import { setGuestyModalVisible, setHostawayModalVisible } from "../../Redux/Features/stateReducer/stateSlice";
import Guesty from "../Guesty";
import Hostway from "../Hostaway";



function Connection() {
  const dispatch = useDispatch();

  const checkConnection = useCheckConnection();

  const isHostawayConnected = checkConnection?.data?.result?.hostaway;
  const isGuestyConnected = checkConnection?.data?.result?.guesty;
  return (
    <div id="connection_page">
      <Guesty />
      <Hostway />
      <div className="welcome-user">
      </div>
      <div className="row gy-4">
        <PmsCard
          imageSrc={process.env.PUBLIC_URL + "/Assets/images/hostaway.png"}
          title="Hostaway"
          buttonText={isHostawayConnected ? "Connected" : "Connect"}
          onClick={() => dispatch(setHostawayModalVisible(true))}
          isComingSoon={false}
          isExternalLink={false}
        />
        <PmsCard
          imageSrc={process.env.PUBLIC_URL + "/Assets/images/guesty.png"}
          title="Guesty"
          buttonText={isGuestyConnected ? "Connected" : "Connect"}
          onClick={() => dispatch(setGuestyModalVisible(true))}
          isComingSoon={false}
          isExternalLink={false}
        />
        <PmsCard
          imageSrc={process.env.PUBLIC_URL + "/Assets/images/hostfully.png"}
          title="Hostfully"
          buttonText="Request Early Access"
          onClick="https://www.autorank.com/marketplace/hostfully"
          isComingSoon={true}
          isExternalLink={true}
        />
      </div>
      <PmsSteps />
    </div>
  );
}

export default Connection;
