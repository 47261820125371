import ActionDialog from "../PropertyDetail/actions/action-dialog";

const PropertyResetConfirm = ({ onConfirm }) => {
  return ActionDialog({
    title: "Are you sure?",
    message: "Do you want to reset your property listing details?",
    onAccept: onConfirm,
    acceptLabel: "Yes",
    rejectLabel: "No",
  });
};

export default PropertyResetConfirm;