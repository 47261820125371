import { ErrorMessage, Field, Form, Formik } from "formik";
import { SignInSchema } from "../../Utils/Validation/Validation";
import TextError from "../TextError";
import PasswordField from "../ui/password-field";

const SignInForm = ({ onSubmit, isLoading }) => {
  const initialValues = {
    email: "",
    password: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignInSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="mb-1">
            <div className="input-group mb-0">
              <span
                className="input-group-text bg-transparent border-end-0 input-border"
                id="basic-addon1"
              >
                <img
                  src={process.env.PUBLIC_URL + "/Assets/images/icons/email.svg"}
                  alt=""
                />
              </span>
              <Field
                type="email"
                className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                id="email"
                name="email"
                placeholder="E-mail address*"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <ErrorMessage name="email" component={TextError} />

          <div className="mt-3 mb-1">
            <PasswordField
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <ErrorMessage name="password" component={TextError} />

          <button
            type="submit"
            className="btn button-light btn-lg form-control rounded-pill mt-4"
            disabled={isLoading}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm me-2 text-dark"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Sign In"
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
