import { BreadCrumb } from "primereact/breadcrumb";
import React from "react";
import AllUpdatedHistory from "../../Components/AllUpdatedHistory";
import { LISTING, PROPERTY_DETAILS, UPDATED_HISTORY, } from "../../Routes/Constant";
import { useParams } from "react-router-dom";

function UpdatedHistory() {
  const { id } = useParams();

  const generateUrlWithId = (urlTemplate) => {
    return urlTemplate.replace(':id', id);
  };

  const items = [
    { label: "Listing", url: LISTING },
    { label: "Listing Details", url: generateUrlWithId(PROPERTY_DETAILS) },
    { label: "Updated History", url: generateUrlWithId(UPDATED_HISTORY) },
  ];
  const home = { icon: "pi pi-home", url: "/" };

  return (
    <>
      <div id="updated_history">
        <BreadCrumb
          model={items}
          home={home}
          className="border-0 text-decoration-none mb-2"
        />
        <h2 className=" content-title mb-0 exDemiBold mb-2">
          All Listing Updates
        </h2>
        <AllUpdatedHistory id={id} />
      </div>
    </>
  );
}

export default UpdatedHistory;
