import { useMutation, useQueryClient } from "@tanstack/react-query";
import { activateEmailNotifications, activateWebNotifications, inactivateNotifications, markAllRead } from "../../services/api/notifications/mutations";
import { errorToast, successToast } from "../../Utils/constant/commonFunction";

export const useInactivateNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => inactivateNotifications(data),
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
    onError: (error) => {
      errorToast(error.response?.data?.message);
    }
  });
};

export const useMarkAllRead = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => markAllRead(data),
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
    onError: (error) => {
      errorToast(error.response?.data?.message);
    }
  });
}

export const useActivateWebNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => activateWebNotifications(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries("userData");
      successToast(response?.message);
    },
    onError: (error) => {
      errorToast(error.response?.data?.message);
    }
  });
}

export const useActivateEmailNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => activateEmailNotifications(data),
    onSuccess: (response) => {
      queryClient.invalidateQueries("userData");
      successToast(response?.message);
    },
    onError: (error) => {
      errorToast(error.response?.data?.message);
    }
  });
}