import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postListings, updateListings } from "../../services/api/listings/mutations";
import { errorToast } from "../../Utils/constant/commonFunction";

export const useAddListings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postListings,
    onSuccess: async () => {
      await queryClient.invalidateQueries("listings");
    },
  });
};

export const useActivateListings = () => {
  return useMutation({
    mutationFn: (data) => updateListings(data),
    onError: (error) => {
      errorToast(error.response?.data?.message);
    }
  });
};
