import axiosInstance from "../axiosInstance";

export const patchAutomateProperties = async (data) => {
  const url = "/hostaway/listing/automate";
  return axiosInstance.patch(url, data);
};

export const putResetProperty = async (data) => {
  const url = "/listing/reset";
  return axiosInstance.put(url, data);
};

export const patchDeleteProperty = async (ids) => {
  const url = "/hostaway/listing/status";
  return axiosInstance.patch(url, ids);
};

export const putPropertySettings = async (values) => {
  const url = `/hostaway/update/listing/${values.recordId}`;
  return axiosInstance.put(url, values);
};

export const postTouristAttractions = async (values) => {
  const url = "/listing/add/ListingAttraction";
  return axiosInstance.post(url, values);
};

export const postEvent = async (values) => {
  const url = "/events/addListingEvent";
  return axiosInstance.post(url, values);
};

export const putManualUpdateListing = async (recordIds) => {
  const url = "/listing/sync";
  return axiosInstance.put(url, recordIds);
}

export const putOriginalContent = async (data) => {
  const url = "/listing/original/content";
  return axiosInstance.put(url, data);
}
