import React from 'react';
import useAutomate from '../../../hooks/actions/automate';
import ActionItem from './action-item';

const AutomateListing = ({ properties, noLabel }) => {

  const { handleAutomateSwitchChange } = useAutomate(properties);

  // TODO: optimistic update (ui changes slowly, because it waits for api response after after invalidating listings)

  return (
    <ActionItem
      label="Automate"
      isSwitch
      checked={properties.every((property) => property?.autoUpdateStatus === 1)}
      onChange={handleAutomateSwitchChange}
      noLabel={noLabel}
      {...(properties.length === 1 && {
        disabled: !properties[0]?.isPaid,
        property: properties[0]
      })
      }
    />
  );
};

export default AutomateListing;
