import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import React, { useState } from "react";
import { useParams } from "react-router";
import { DateFormat } from "../../Utils/constant/commonFunction";
import { useAudits } from "../../hooks/queries/properties";

function AllUpdatedHistory({ id }) {
  const params = useParams();
  const [historyDetails, setHistoryDetails] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const getAudits = useAudits(id);
  const audits = getAudits?.data?.result;

  const onRowExpand = (event) => {
    // toast.current.show({
    //   severity: "info",
    //   summary: "Product Expanded",
    //   detail: event.data.name,
    //   life: 3000,
    // });
  };

  const onRowCollapse = (event) => {
    // toast.current.show({
    //   severity: "success",
    //   summary: "Product Collapsed",
    //   detail: event.data.name,
    //   life: 3000,
    // });
  };

  const allowExpansion = (rowData) => {
    return rowData;
  };

  const onRowClick = (e) => {
    const rowId = e.data.id;
    const currentExpandedRows = { ...expandedRows };

    if (currentExpandedRows[rowId] === true) {
      delete currentExpandedRows[rowId];
    } else {
      currentExpandedRows[rowId] = true;
    }
    setExpandedRows(Object.keys(currentExpandedRows)?.length ? currentExpandedRows : null);
  }

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="p-3">
        <h6 className="exMedium fs-18 color-black">Summary</h6>
        <div className="font-size-16">
          {rowData?.airbnbSummary?.split('\n').map((item, index) => (
            <div key={index}>
              {item?.split(': ')[0]}: {item?.split(': ')[1]}
            </div>
          ))}
        </div>
        <h6 className="exMedium fs-18 color-black margin-top-20">Space</h6>
        <div className="font-size-16">
          {rowData?.airbnbSpace?.split('\n\n').map((section, index) => (
            <div key={index}>
              {section?.split('\n')[0]}
              {section?.split('\n').slice(1).map((item, index) => (
                <div key={index}>{item}</div>
              ))}
              <br />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const titleBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p className="fs-14 m-0 lh-lg">{rowData.title}</p>
        </div>
      </>
    );
  };

  const includedData = (rowData) => {
    return (
      <>
        <Tag
          className={
            rowData.included == "Event"
              ? "px-3 py-2 mint-30 text-navy"
              : rowData.included == "Attraction"
                ? "px-3 py-2 lime-30 text-navy"
                : "px-3 py-2 purple-15 text-navy"
          }
          value={rowData?.logType.replace(/\b\w/g, char => char.toUpperCase())}
          rounded
        ></Tag>
      </>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p className="fs-14 m-0 lh-lg">{DateFormat(rowData?.createdAt)}</p>
        </div>
      </>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();
  return (
    <>
      <DataTable
        className="update_data rounded-17"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        header={header}
        value={audits}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        responsiveLayout="scroll"
        size="small"
        showGridlines
        stripedRows
        removableSort
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        filters={filters}
        onRowClick={onRowClick}

      >
        <Column
          field="title"
          header="Title"
          sortable
          body={titleBodyTemplate}
        ></Column>
        <Column
          field="date"
          header="Date"
          body={dateBodyTemplate}
          sortable
        ></Column>
        <Column
          field="included"
          header="Update Mode"
          sortable
          body={includedData}
        ></Column>
        {/*<Column*/}
        {/*  field="upmode"*/}
        {/*  header="Update Mode"*/}
        {/*  sortable*/}
        {/*  body="Automated"*/}
        {/*></Column>*/}
        <Column
          className=""
          header="-"
          expander={allowExpansion}
        />
      </DataTable>
    </>
  );
}

export default AllUpdatedHistory;
