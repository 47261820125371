import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { useSync } from "../../contexts/sync-context";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import { INDEX, UNAUTHRIZED_USER } from "../Constant";

const ProtectedRoutes = ({ requiredRole = null }) => {

  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const { progress, progressVisible } = useSync();

  if (!isLoggedIn) {
    return <Navigate to={INDEX} />;
  }

  if (requiredRole && user?.roleId !== requiredRole) {
    return <Navigate to={UNAUTHRIZED_USER} />;
  }

  if (requiredRole) {
    return (
      <Outlet />
    )
  }

  return (
    <div>
      <Header />
      <div id="wrapper">
        <SideBar />
        {
          progressVisible && (
            <Progress
              className="cardProgressBar"
              percent={progress}
              status="active"
            />
          )}
        <section id="content-wrapper" className="logged-in">
          <Outlet />
        </section>
      </div>
    </div>
  );
};


export default ProtectedRoutes;