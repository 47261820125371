import React from "react";

function index(props) {
  return (
    <div className="sign-form-error">
      <p className="ms-2 mb-0">{props.children}</p>
    </div>
  );
}

export default index;
