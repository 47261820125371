import ActionDialog from "../../Components/PropertyDetail/actions/action-dialog";
import { useAutomateProperties } from "../mutations/properties";

const useAutomate = (properties) => {
  const automatePropertyMutation = useAutomateProperties(properties.map((property) => property?.recordId));

  const automate = async () => {
    automatePropertyMutation.mutate({
      listingIds: properties.map((property) => property?.id),
    });
  };

  const handleAutomateSwitchChange = async () => {
    if (properties.some((property) => property?.autoUpdateStatus === 0)) {
      return automate();
    }

    if (properties.length === 1) {
      ActionDialog({
        title: "Are you sure?",
        message: "If you disable this listing, you will not be able to see future updates.",
        onAccept: () => automate(),
        hasHeader: true,
        imageUrl: properties[0]?.thumbnailUrl || process.env.PUBLIC_URL + "/Assets/images/img_placeholder.svg",
        subtitle: properties[0]?.name
      });
    } else {
      ActionDialog({
        title: "Are you sure?",
        message: "If you disable these listings, you will not be able to see future updates.",
        onAccept: () => automate(),
      });
    }
  };

  return { handleAutomateSwitchChange };
};

export default useAutomate;
