import { Field, Form, Formik } from "formik";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import "react-sweet-progress/lib/style.css";
import PropertyActions from "../../Components/PropertyDetail/actions";
import AutomateListing from "../../Components/PropertyDetail/actions/automate";
import DeleteProperty from "../../Components/PropertyDetail/actions/delete";
import ResetListing from "../../Components/PropertyDetail/actions/reset";
import SyncNow from "../../Components/PropertyDetail/actions/sync";
import { useSavePropertySettings } from "../../hooks/mutations/properties";
import { useListingCities, useListingCountries, useListings, useListingTags } from "../../hooks/queries/listings";
import { DateFormat } from "../../Utils/constant/commonFunction";
import { propertyFilterSchema } from "../../Utils/Validation/Validation";
import ColumnActionsButton from "./column-actions/button";
import ColumnReset from "./column-actions/reset";
import ColumnSettings from "./column-actions/settings";
import ColumnSync from "./column-actions/sync";
import { useSelector } from "react-redux";

function PropertyListing() {

  const navigate = useNavigate();
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [formFilters, setFormFilters] = useState({});
  const [isShow, setIsShow] = useState(false);

  const { impersonateUser } = useSelector((state) => state.auth);

  const getCountries = useListingCountries();
  const countries = getCountries?.data?.result;
  const getCities = useListingCities({ country: formFilters.country });
  const cities = getCities?.data?.result;
  const getTags = useListingTags();
  const tags = getTags?.data?.result;


  const getListings = useListings({ status: "active", ...formFilters });
  const listings = getListings?.data?.result;

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  // Define initial values to filter the properies
  const initialValues = {
    autoUpdateStatus: "",
    dataSource: "",
    country: "",
    city: "",
  };

  useEffect(() => {
    if (selectedProperties?.length > 0) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [selectedProperties?.length]);

  const onSelectedProperties = (e) => {
    setSelectedProperties(e.value);
  };

  const onApplyFilter = (values) => {
    setFormFilters(values);
    getListings.refetch();
  };

  const handleCity = (selectedCountry) => {
    if (selectedCountry !== "") {
      getCities.refetch();
    }
  };

  const renderHeader = () => {
    return (
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
        <div className="dropdown ms-3 me-auto mb-md-0 mb-2">
          <Button
            icon="pi pi-sliders-h"
            className="filter-btn rounded-pill px-3 py-2 "
            label="Filter"
            type="button"
            id="filterDrop"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            outlined
            severity="secondary"
          />
          <ul className="dropdown-menu mt-2 p-3" aria-labelledby="filterDrop">
            <Formik
              initialValues={initialValues}
              validationSchema={propertyFilterSchema}
              onSubmit={onApplyFilter}
            >
              {(props) => {
                return (
                  <Form>
                    <li>
                      <p className="mb-1  ps-2">Property status</p>
                      <Field
                        as="select"
                        name="autoUpdateStatus"
                        className="form-select rounded-pill"
                        aria-label="Default select example"
                      >
                        <option selected value="">All</option>
                        <option value={1}>Automated</option>
                        <option value={0}>Unautomated</option>
                      </Field>
                    </li>
                    <li>
                      <p className="mb-1 mt-3 ps-2">Property tags</p>
                      <Field
                        as="select"
                        name="dataSource"
                        className="form-select rounded-pill"
                        aria-label="Default select example"
                      >
                        <option selected value="">All</option>
                        {tags?.map((item, index) => (
                          <option key={index} value={item}>
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </option>
                        ))}
                      </Field>
                    </li>
                    <li>
                      <p className="mb-1 mt-3 ps-2">Location</p>
                      <div className="d-flex">
                        <Field
                          as="select"
                          name="country"
                          className="form-select rounded-pill me-1"
                          aria-label="Default select example"
                          onChange={(e) => {
                            handleCity(e.target.value);
                            props.setFieldValue("country", e.target.value);
                          }}
                        >
                          <option selected value="">Country</option>
                          {countries?.map((item, index) => (
                            <option key={index} value={item} >
                              {item}
                            </option>
                          ))}
                        </Field>

                        <Field
                          as="select"
                          name="city"
                          className="form-select rounded-pill ms-1"
                          aria-label="Default select example"
                        >
                          <option selected value="">City</option>
                          {cities?.map((item, index) => (
                            <option key={index} value={item.city}>
                              {item.city}
                            </option>
                          )
                          )}
                        </Field>
                      </div>
                    </li>

                    <Button
                      type="submit"
                      label="Apply"
                      className="btn btn-sm rounded-pill button-navy mt-3 float-end"
                      rounded
                    ></Button>
                  </Form>
                )
              }}
            </Formik>
          </ul>
        </div>

        {isShow && (
          <div className="me-2 fw-normal">
            <PropertyActions actions={[
              { Component: AutomateListing, props: { properties: selectedProperties } },
              { Component: SyncNow, props: { properties: selectedProperties.map(item => item.recordId), isPaid: selectedProperties.every(item => item.isPaid) } },
              { Component: DeleteProperty, props: { ids: selectedProperties.map(item => item.id), setSelectedProperties: setSelectedProperties } },
              { Component: ResetListing, props: { ids: selectedProperties.map(item => item.recordId) } },
            ]} />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!selectedProperties?.length) return;

    const selectedMap = new Map(selectedProperties.map((item) => [item.recordId, item.autoUpdateStatus]));
    const updatedSelected = listings.reduce((acc, listing) => {
      const existingStatus = selectedMap.get(listing.recordId);
      if (existingStatus !== undefined) {
        acc.push(existingStatus !== listing.autoUpdateStatus ? listing : { ...listing, autoUpdateStatus: existingStatus });
      }
      return acc;
    }, []);

    setSelectedProperties(updatedSelected);
  }, [listings]);

  const tableRef = useRef(null);

  const ActionPopover = ({ rowData }) => {
    const op = useRef(null);

    useEffect(() => {
      const handleScrollOrResize = () => {
        if (op.current && typeof op.current.hide === "function") {
          op.current.hide();
        }
      };

      document.addEventListener("scroll", handleScrollOrResize, true);
      window.addEventListener("resize", handleScrollOrResize);

      return () => {
        document.removeEventListener("scroll", handleScrollOrResize, true);
        window.removeEventListener("resize", handleScrollOrResize);
      };
    }, []);

    return (
      <>
        <Button
          className="rounded-circle"
          onClick={(e) => op.current.toggle(e)}
          rounded
          text
          severity="secondary"
          icon="pi pi-ellipsis-h"
        />
        <OverlayPanel ref={op}>
          <ActionButtons rowData={rowData} />
        </OverlayPanel>
      </>
    );
  };

  const ActionButtons = ({ rowData }) => {
    return (
      <>
        <ColumnActionsButton icon="pi-pen-to-square" tooltip="Edit" onClick={() => navigate(`/propertydetails/${rowData.recordId}`)} />
        <ColumnSync data={rowData} />
        <ColumnSettings rowData={rowData} />
        <ColumnReset rowData={rowData} />
      </>
    );
  };

  const ActionBodyTemplate = (rowData) => {

    return (
      <>
        <div className="display-center act_btn actionShowlap">
          <ActionButtons rowData={rowData} />
        </div>
        <div className="dropdown actionShowmob">
          <ActionPopover rowData={rowData} />
        </div>
      </>
    );
  };

  const automateBodyTemplate = (rowData) => {
    return (
      <AutomateListing properties={[rowData]} noLabel={true} />
    );
  };

  const propertyBodyTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center">
        <img
          onClick={() => navigate(`/propertydetails/${rowData.recordId}`)}
          className="propertyImg rounded-2 me-2"
          src={
            rowData?.thumbnailUrl ||
            process.env.PUBLIC_URL + "/Assets/images/img_placeholder.svg"
          }
          onError={(e) => {
            e.target.src =
              process.env.PUBLIC_URL + "/Assets/images/img_placeholder.svg";
          }}
          alt="Not available"
        />
        <div className="d-flex justify-content-between align-items-center w-100">
          <p className="mb-0 ">{rowData?.name}</p>
          <div className="show-action-in-name">
            <ActionPopover rowData={rowData} />
          </div>
        </div>

      </div>
    );
  };

  const PropertyNickNameTemplate = (rowData) => {
    const [editNickName, setEditNickName] = useState(
      rowData?.nickName || rowData?.name
    );

    const [editVisible, setEditVisible] = useState(false);
    const headerElement = (
      <div className="inline-flex align-items-center justify-content-center gap-2 mb-3">
        <span className="font-bold white-space-nowrap ">Nick Name</span>
      </div>
    );

    const footerContent = (
      <div className="mt-4">
        <Button
          className="btn popup-cancel-btn rounded-pill float-start"
          label="Cancel"
          onClick={() => setEditVisible(false)}
        />
        <Button
          className="btn popup-save-btn rounded-pill"
          label="Save"
          onClick={() => saveNickName(rowData, editNickName)}
          autoFocus
        />
      </div>
    );

    const updatePropertyMutation = useSavePropertySettings();

    const onNameFieldEdit = (e) => {
      setEditNickName(e.target.value);
    };

    const saveNickName = async (rowData, editNickName) => {
      updatePropertyMutation.mutate(
        {
          recordId: rowData.recordId,
          nickName: editNickName,
        }
      )
      setEditVisible(false);
    };

    return (
      <React.Fragment>
        <div className="d-flex align-items-center">
          <p className="mb-0 text-justify">
            {rowData.nickName || rowData.name}
          </p>

          <div className="tagCol">
            <Button
              tooltip="Edit"
              className="btn border-0"
              onClick={() => setEditVisible(true)}
            >
              <img
                className="ms-2"
                src={
                  process.env.PUBLIC_URL + "/Assets/images/icons/edit_text.svg"
                }
                alt=""
              />
            </Button>
            <Dialog
              className="nick_dialog p-3 background-white rounded-17"
              visible={editVisible}
              modal
              header={headerElement}
              footer={footerContent}
              style={{ maxWidth: "500px", minWidth: "400px" }}
              onHide={() => {
                if (!editVisible) return;
                setEditVisible(false);
              }}
            >
              <textarea
                className="form-control"
                id="nickName"
                name="nickName"
                value={editNickName}
                onChange={(e) => onNameFieldEdit(e)}
              ></textarea>
            </Dialog>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p
            className={rowData?.dataSource
              ? "lime-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono tags mb-0"
              : rowData?.dataSource == "hostfully"
                ? "red-10 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono tags mb-0"
                : "purole-15 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono tags mb-0"
            }
          >
            {rowData?.dataSource?.charAt(0)?.toUpperCase() + rowData?.dataSource?.slice(1)}
          </p>
        </div>
      </>
    );
  };

  const header = renderHeader();

  const allColumns = [
    { field: "automated", header: "Automated", template: automateBodyTemplate },
    { field: "name", header: "Property", template: propertyBodyTemplate, sortable: true },
    { field: "address", header: "Address", sortable: true },
    { field: "updatedAt", header: "Last Updated", template: (row) => <span>{DateFormat(row?.updatedAt)}</span>, sortable: true },
    { field: "nickName", header: "Nick Name", template: PropertyNickNameTemplate },
    { field: "dataSource", header: "Pms", template: statusBodyTemplate },
    { field: "recordId", header: "ListID", sortable: true },
    { header: "Actions", template: ActionBodyTemplate },
  ];

  const [visibleColumns, setVisibleColumns] = useState(allColumns);

  const phase1 = allColumns.filter(col => col.header !== "Pms");
  const phase2 = phase1.filter(col => col.field !== "updatedAt");
  const phase3 = phase2.filter(col => col.field !== "nickName" && col.header !== "Actions");

  const importantColumns = allColumns.filter(col =>
    ["name", "automated"].includes(col.field)
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setVisibleColumns(importantColumns);
      } else if (window.innerWidth < 1441) {
        setVisibleColumns(phase3);
      } else if (window.innerWidth < 1471) {
        setVisibleColumns(phase2);
      } else if (window.innerWidth < 1591) {
        setVisibleColumns(phase1);
      } else {
        setVisibleColumns(allColumns);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div id="listing_page">
        <div className="welcome-user  ">
          <div className="d-flex justify-content-between align-items-center" >
            <h2 className=" content-title mb-0 exDemiBold mb-3">
              Property Listings
            </h2>
            {
              impersonateUser !== null && ( // TODO
                <h5>Impersonated User: {impersonateUser}</h5>
              )}
          </div>
          <div className="headerContent shadow-sm rounded-17">
            <DataTable
              ref={tableRef}
              className="editNick"
              value={listings?.sort((a, b) => a.name.localeCompare(b.name))}
              selectionMode={"checkbox"}
              selection={selectedProperties}
              onSelectionChange={(e) => onSelectedProperties(e)}
              dataKey="id"
              // tableStyle={{ minWidth: "10rem" }}
              responsiveLayout="scroll"
              size="small"
              showGridlines
              removableSort
              paginator
              rows={20}
              rowsPerPageOptions={[6, 20, 30, 50]}
              paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              filters={filters}
              header={header}
              loading={getListings.isLoading}
              loadingIcon={<ProgressSpinner
                style={{ height: "50px" }}
                strokeWidth="3"
                animationDuration="0.8s"
              />}
            >
              <Column
                selectionMode="multiple"
                field="id"
              ></Column>
              {visibleColumns.map((col) => (
                <Column key={col.field} field={col.field} header={col.header} body={col.template} sortable={col.sortable} />
              ))}
            </DataTable>
          </div>
          {/* Confirmation dialog to add bullet  */}
          <ConfirmDialog className="toggleOffAlert" />
        </div>
      </div>
    </>
  );
}
export default PropertyListing;
