import ActionDialog from "../PropertyDetail/actions/action-dialog";

const PropertySyncConfirm = ({ onConfirm }) => {
  return (
    ActionDialog({
      title: "Confirm Manual Sync",
      message: "This update will only be active until your next automatic update. Do you want to proceed?",
      onAccept: onConfirm,
    })
  );
};

export default PropertySyncConfirm;