import axiosInstance from "../axiosInstance";

export const fetchPropertyById = async (recordId) => {
  const url = `/listing/details/${recordId}`;
  return axiosInstance.get(url);
}

export const fetchPropertyStats = async (recordId) => {
  const url = `/listing/stats/${recordId}`;
  return axiosInstance.get(url);
}

export const fetchPropertySettings = async (recordId) => {
  const url = `/listing/settings/${recordId}`;
  return axiosInstance.get(url);
}

export const fetchPropertyMap = async () => {
  const url = '/listing/coordinates';
  return axiosInstance.get(url);
}

export const fetchEventsById = async (id) => {
  const url = `/events/getListingEvent/${id}`;
  return axiosInstance.get(url);
}

export const fetchAllEvents = async (recordId) => {
  const url = `/listing/events/${recordId}`;
  return axiosInstance.get(url);
}

export const fetchAllAttractions = async (recordId) => {
  const url = `/listing/attractions/${recordId}`;
  return axiosInstance.get(url);
}

export const fetchAudits = async (recordId) => {
  const url = `/audit/${recordId}`;
  return axiosInstance.get(url);
}