import { ErrorMessage, Field, Form, Formik } from "formik";
import { ForgetPasswordSchema } from "../../Utils/Validation/Validation";
import TextError from "../TextError";
import PasswordField from "../ui/password-field";

const ResetPasswordForm = ({ onSubmit, isLoading }) => {
  const initialValues = {
    email: "",
    otp: "",
    newPassword: "",
    conPassword: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ForgetPasswordSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="mb-1">
            <div className="input-group">
              <span
                className="input-group-text bg-transparent border-end-0 input-border"
                id="basic-addon1"
              >
                <img
                  src={process.env.PUBLIC_URL + "/Assets/images/icons/otp.svg"}
                  alt=""
                />
              </span>
              <Field
                type="text"
                className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                id="otp"
                name="otp"
                placeholder="Enter OTP"
                value={values.otp}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <ErrorMessage name="otp" component={TextError} />

          <div className="mt-3 mb-1">
            <PasswordField
              name="newPassword"
              placeholder="Enter New Password"
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <ErrorMessage name="newPassword" component={TextError} />

          <div className="mt-3 mb-1">
            <PasswordField
              name="conPassword"
              placeholder="Confirm Password"
              value={values.conPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <ErrorMessage name="conPassword" component={TextError} />

          <button
            type="submit"
            className="btn button-light btn-lg form-control rounded-pill mt-4 mb-5"
            disabled={isLoading}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm me-2 text-dark"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Reset Password"
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;