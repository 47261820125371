import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { arrayBufferToBase64, formatedDateTime } from "../../../Utils/constant/commonFunction";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedListings, setVisible } from "../../../Redux/Features/addListingsReducer/addListingsSlice";
import { useMarkAllRead } from "../../../hooks/mutations/notification";
import { useListings } from "../../../hooks/queries/listings";
import { useCheckSubscription } from "../../../hooks/queries/payment";
import PlanNotification from "./plan-notification";

function Notification(props) {
  const { data } = props;

  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getActiveListings = useListings({ status: "active" });
  const activeListings = getActiveListings?.data?.result;
  const getInactiveListings = useListings({ status: "inactive" });
  const inactiveListings = getInactiveListings?.data?.result;
  const checkSubscription = useCheckSubscription();
  const subscription = checkSubscription?.data?.result;


  const notificationLength = data?.filter((item) => !item.isSeen);

  const markAllReadMutation = useMarkAllRead();

  const markAllAsRead = () => {
    markAllReadMutation.mutate(data?.map((item) => item.id));
  };

  window.onload = () => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      document.body.classList.add("dark");
    }
  };

  return (
    <>
      {
        user?.roleId === 2 && (
          subscription && !subscription?.validPaymentMethod ? (
            <PlanNotification title="No payment method" description="Please add a payment method to start automating your listings" actionText="Add Payment" bgColor="bg-important" />
          ) : activeListings?.length > 0 && activeListings?.some(item => item.isPaid === false) ? (
            <PlanNotification title="Some listings have expired" description="Upgrade your subscription to activate all listings" actionText="Upgrade Plan" bgColor="bg-important" />
          ) : inactiveListings && inactiveListings?.length > 0 && (
            <PlanNotification title="Automate more listings" description="Consider adding more listings to maximize your account's potential!" actionText="Add Listings" bgColor="bg-purple-gradient"
              action={() => {
                dispatch(setSelectedListings(inactiveListings.map(item => item.id)));
                dispatch(setVisible(true));
              }} />
          )
        )
      }


      <ul className="d-flex align-items-center ms-auto p-0 mb-2 mb-lg-0">
        <li className="nav-item list-group dropdown pt-2 notify-dropdown">
          <button
            className="nav-link border-end border-muted icons"
            id="navbarDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="icon-outer display-center  ms-3 me-md-5 me-3 purple-15 position-relative">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/Assets/images/icons/notification_icon.svg"
                }
                alt=""
              />
              <div className="notification-number text-white color-purple rounded-circle display-center">
                {notificationLength?.length}
              </div>
            </div>
          </button>
          <div
            className="dropdown-menu dropdown-menu-end p-0 border-0 shadow-sm rounded-17 mt-2"
            aria-labelledby="navbarDropdown"
          >
            <div className="d-flex align-items-center justify-content-between purple-15 px-3 py-3">
              <h5 className="mb-0 me-auto exMedium">Notifications</h5>
              <div className="">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/icons/check_all.svg"
                  }
                  alt=""
                />
                <button
                  className="mark-read btn btn-link text-decoration-none text-purple ps-1 "
                  onClick={markAllAsRead}
                >
                  Mark all as read
                </button>
              </div>
              <li>
                <hr className="dropdown-divider m-0 text-purple" />
              </li>
            </div>
            {
              <ul className="list-unstyled">
                {notificationLength?.map((item, index) => (
                  <>
                    <div
                      className={`${item?.isSeen === true
                        ? "dropdown-item d-flex align-items-center justify-content-between py-3 text-decoration-none bg-notification"
                        : "dropdown-item d-flex align-items-center justify-content-between py-3 text-decoration-none bg-not-unread"
                        }`}
                      onClick={() => navigate(`/propertydetails/${item?.recordId}`)}
                    >
                      <div className="notify-msg position-relative ps-4">
                        <p className="text-wrap mb-0">
                          {item.message}
                        </p>
                        <span>
                          <small className="color-muted">
                            {formatedDateTime(item.updatedAt)}
                          </small>
                        </span>
                      </div>
                      <img
                        src={
                          item?.webNotificationThamnail
                            ? item?.webNotificationThamnail
                            : arrayBufferToBase64(item?.webUserThamnail?.data)
                        }
                        onError={(e) => {
                          e.target.src =
                            process.env.PUBLIC_URL +
                            "/Assets/images/img_placeholder.svg"; // replace with a default image
                        }}
                        alt=""
                      />
                    </div>
                    <li>
                      <hr className="dropdown-divider m-0 color-muted" />
                    </li>
                  </>
                ))}
              </ul>
            }
            <div className="purple-15 text-center w-100">
              <NavLink
                to={"/notification"}
                className=" text-purple py-3 exMedium text-center text-decoration-underline d-block"
                href="allNotifications.html"
              >
                View all notifications{" "}
              </NavLink>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
}

export default Notification;
