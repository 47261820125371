import React, { useEffect, useState } from "react";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import { useDispatch, useSelector } from "react-redux";
import {connectionDataForAdmin} from "../../Redux/Features/listReducer/listSlice";
import {inActivateUser} from "../../Redux/Features/authReducer/authSlice";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";

function ConnectedUsersList() {
  const dispatch = useDispatch();
  const {connectionDataForAdminData}=useSelector((state)=>state.List)
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filters, setFilters] = useState({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  useEffect(() => {
    dispatch(connectionDataForAdmin({"dataSource":"hostaway"}));
  }, []);

  const inActivateUserByAdmin = (rowData) => {
    dispatch(
      inActivateUser({
        userId: rowData.id,
        userEmail: rowData.email,
        userStatus: rowData?.status === "active" ? "inactive" : "active",
      })).unwrap()
      .then((result) => {
        dispatch(connectionDataForAdmin({"dataSource":"hostaway"}))})   
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          className="rounded-circle"
          icon="pi pi-user-edit fs-5"
          tooltip="Disable User"
          tooltipOptions={{ position: "top" }}
          rounded
          outlined
          severity="secondary"
          onClick={() => inActivateUserByAdmin(rowData)}
        />
      </React.Fragment>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
    setFilters(prevFilters => ({
      ...prevFilters,
      global: { value, matchMode: FilterMatchMode.CONTAINS }
    }));
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  return (
    <>
      <div id="connection_page">
        <Header />
        <div id="wrapper">
          <SideBar />
          <section id="content-wrapper">
            <h2 className=" content-title mb-0 exDemiBold mb-2">Connected Users List</h2>
            {/* <p className="text-navy-light mb-4">
              Lorem ipsum dolor sit amet, consectetur.
            </p> */}
            <DataTable
              className="shadow-sm rounded-17"
              header={header}
              value={connectionDataForAdminData?.data || []}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
              responsiveLayout="scroll"
              size="small"
              showGridlines
              stripedRows
              removableSort
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              filters={filters}
            >
              <Column field="email" header="Email Address" sortable></Column>
              <Column field="dataSource" header="Tags"></Column>
              <Column field="status" header="Status" sortable></Column>
              <Column header="Actions" body={actionBodyTemplate}></Column>
            </DataTable>
          </section>
        </div>
      </div>
    </>
  );
}
export default ConnectedUsersList;
