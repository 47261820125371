import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import TextError from "../../../Components/TextError";
import { CHANGEPASSWORD } from "../../../Utils/Validation/Validation";
import { useChangePassword } from "../../../hooks/mutations/auth";

function PrivacySetting() {
  const [showPassword, setShowPassword] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [seenPassword, setSeenPassword] = useState(false);

  const InitialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const changePasswordMutation = useChangePassword();

  const onSubmit = (values, { resetForm }) => {
    const value = {
      oldPassword: values?.oldPassword,
      newPassword: values?.newPassword,
    };
    changePasswordMutation.mutate(value);
    resetForm();
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordShow = () => {
    setVisiblePassword(!visiblePassword);
  };

  const handlePasswordSeen = () => {
    setSeenPassword(!seenPassword);
  };

  return (
    <div
      className="tab-pane fade"
      id="privacy"
      role="tabpanel"
      aria-labelledby="privacy-tab"
    >
      <Formik
        initialValues={InitialValues}
        validationSchema={CHANGEPASSWORD}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="d-flex align-items-md-center justify-content-between py-3 border-bottom flex-md-row flex-column">
            <div>
              <h4 className="fs-22 exMedium">Privacy Settings</h4>
              <p className="fs-14 text-navy-light exLight mb-2 mb-md-0">
                View and update your privacy settings.
              </p>
            </div>
            <div>
              <button className="btn btn-lg button-navy rounded-pill mb-md-2"
                type="submit"
              >
                Save Changes
              </button>
            </div>
          </div>
          <div className="row py-3 px-3 border-bottom">
            <div className="col-md-6 px-0">
              <label className="exMedium mb-2" for="privacyPass">
                Change Password
              </label>
            </div>
            <div className="col-md-6 px-0">
              <div className="input-group input-48 border rounded-pill mt-3 mb-1">
                <span
                  className="input-group-text bg-transparent  border-0 ps-3 rounded-pill  "
                  id="basic-addon1"
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 8H16.625V5.375C16.625 4.28098 16.1904 3.23177 15.4168 2.45818C14.6432 1.6846 13.594 1.25 12.5 1.25C11.406 1.25 10.3568 1.6846 9.58318 2.45818C8.8096 3.23177 8.375 4.28098 8.375 5.375V8H5C4.60218 8 4.22064 8.15804 3.93934 8.43934C3.65804 8.72064 3.5 9.10218 3.5 9.5V20C3.5 20.3978 3.65804 20.7794 3.93934 21.0607C4.22064 21.342 4.60218 21.5 5 21.5H20C20.3978 21.5 20.7794 21.342 21.0607 21.0607C21.342 20.7794 21.5 20.3978 21.5 20V9.5C21.5 9.10218 21.342 8.72064 21.0607 8.43934C20.7794 8.15804 20.3978 8 20 8ZM12.5 15.875C12.2775 15.875 12.06 15.809 11.875 15.6854C11.69 15.5618 11.5458 15.3861 11.4606 15.1805C11.3755 14.975 11.3532 14.7488 11.3966 14.5305C11.44 14.3123 11.5472 14.1118 11.7045 13.9545C11.8618 13.7972 12.0623 13.69 12.2805 13.6466C12.4988 13.6032 12.725 13.6255 12.9305 13.7106C13.1361 13.7958 13.3118 13.94 13.4354 14.125C13.559 14.31 13.625 14.5275 13.625 14.75C13.625 15.0484 13.5065 15.3345 13.2955 15.5455C13.0845 15.7565 12.7984 15.875 12.5 15.875ZM15.125 8H9.875V5.375C9.875 4.67881 10.1516 4.01113 10.6438 3.51884C11.1361 3.02656 11.8038 2.75 12.5 2.75C13.1962 2.75 13.8639 3.02656 14.3562 3.51884C14.8484 4.01113 15.125 4.67881 15.125 5.375V8Z"
                      fill="#8D909D"
                    />
                  </svg>
                </span>
                <Field
                  className=" form-control  border-0 fs-6 ps-0  rounded-pill-end   color-dark"
                  type={showPassword ? "text" : "password"}
                  id="privacyPass"
                  name="oldPassword"
                  placeholder="Current Password"
                  maxLength={16}
                />
                <span>
                  <span
                    type="button"
                    className={` passEyess pi border-start-0  ${showPassword ? "pi-eye-slash" : "pi-eye"}`}
                    onClick={handlePasswordVisibility}
                  />
                </span>
              </div>
              <ErrorMessage name="oldPassword" component={TextError} />
              <div className="input-group input-48 border rounded-pill mt-3 mb-1">
                <span
                  className="input-group-text bg-transparent  border-0 ps-3 rounded-pill  "
                  id="basic-addon1"
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 8H16.625V5.375C16.625 4.28098 16.1904 3.23177 15.4168 2.45818C14.6432 1.6846 13.594 1.25 12.5 1.25C11.406 1.25 10.3568 1.6846 9.58318 2.45818C8.8096 3.23177 8.375 4.28098 8.375 5.375V8H5C4.60218 8 4.22064 8.15804 3.93934 8.43934C3.65804 8.72064 3.5 9.10218 3.5 9.5V20C3.5 20.3978 3.65804 20.7794 3.93934 21.0607C4.22064 21.342 4.60218 21.5 5 21.5H20C20.3978 21.5 20.7794 21.342 21.0607 21.0607C21.342 20.7794 21.5 20.3978 21.5 20V9.5C21.5 9.10218 21.342 8.72064 21.0607 8.43934C20.7794 8.15804 20.3978 8 20 8ZM12.5 15.875C12.2775 15.875 12.06 15.809 11.875 15.6854C11.69 15.5618 11.5458 15.3861 11.4606 15.1805C11.3755 14.975 11.3532 14.7488 11.3966 14.5305C11.44 14.3123 11.5472 14.1118 11.7045 13.9545C11.8618 13.7972 12.0623 13.69 12.2805 13.6466C12.4988 13.6032 12.725 13.6255 12.9305 13.7106C13.1361 13.7958 13.3118 13.94 13.4354 14.125C13.559 14.31 13.625 14.5275 13.625 14.75C13.625 15.0484 13.5065 15.3345 13.2955 15.5455C13.0845 15.7565 12.7984 15.875 12.5 15.875ZM15.125 8H9.875V5.375C9.875 4.67881 10.1516 4.01113 10.6438 3.51884C11.1361 3.02656 11.8038 2.75 12.5 2.75C13.1962 2.75 13.8639 3.02656 14.3562 3.51884C14.8484 4.01113 15.125 4.67881 15.125 5.375V8Z"
                      fill="#8D909D"
                    />
                  </svg>
                </span>
                <Field
                  className=" form-control  border-0 fs-6 ps-0  rounded-pill-end   color-dark"
                  type={visiblePassword ? "text" : "password"}
                  id="newPass"
                  name="newPassword"
                  placeholder="New Password"
                  maxLength={16}
                />
                <span>
                  <span
                    type="button"
                    className={` passEyess pi border-start-0  ${visiblePassword ? "pi-eye-slash" : "pi-eye"}`}
                    onClick={handlePasswordShow}
                  />
                </span>
              </div>
              <ErrorMessage name="newPassword" component={TextError} />
              <div className="input-group input-48 border rounded-pill mt-3 mb-1">
                <span
                  className="input-group-text bg-transparent  border-0 ps-3 rounded-pill  "
                  id="basic-addon1"
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 8H16.625V5.375C16.625 4.28098 16.1904 3.23177 15.4168 2.45818C14.6432 1.6846 13.594 1.25 12.5 1.25C11.406 1.25 10.3568 1.6846 9.58318 2.45818C8.8096 3.23177 8.375 4.28098 8.375 5.375V8H5C4.60218 8 4.22064 8.15804 3.93934 8.43934C3.65804 8.72064 3.5 9.10218 3.5 9.5V20C3.5 20.3978 3.65804 20.7794 3.93934 21.0607C4.22064 21.342 4.60218 21.5 5 21.5H20C20.3978 21.5 20.7794 21.342 21.0607 21.0607C21.342 20.7794 21.5 20.3978 21.5 20V9.5C21.5 9.10218 21.342 8.72064 21.0607 8.43934C20.7794 8.15804 20.3978 8 20 8ZM12.5 15.875C12.2775 15.875 12.06 15.809 11.875 15.6854C11.69 15.5618 11.5458 15.3861 11.4606 15.1805C11.3755 14.975 11.3532 14.7488 11.3966 14.5305C11.44 14.3123 11.5472 14.1118 11.7045 13.9545C11.8618 13.7972 12.0623 13.69 12.2805 13.6466C12.4988 13.6032 12.725 13.6255 12.9305 13.7106C13.1361 13.7958 13.3118 13.94 13.4354 14.125C13.559 14.31 13.625 14.5275 13.625 14.75C13.625 15.0484 13.5065 15.3345 13.2955 15.5455C13.0845 15.7565 12.7984 15.875 12.5 15.875ZM15.125 8H9.875V5.375C9.875 4.67881 10.1516 4.01113 10.6438 3.51884C11.1361 3.02656 11.8038 2.75 12.5 2.75C13.1962 2.75 13.8639 3.02656 14.3562 3.51884C14.8484 4.01113 15.125 4.67881 15.125 5.375V8Z"
                      fill="#8D909D"
                    />
                  </svg>
                </span>
                <Field
                  className=" form-control  border-0 fs-6 ps-0  rounded-pill-end   color-dark"
                  type={seenPassword ? "text" : "password"}
                  id="confPass"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  maxLength={16}
                />
                <span>
                  <span
                    type="button"
                    className={` passEyess pi border-start-0  ${seenPassword ? "pi-eye-slash" : "pi-eye"}`}
                    onClick={handlePasswordSeen}
                  />
                </span>
              </div>
              <ErrorMessage name="confirmPassword" component={TextError} />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default PrivacySetting;
