import axiosInstance from "../axiosInstance";

export const impersonateUser = async (data) => {
  const url = "/impersonate/user";
  return axiosInstance.post(url, data);
}

export const stopImpersonateUser = async () => {
  const url = "/impersonate/stop";
  return axiosInstance.post(url);
}

export const inactivateUser = async (data) => {
  const url = "/user/inactiveUserByAdmin";
  return axiosInstance.post(url, data);
}

export const increaseUserLimit = async (data) => {
  const url = "/user/increaseLimit";
  return axiosInstance.post(url, data);
}