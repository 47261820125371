import React from 'react';
import { Link } from 'react-router-dom';
import { useGoogleLogin } from '../../../hooks/queries/auth';

const GoogleLoginButton = () => {
  const getGoogleLogin = useGoogleLogin();
  const googleLoginUrl = getGoogleLogin.data?.authUrl;

  return (
    <>
      <Link className="btn btn-lg btn-outline-purple rounded-0 form-control text-white py-3" to={googleLoginUrl}>SIGN IN VIA GOOGLE
        <img src={process.env.PUBLIC_URL + "/Assets/images/icons/google-icon.png"} alt="google" className="google-icon   ms-3 mb-1" />
      </Link>
      <p className="text-secondary my-4"> - - - - - - - - - - <span className="text-white">OR</span> - - - - - - - - - - </p>
    </>
  );
};

export default GoogleLoginButton;
