import React from 'react';
import { useDeleteProperty } from '../../../hooks/mutations/properties';
import PropertyDeleteConfirm from '../../Dialogs/delete';
import ActionItem from './action-item';

const DeleteProperty = ({ ids, setSelectedProperties }) => {
  const deletePropertyMutation = useDeleteProperty();

  return (
    <ActionItem
      label="Delete"
      icon="pi pi-trash"
      onClick={() => PropertyDeleteConfirm({
        onConfirm: async () => {
          await deletePropertyMutation.mutateAsync({ listingIds: ids });
          setSelectedProperties([]);
        }
      })}
      disabled={deletePropertyMutation.isPending}
      background="red-10"
    />
  );
};

export default DeleteProperty;
