import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ResetPasswordForm from "../../../Components/forms/reset-password-form";
import { SIGN_IN } from "../../../Routes/Constant";
import { useResetPassword } from "../../../hooks/mutations/auth";

function ResetPassword() {
  const resetPasswordMutation = useResetPassword();

  const handleSubmit = (values) => {
    resetPasswordMutation.mutate(values);
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid h-100">
        <section id="reset_page" className="h-100">
          <div className="row h-100 ">
            <div className="col-lg-6 px-0 h-100 d-none d-md-block px-0 h-100">
              <div className="left-box w-100 h-100 text-center position-relative">
                <div className="forgot-arrow">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/Assets/images/reset_lock.png"
                    }
                    className="img-fluid "
                    alt="..."
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-0 h-100">
              <div className="right-box h-100 display-center text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/client_images/horizontal_logo/Logo-white.png"
                  }
                  className="img-fluid w-25 text-center py-5 position-absolute top-0 d-md-none"
                  alt=""
                />
                <div className="inner-box m-auto h-auto ">
                  <h2 className="text-white mb-5">Reset your password</h2>
                  <ResetPasswordForm
                    onSubmit={handleSubmit}
                    isLoading={resetPasswordMutation.isPending}
                  />
                  <NavLink
                    to={SIGN_IN}
                    className="text-secondary text-decoration-none pb-5 mb-5"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/Assets/images/icons/left_arrow.svg"
                      }
                      alt=""
                      className="pe-2 "
                    />
                    Go back to login
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default ResetPassword;
