export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_REDIRECT_URL;

/* Server response message */
export const MESSAGE_NO_RECORD_FOUND = "Record not found"
export const MESSAGE_DUPLICATE_RECORD = "Duplicate record"
export const MESSAGE_ERROR_READING_RECORD = "Error reading record"
export const MESSAGE_SUCCESS_READING_RECORD = "Record get succes"
export const MESSAGE_ERROR_SERVER = " Internal Server Error !"
export const MESSAGE_SUCCESS_REGISTER = "User Register Successfull."
export const MESSAGE_ERROR_CREATING_RECORDS = "Error creating record"
export const MESSAGE_ERROR_UPDATING_RECORDS = "Error updating record"
export const MESSAGE_ERROR_DELETING_RECORDS = "Error deleting record"

export const MESSAGE_SUCCESS_CREATE_RECORD = "Record created"
export const MESSAGE_SUCCESS_UPDATE_RECORD = "Record updated"
export const MESSAGE_SUCCESS_DELETE_RECORD = "Record deleted"

export const MESSAGE_INVALID_LOGIN = "UserName and password not valid."
export const MESSAGE_VALID_LOGIN = "Login successful"

export const MESSAGE_NOT_LOGGED = "NOT LOGGED"
export const MESSAGE_BAD_REQUEST = "BAD REQUEST"

export const MESSAGE_DUPLICATE_EMAIL = "Email already exists"

/* Array of colors */
export const COLORS = ['blue', 'yellow']
