import { useQuery } from "@tanstack/react-query";
import { fetchConnections, fetchGoogleLogin, fetchUserData } from "../../services/api/auth/queries";

export const useCheckConnection = (enabled = true) => { // Needs to be disabled, so it doesn't run before login
  return useQuery({
    queryKey: ['checkConnection'],
    queryFn: fetchConnections,
    enabled
  });
};

export const useUserData = () => {
  return useQuery({
    queryKey: ['userData'],
    queryFn: fetchUserData
  });
}

export const useGoogleLogin = () => {
  return useQuery({
    queryKey: ['googleLogin'],
    queryFn: fetchGoogleLogin
  });
}