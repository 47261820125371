import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { includeExcludeUpdate } from "../../Redux/Features/authReducer/authSlice";
import { getListingSettingOfId } from "../../Redux/Features/listReducer/listSlice";
import { SelectButton } from 'primereact/selectbutton';

function SettingDialog(props) {
  const { visible, setVisible, propertySetting } = props;
  
  const dispatch = useDispatch();
  const options = ['On', 'Off'];

  const [loading, setLoading] = useState(false);

  const handleOnChange = async (e, value) => {
    setLoading(true);
    try {
    dispatch(includeExcludeUpdate({
        recordId: propertySetting?.recordId,
        [e.target.name]: value || e.target.value,
      })
    )
      .unwrap()
      .then((result) => {
        dispatch(
          getListingSettingOfId({
            recordId: propertySetting?.recordId,
          })
        )
          .unwrap();
        setLoading(false);
      });
    }
    catch(error){
      console.error(error);
      setLoading(false);
    }
  };

  const settingHeader = (
    <>
      <h4 className="color-black exMedium mb-3">Listing Content Settings</h4>
    </>
  );
  return (
    <>   
      <Dialog
        className="p-4 background-white rounded-17 mx-3 listing-setting"
        header={settingHeader}
        visible={visible}
        style={{ maxWidth: "550px", width: "100%" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >   
        <div>
          <div className="border-bottom modal-subadding">
            <label htmlFor="switch1" className="contentTxt">Content Overides</label>
            <p className="subaddingPTxt">The below toggle buttons allow you to turn off AutoRanks automated text updates for specific content areas.</p>
          </div>

          <div className="d-flex align-items-center justify-content-between border-bottom py-2">
            <label htmlFor="switch1" className="CstmListing-txtclr">Listing Title Automation</label>
            <SelectButton
              value={propertySetting?.isTitle === "yes" ? options[0] : options[1]}
              name="isTitle"
              onChange={(e) => {
                setLoading(true);
                handleOnChange(
                  e,
                  propertySetting?.isTitle === "yes" ? "no" : "yes"
                )
              }}
              options={options}
              disabled={loading}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between border-bottom py-2">
            <label htmlFor="switch1" className="CstmListing-txtclr">Listing Summary Automation</label>
            <SelectButton
              value={propertySetting?.isSummary === "yes" ? options[0] : options[1]}
              name="isSummary"
              onChange={(e) => {
                setLoading(true);
                handleOnChange(
                  e,
                  propertySetting?.isSummary === "yes" ? "no" : "yes"
                )
              }}
              options={options}
              disabled={loading}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between border-bottom py-2">
            <label htmlFor="switch1" className="CstmListing-txtclr">Listing Space Description</label>
            <SelectButton
              value={propertySetting?.isSpace === "yes" ? options[0] : options[1]}
              name="isSpace"
              onChange={(e) => {
                setLoading(true);
                handleOnChange(
                  e,
                  propertySetting?.isSpace === "yes" ? "no" : "yes"
                )
              }}
              options={options}
              disabled={loading}
            />
          </div>
          <div className="border-bottom">
            <label htmlFor="switch1" className="contentTxt mt-4">Listing Style & Formatting </label>
            <p className="subaddingPTxt">The below options allow you to customize the style and formatting of your listing content.</p>
          </div>
          <div className="d-flex align-items-center justify-content-between border-bottom py-2">
            <label htmlFor="switch1" className="CstmListing-txtclr">Listing Space Title Format</label>
            <select
              className="form-select btn-outline border-secondary Cstmfont18  w-auto pe-40"
              aria-label="Select option"
              name="format"
              onChange={(e) => handleOnChange(e)}
            >
              <option value={1} selected={(propertySetting?.format) == 1}>
                Standard
              </option>
              <option value={2} selected={(propertySetting?.format) == 2}>
                Customize
              </option>
            </select>
          </div>
           <div className="d-flex align-items-center justify-content-between border-bottom py-2">
            <label htmlFor="switch1" className="CstmListing-txtclr">Listing Space Format</label>
            <select
              className="form-select btn-outline border-secondary  Cstmfont18 w-auto pe-40"
              aria-label="Select option"
              name="bullet_option"
              onChange={(e) => handleOnChange(e)}
            >
              <option value={"yes"} selected={(propertySetting?.bullet_option) == "yes"}>
                Bullet
              </option>
              <option value={"no"} selected={(propertySetting?.bullet_option) == "no"}>
                Paragraph
              </option>
            </select>
          </div>
        </div>       
      </Dialog>   
    </>
  );
}
export default SettingDialog;