import React from "react";
import { Routes, Route } from "react-router-dom";
import * as Constants from "./Constant";
import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import SignIn from "../Pages/Authentication/SignIn";
import GoogleCallback from "../Pages/Authentication/SignIn/GoogleCallback";
import SignUp from "../Pages/Authentication/Register";
import ForgotPassword from "../Pages/Authentication/ForgotPassword";
import ResetPassword from "../Pages/Authentication/ForgotPassword/ResetPassword";
import Dashboards from "../Pages/Dashboards";
import HostayConnection from "../Pages/Hostaway";
import Guesty from "../Pages/Guesty";
import PropertyListing from "../Pages/PropertyListing";
import PageNotFound from "../Pages/Page_Not_Found/PageNotFound";
import UserList from "../Pages/Users/UserList";
import Log from "../Pages/Log";
import Connection from "../Pages/Connection";
import Settings from "../Pages/Settings";
import Insights from "../Pages/Insights";
import Notification from "../Pages/Notification";
import PropertyDetails from "../Pages/PropertyDetail";
import PaymentSuccessfully from "../Pages/PaymentSuccessfully";
import PaymentRejected from "../Pages/PaymentRejected";
import UpdatedHistory from "../Pages/UpdatedHistory";
import ConnectedUsersList from "../Pages/ConnectedHostawayUsersList/ConnectedUsersList";
import CheckAuthRoutes from "./ProtectedRoutes/CheckAuthRoutes";
import Coupon from "../Components/Coupon";
import COUPON from "../Routes/Constant";

const Routing = () => {
  return (
    <>
      <Routes>

        <Route element={<CheckAuthRoutes />} >
          <Route path={Constants.SIGN_IN} element={<SignIn />} />
          <Route path={Constants.CALLBACK} element={<GoogleCallback />} />
          <Route path={Constants.INDEX} element={<SignIn />} />
          <Route path={Constants.SIGN_UP} element={<SignUp />} />
          <Route path={Constants.FORGET_PASSWORD} element={<ForgotPassword />} />
          <Route path={Constants.RESET_PASSWORD} element={<ResetPassword />} />
        </Route>
        <Route path={Constants.PAGE_NOT_FOUND} element={<PageNotFound />} />

        {/* Protected routes */}
        <Route element={<ProtectedRoutes />}>
          <Route path={Constants.DASHBOARD} element={<Dashboards />} />
          <Route path={Constants.CONNECTION} element={<Connection />} />
          <Route path={Constants.HOSTAWAYCONNECTION} element={<HostayConnection />} />
          <Route path={Constants.GUESTYCONNECTION} element={<Guesty />} />
          <Route path={Constants.LISTING} element={<PropertyListing />} />
          <Route path={Constants.USERLIST} element={<UserList />} />
          <Route path={Constants.LOG} element={<Log />} />
          <Route path={Constants.SETTINGS} element={<Settings />} />
          <Route path={Constants.INSIGHTS} element={<Insights />} />
          <Route path={Constants.NOTIFICATION} element={<Notification />} />
          <Route path={Constants.UPDATED_HISTORY} element={<UpdatedHistory />} />
          <Route path={Constants.PROPERTY_DETAILS} element={<PropertyDetails />} />
          <Route path={Constants.PAYMENT_SUCCESS} element={<PaymentSuccessfully />} />
          <Route path={Constants.PAYMENT_REJECTED} element={<PaymentRejected />} />
          <Route path={Constants.CONNECTEDHOSTAWAYUSERLIST} element={<ConnectedUsersList />} />
          <Route path={Constants.COUPON} element={<Coupon />} />
        </Route>
      </Routes>
    </>
  );
};

export default Routing;
