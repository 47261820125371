import axios from "axios";
import { getToken, getToken2, setSubsId } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";

import {
    BASE_URL,
    SUBSCRIPTION,
    BUY_SUBSCRIPTION,
    CHECK_PAYMENT_STATUS
} from "../../../Config/Config"

// Fetch subscription details  API
const fetchSubscriptionApi = async () => {
    const response = await axios.get(
        BASE_URL + SUBSCRIPTION,
        HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
};

// Buy subscription API
const buySubscriptionApi = async (data) => {
    const response = await axios.post(
        BASE_URL + BUY_SUBSCRIPTION,
        data,
        HeaderToken(getToken2() ? getToken2() : getToken()),
    );
    if (response?.data?.success) {
        setSubsId(response.data.id)
       }  

    return response?.data;
};

// Buy subscription API
const checkPaymentStatusApi = async (data) => {
    const response = await axios.post(
        BASE_URL + CHECK_PAYMENT_STATUS,
        data,
        HeaderToken(getToken2() ? getToken2() : getToken()),
    );
    return response?.data;
};

const subscriptionService = {
    fetchSubscriptionApi,
    buySubscriptionApi,
    checkPaymentStatusApi
}

export default subscriptionService;