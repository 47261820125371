import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import {
  automatedListingDataApi,
  reviewScoreDataApi,
  bookingPaceMomentumDataApi,
  totalRevenueforMonthDataApi,
  bookingPaceMomentumAirbnbDataApi,
  topBookingSitesDataApi,
  totalRevenueMonthAndYearDataApi,
  getAverageReviewScore,
  automatedAndUnautomatedListingData,
} from "../../Redux/Features/dashboard/dashboardSlice";
import BarChart from "../../Components/dashboard/BarChart";
import DoughnutChart from "../../Components/dashboard/DoughnutChart";
import LineChart from "../../Components/dashboard/LineChart";
import AvgReviewScore from "../../Components/dashboard/AvgReviewScore";
import DashboardCard from "../../Components/dashboard/DashboardCard";

function Dashboards() {
  const dispatch = useDispatch();

  const {
    automatedListingData,
    reviewScoreData,
    bookingPaceMom,
    totalRevenueforMonth,
    bookingPaceMomAirbnb,
    totalRevenuMonthAndYearWise,
    avgReviewScore,
    automatedAndUnautomatedListing,
  } = useSelector((state) => state.Dashboard);

  useEffect(() => {
    dispatch(automatedListingDataApi());
    dispatch(reviewScoreDataApi());
    dispatch(bookingPaceMomentumDataApi());
    dispatch(totalRevenueforMonthDataApi());
    dispatch(bookingPaceMomentumAirbnbDataApi());
    dispatch(topBookingSitesDataApi());
    dispatch(totalRevenueMonthAndYearDataApi());
    dispatch(getAverageReviewScore());
    dispatch(automatedAndUnautomatedListingData());
  }, []);

  return (
    <>
      <div id="dashboard">
        <div id="wrapper">
          <SideBar />
          <Header />
          <section id="content-wrapper">
            <div className="welcome-user ">
              <h2 className=" content-title mb-0 exDemiBold mb-3">Dashboard</h2>

              {/* <p className="text-navy-light mb-3">
                Lorem ipsum dolor sit amet, consectetur.
              </p> */}
              <div className="dashboard_cards row mb-4 gy-4">
                <DashboardCard
                  imgURL={
                    process.env.PUBLIC_URL +
                    "/Assets/images/icons/automatization.svg"
                  }
                  cardHeading={
                    automatedListingData?.data?.totalAutomatedListing || 0
                  }
                  cardContent="Automated Listings"
                  percent={`${automatedListingData?.data?.totalPercentage || 0
                    }%`}
                  duration="(Past 30 days)"
                  bgColor={"rgba(137,255,212,.2)"}
                  IconOuter={"var(--mint-40)"}
                />
                <DashboardCard
                  imgURL={
                    process.env.PUBLIC_URL +
                    "/Assets/images/icons/review_score.svg"
                  }
                  cardHeading={
                    reviewScoreData?.data?.sumOfUpdatedReviewScoreData || 0
                  }
                  cardContent="Review Score"
                  percent={`${reviewScoreData?.data?.totalPercentage || 0}%`}
                  duration="(Past 30 days)"
                  bgColor={"var(--lime-20)"}
                  IconOuter={"var(--lime-40)"}
                />
                <DashboardCard
                  imgURL={
                    process.env.PUBLIC_URL + "/Assets/images/icons/momentum.svg"
                  }
                  cardHeading={bookingPaceMom?.percentageLastTwoDays || 0}
                  cardContent="Booking Pace Momentum"
                  percent={`${bookingPaceMom?.totalBookingPaceData || 0}%`}
                  duration="(Past 02 days)"
                  bgColor={"var(--purple-15)"}
                  IconOuter={"var(--purple-25)"}
                />
                <DashboardCard
                  imgURL={
                    process.env.PUBLIC_URL +
                    "/Assets/images/icons/account_balance_wallet.svg"
                  }
                  cardHeading={totalRevenueforMonth?.totalRevenue || 0}
                  cardContent="This Months Revenue"
                  percent={`${totalRevenueforMonth?.percentage || 0}%`}
                  duration="(Past 30 days)"
                  bgColor={"var(--navy-10)"}
                  IconOuter={"var(--navy-15)"}
                />
              </div>
              <div className="db-charts">
                <div className="row g-4">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <BarChart
                      bookingPaceMomAirbnb={bookingPaceMomAirbnb}
                      className="h-100"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {" "}
                    <DoughnutChart
                      automatedAndUnautomatedListing={
                        automatedAndUnautomatedListing
                      }
                      className="h-100"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <LineChart
                      totalRevenuMonthAndYearWise={totalRevenuMonthAndYearWise}
                      className="h-100"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <AvgReviewScore
                      avgReviewScore={avgReviewScore}
                      className="h-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Dashboards;
