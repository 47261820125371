import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SignInForm from "../../../Components/forms/sign-in-form";
import { FORGET_PASSWORD, SIGN_UP } from "../../../Routes/Constant";
import { useLogin } from "../../../hooks/mutations/auth";
import GoogleLoginButton from "./GoogleLogin";

const SignIn = () => {
  const loginMutation = useLogin();

  const handleSubmit = async (values) => {
    const data = {
      email: values.email,
      password: values.password.replace(/\s+/g, ""),
    };
    loginMutation.mutate(data);
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid h-100">
        <section id="login_page" className="h-100">
          <div className="row h-100">
            <div className="col-lg-6 px-0 h-100 d-none d-md-block px-0 h-100">
              <div className="left-box w-100 h-100 text-center position-relative">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/client_images/horizontal_logo/Logo navy-purple.png"
                  }
                  className="  w-25 text-center py-5"
                  alt=""
                />
                <div className="scrnshot w-100">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/client_images/scrnshot/user_dashboard.png"
                    }
                    className="  w-50 "
                    alt=""
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/client_images/scrnshot/user_listings.png"
                    }
                    className="  w-50"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-0 h-100">
              <div className="right-box h-100 display-center text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/client_images/horizontal_logo/Logo-white.png"
                  }
                  className="img-fluid w-25 text-center py-5 position-absolute top-0 d-md-none"
                  alt=""
                />
                <div className="inner-box m-auto h-auto">
                  <h2 className="text-white mb-4">Welcome back</h2>
                  <GoogleLoginButton />
                  <SignInForm
                    onSubmit={handleSubmit}
                    isLoading={loginMutation.isPending}
                  />
                  <p className="text-secondary mt-5 mb-2">
                    Don't have an account?
                    <NavLink
                      to={SIGN_UP}
                      className="text-white ms-2 text-decoration-none"
                    >
                      <span>Register</span>
                    </NavLink>
                  </p>
                  <NavLink
                    to={FORGET_PASSWORD}
                    className="text-white pb-4 text-decoration-none"
                  >
                    <span>Forgot Password?</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default SignIn;
