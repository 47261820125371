import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import logService from "./logService"

const user = localStorage.getItem("user") || {};
const initialState = {
  user: user ? user : null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  response: null,
  message: "",
  subscriptionResponse: null,
  logsData: ""
}

/* Fetch logs Details   */
export const fetchLogsApi = createAsyncThunk(
    "getUserAuditLogData",
    async (_, thunkAPI) => {
      try{
        return await logService.fetchLogsApi();
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  /*  For Slice */
const logSlice = createSlice({
    name: "logs",
    initialState,
    reducers: {
      reset: (state) => {
        state.isSuccess = false;
        state.isError = false;
        state.isLoading = false;
        state.message = "";
        state.response = null
        state.subscriptionResponse = null
      },
     
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchLogsApi.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchLogsApi.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.logsData = payload;        
        })
        .addCase(fetchLogsApi.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;        
        })
    },
});

export const { reset } = logSlice.actions;
export default logSlice.reducer;