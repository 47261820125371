import { Link } from "react-router-dom";
import ActionDialog from "../PropertyDetail/actions/action-dialog";

const PropertyDeleteConfirm = ({ onConfirm }) => {
  return ActionDialog({
    title: "Are you sure?",
    message: "Do you want to delete property listings?",
    extraInfo: (
      <>
        Removing a listing will not reduce the subscription amount. To reduce the amount, go to the{" "}
        <Link
          to={process.env.REACT_APP_STRIPE_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="text-purple"
        >
          billing page
        </Link>.
      </>
    ),
    danger: true,
    onAccept: onConfirm,
    acceptLabel: "Yes",
    rejectLabel: "No",
  });
};

export default PropertyDeleteConfirm;