import { ErrorMessage, Field, Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextError from "../../Components/TextError";
import { useGenerateHostawayToken } from "../../hooks/mutations/tokens";
import { setHostawayModalVisible } from "../../Redux/Features/stateReducer/stateSlice";
import { HostawayAPISchema } from "../../Utils/Validation/Validation";
import { useCheckConnection } from "../../hooks/queries/auth";

function Hostway() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [initialValues, setInitialValues] = useState({
    hostawayAccountId: "",
    hostawayAccount_secret: "",
  });
  const [fieldType, setFieldType] = useState({
    key: "password",
    token: "password",
  });

  const checkConnection = useCheckConnection();
  const isHostawayConnected = checkConnection?.data?.result?.hostaway;
  const { hostawayModalVisible } = useSelector((state) => state.State);

  function handleFieldFocus(value, value2) {
    if ("in" === value && "key" === value2) {
      setFieldType({ key: "text", token: "password" });
    } else if ("in" === value && "token" === value2) {
      setFieldType({ key: "passwprd", token: "text" });
    } else {
      setFieldType({ key: "password", token: "password" });
    }
  }

  const generateHostawayTokenMutation = useGenerateHostawayToken(setLoading);

  const onSubmit = async (values) => {
    setLoading(true);
    generateHostawayTokenMutation.mutate({
      clientId: values.hostawayAccountId,
      clientSecret: values.hostawayAccount_secret,
    });
  };

  const HostawayHeader = (
    <>
      <h4 className="color-black exMedium mb-3">Connect to Hostaway Account</h4>
    </>
  );

  return (
    <>
      <Dialog
        className="p-4 background-white rounded-17 mx-3 listing-setting"
        header={HostawayHeader}
        visible={hostawayModalVisible}
        style={{ maxWidth: "550px", width: "100%" }}
        onHide={() => {
          if (!hostawayModalVisible) return;
          dispatch(setHostawayModalVisible(false));
        }}
      >
        <div id="hostaway_connection">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={HostawayAPISchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className="row py-4 mx-2 aligndiv">
                <div className="col-md-6 px-0">
                  <label for="hostawayAccountId" className="exMedium">
                    Account ID <span className="strictly">*</span>
                  </label>
                </div>
                <div className="col-md-6 px-0">
                  <Field
                    type={fieldType.key}
                    className="w-100 form-control form-control-lg input-lg rounded-pill color-dark "
                    id="hostawayAccountId"
                    placeholder="Enter Hostaway API Key"
                    name="hostawayAccountId"
                    onFocus={(e) => {
                      handleFieldFocus("in", "key");
                    }}
                    onBlur={(e) => {
                      handleFieldFocus("out", "key");
                    }}
                  />
                  <ErrorMessage name="hostawayAccountId" component={TextError} />
                </div>
              </div>
              <div className="row border-bottom py-4 mx-2">
                <div className="col-md-6 px-0">
                  <label for="hostawayAccount_secret" className="exMedium">
                    Account Token <span className="strictly">*</span>
                  </label>
                </div>
                <div className="col-md-6 px-0">
                  <Field
                    type={fieldType.token}
                    className="w-100 form-control form-control-lg input-lg rounded-pill color-dark "
                    id="hostawayAccount_secret"
                    placeholder="Enter Hostaway API Token"
                    name="hostawayAccount_secret"
                    onFocus={(e) => {
                      handleFieldFocus("in", "token");
                    }}
                    onBlur={(e) => {
                      handleFieldFocus("out", "key");
                    }}
                  />
                  <ErrorMessage name="hostawayAccount_secret" component={TextError}
                  />
                </div>
              </div>
              {isHostawayConnected
                ?
                <div className="login-card-bottom mt-4 text-end">
                  <button
                    type="submit"
                    className="btn btn-lg button-purple rounded-pill ms-auto mt-2"
                    disabled={loading} // Disable button while loading
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm me-2 text-dark"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Reset Hostaway Token"
                    )}
                  </button>
                </div>
                : (
                  <div className="login-card-bottom mt-4 text-end">
                    <button
                      type="submit"
                      className="btn btn-lg button-purple rounded-pill ms-auto mt-2"
                      disabled={loading} // Disable button while loading
                    >
                      {loading ? (
                        <span
                          className="spinner-border spinner-border-sm me-2 text-dark"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Connect Hostaway"
                      )}
                    </button>
                  </div>
                )}
            </Form>
          </Formik>
        </div>
      </Dialog>
    </>
  );
}

export default Hostway;
