import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "../Features/authReducer/authSlice";
import commonStateSlice from "../Features/commonStateSlice/commonStateSlice";
import logSlice from "../Features/logReducer/logSlice";
import listSlice from "../Features/listReducer/listSlice";
import subscriptionSlice from "../Features/subscriptionReducer/subscriptionSlice";
import dashboardSlice from "../Features/dashboard/dashboardSlice";
import notificationSlice from "../Features/notificationReducer/notificationSlice";
import settingSlice from "../Features/settingReducer/settingSlice";
import couponSlice from "../Features/couponReducer/couponSlice";
import attractionsSlice from "../Features/attractionsReducer/attractionsSlice";
import stateSlice from "../Features/stateReducer/stateSlice";

const rootReducer = combineReducers({
  Auth: authSlice,
  commonState: commonStateSlice,
  Log: logSlice,
  List: listSlice,
  Subscription: subscriptionSlice,
  Dashboard: dashboardSlice,
  Notification: notificationSlice,
  Setting: settingSlice,
  Coupon:couponSlice,
  Attractions: attractionsSlice,
  State:stateSlice
});

export default rootReducer;
