import { Field } from "formik";
import { Button } from "primereact/button";
import { useState } from "react";

const PasswordField = ({ name, value, onChange, onBlur, placeholder = "Password*" }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="input-group mb-0">
      <span
        className="input-group-text bg-transparent border-end-0 input-border"
        id="basic-addon1"
      >
        <img
          src={process.env.PUBLIC_URL + "/Assets/images/icons/lock.svg"}
          alt=""
        />
      </span>
      <Field
        type={showPassword ? "text" : "password"}
        className="form-control bg-transparent border-start-0 border-end-0 rounded-0 py-3 ps-0 input-border"
        id={name}
        name={name}
        placeholder={placeholder}
        value={value?.replace(/\s+/g, "")}
        onChange={onChange}
        onBlur={onBlur}
      />
      <span>
        <Button
          type="button"
          className={`passEye pi border-start-0 ${showPassword ? "pi-eye-slash" : "pi-eye"
            }`}
          onClick={handlePasswordVisibility}
        />
      </span>
    </div>
  );
};

export default PasswordField;