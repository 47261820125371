import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { useEffect, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { useSavePropertySettings, useUpdateOriginal } from "../../../hooks/mutations/properties";
import ExpandableDescriptionElement from "./expandable";

const Description = ({ id, ogTitle, title, ogSummary, summary, ogSpace, space, initialValues, touristAttractions, events }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [original, setOriginal] = useState(true);

  const handleSelectChange = (e) => {
    const newValue = e.value === null ? !original : e.value;
    setOriginal(newValue);
  };

  const propertySettingsMutation = useSavePropertySettings();

  const updateOriginalMutation = useUpdateOriginal();

  const saveDescription = (values) => {
    propertySettingsMutation.mutateAsync({
      recordId: values.recordId,
      descriptionInclude: values.include,
      descriptionExclude: values.exclude,
      descriptionAdditional: values.additional,
    });
    updateOriginalMutation.mutateAsync({
      recordId: values.recordId,
      title: values.title,
      airbnbSummary: values.summary,
      airbnbSpace: values.space,
    });
  };

  useEffect(() => {
    if (propertySettingsMutation.isSuccess && updateOriginalMutation.isSuccess) {
      setIsEditing(false);
    }
  }, [propertySettingsMutation.isSuccess, updateOriginalMutation.isSuccess]);

  const displayTitle = original ? ogTitle : title;
  const displaySummary = original ? ogSummary : summary;
  const displaySpace = original ? ogSpace : space;

  const handleButtonClick = () => {
    setIsEditing(!isEditing);
    setOriginal(true);
  }

  const isLoading = updateOriginalMutation.isPending || propertySettingsMutation.isPending;

  return (
    <div className="mt-4 border rounded-17 p-4">
      {
        <>
          <div className="d-flex align-items-center justify-content-between">
            <SelectButton
              className="original-updated"
              value={original}
              onChange={handleSelectChange}
              options={[{ label: 'Original', value: true }, { label: 'Last Sync', value: false }]}
              disabled={isEditing}
            />
            <div>
              <Button
                onClick={handleButtonClick}
                className="btn btn-purple-outline"
              >
                <i className="pi pi-pen-to-square me-2"></i>
                {!isEditing ? "Edit original" : "Cancel"}
              </Button>
            </div>
          </div>
          {isEditing ? (
            <Formik
              initialValues={{
                recordId: id,
                title: ogTitle,
                summary: ogSummary,
                space: ogSpace,
                additional: initialValues?.descriptionAdditional,
                include: initialValues?.descriptionInclude,
                exclude: initialValues?.descriptionExclude,
              }}
              enableReinitialize
              onSubmit={(values) => saveDescription(values)}
            >
              {({ values, handleChange, handleBlur, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <label className="exMedium mt-4 fs-4 mb-2" style={{ lineHeight: "1.2" }}>Title</label>
                  <Field
                    className="form-control text-navy-light"
                    id="title"
                    name="title"
                    value={values?.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></Field>
                  <label className="exMedium mt-2 fs-4 mb-2" style={{ lineHeight: "1.2" }}>Summary</label>
                  <TextareaAutosize
                    className="form-control text-navy-light"
                    id="summary"
                    name="summary"
                    value={values?.summary}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxRows={10}
                  />
                  <label className="exMedium mt-2 fs-4 mb-2" style={{ lineHeight: "1.2" }}>Space</label>
                  <TextareaAutosize
                    className="form-control text-navy-light"
                    id="space"
                    name="space"
                    value={values?.space}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxRows={10}
                  />
                  <div className="d-flex justify-content-between flex-wrap gap-3">
                    <div className="flex-grow-1">
                      <label className="exMedium mt-2 fs-6 mb-2" style={{ lineHeight: "1.2" }}>Include</label>
                      <TextareaAutosize
                        className="form-control text-navy-light"
                        id="include"
                        name="include"
                        value={values?.include}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxRows={2}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <label className="exMedium mt-2 fs-6 mb-2" style={{ lineHeight: "1.2" }}>Exclude</label>
                      <TextareaAutosize
                        className="form-control text-navy-light"
                        id="exclude"
                        name="exclude"
                        value={values?.exclude}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxRows={2}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <label className="exMedium mt-2 fs-6 mb-2" style={{ lineHeight: "1.2" }}>Additional</label>
                      <TextareaAutosize
                        className="form-control text-navy-light"
                        id="additional"
                        name="additional"
                        value={values?.additional}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxRows={2}
                      />
                    </div>
                  </div>
                  <div className="text-end mt-3">
                    <Button type="submit" className="btn button-purple" disabled={isLoading}>
                      {isLoading ? (
                        <>
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving
                        </>
                      ) : (
                        <>
                          <i className="pi me-2 pi-save"></i>
                          Save
                        </>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <>
              <h4 className="exMedium mt-4 fs-4">Title</h4>
              <h5 className="text-navy-light">{displayTitle}</h5>
              <ExpandableDescriptionElement elementName="Summary" element={displaySummary} />
              <ExpandableDescriptionElement elementName="Space" element={displaySpace} />
            </>
          )
          }
        </>
      }

      <h5 className="fs-18 exMedium mb-3 mt-5">Nearby Tourist Attractions</h5>
      {touristAttractions?.length > 0 ? (
        <div>
          {touristAttractions?.map((attraction, index) => (
            <div key={index} className="d-flex">
              {" "}
              &#10148;{" "}
              <p className="ms-2 text-navy-light">
                <span
                  className="exMedium color-dark">{attraction?.name?.replace(/\s*\([^)]+\)/, '')} {attraction.name ? `:` : null} </span> {attraction.description}
                <small className="w-100 color-dark d-flex align-items-center mt-2">
                  <i className="pi pi-map-marker me-2"></i>
                  {attraction?.name?.match(/\(([^)]+)\)/)?.[1]} away
                </small>
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-navy-light">No nearby tourist attractions added.</p>
      )}

      <h5 className="fs-18 exMedium mb-3">Nearby Events</h5>
      {
        events?.length > 0 ? (
          <div>

            {events?.map((event, index) => (
              <div key={index} className="d-flex">
                {" "}
                &#10148;{" "}
                <p className="ms-2 text-navy-light mb-0">
                  <span className="exMedium color-dark">
                    {event?.name}
                  </span>
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-navy-light">No nearby Events added.</p>
        )
      }
    </div >
  )
}

export default Description;