import axiosInstance from "../axiosInstance";

export const inactivateNotifications = async (data) => {
  const url = "/notification/inactive";
  return axiosInstance.put(url, data);
}

export const markAllRead = async (data) => {
  const url = "/notification/mark/read";
  return axiosInstance.put(url, data);
}

export const activateWebNotifications = async (data) => {
  const url = "/notification/web/activate";
  return axiosInstance.post(url, data);
}

export const activateEmailNotifications = async (data) => {
  const url = "/notification/email/activate";
  return axiosInstance.post(url, data);
}