import React from 'react';
import VerticalDivider from '../../ui/vertical-divider';

const PropertyActions = ({ actions }) => {
  return (
    <div className="d-flex align-items-center flex-row flex-wrap justify-content-end property-actions">
      {actions.map((action, index) => (
        <React.Fragment key={index}>
          <action.Component {...action.props} />
          {index < actions.length - 1 && <VerticalDivider />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default PropertyActions;
