import { Dropdown } from "primereact/dropdown";

const SelectSetting = ({ label, value, name, options, handleChange }) => {
  return (
    <div className="d-flex align-items-center justify-content-between border-bottom py-2">
      <label htmlFor="switch1" className="CstmListing-txtclr">{label}</label>
      <Dropdown
        className="billing-drop-down w-auto pe-0"
        aria-label="Select option"
        name={name}
        onChange={(e) => handleChange(e)}
        value={value}
        options={options}
      >
      </Dropdown>
    </div>
  )
}

export default SelectSetting;