import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import RegistrationForm from "../../../Components/forms/registration-form";
import Otp from "../../../Components/OtpInput";
import CarouselItem from "../../../Components/Register/carousel-item";
import { useRegister, useRegisterOtp } from "../../../hooks/mutations/auth";
import { SIGN_IN } from "../../../Routes/Constant";

const Register = () => {
  const [otp, setOtp] = useState("");
  const [visible, setVisible] = useState(false);
  const [userDetail, setUserDetail] = useState("");
  const [buttonVisible, setButtonVisible] = useState(false);

  const checkOtpMutation = useRegisterOtp();
  const registrationMutation = useRegister(setButtonVisible, setVisible);

  const handleSubmit = (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email.toLowerCase(),
      phoneNumber: values.phoneNumber,
      password: values.password,
    };
    setUserDetail(data);
    checkOtpMutation.mutate({ email: data.email }, {
      onSuccess: () => {
        setVisible(true);
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  const submitUser = () => {
    if (otp != "") {
      setButtonVisible(true);
      registrationMutation.mutate({ ...userDetail, otp: otp });
    }
  }

  const carouselItems = [
    {
      image: "/Assets/images/signup_page_user.png",
      quote: "After using the solution our bookings and views skyrocketed.",
      author: "JB Spisso",
      company: "Casago"
    },
    {
      image: "/Assets/images/user.jpg",
      quote: "Lorem ipsum dolor sit amet, consectetur vel dipiscing elit.Ut lorem diam, sagittis vel dolor sit amet, porta sollicitudin urna.",
      author: "Jassie Robert",
      company: "CEO diam"
    },
    {
      image: "/Assets/images/user.jpg",
      quote: "Lorem ipsum dolor sit amet, consectetur vel dipiscing elit.Ut lorem diam, sagittis vel dolor sit amet, porta sollicitudin urna.",
      author: "Jassie Robert",
      company: "CEO diam"
    }
  ];

  return (
    <>
      <Otp
        otp={otp}
        setOtp={setOtp}
        visible={visible}
        setVisible={setVisible}
        submitUser={submitUser}
        data={userDetail}
        buttonVisible={buttonVisible}
      />
      <ToastContainer />
      <div className="container-fluid h-100">
        <section id="login_page" className="h-100">
          <div className="row h-100 ">
            <div className="col-lg-6 px-0 h-100 d-none d-md-block px-0 h-100">
              <div className="left-box w-100 h-100 text-center position-relative">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/client_images/horizontal_logo/Logo navy-purple.png"
                  }
                  className="   w-25 text-center py-5"
                  alt=""
                />
                <div className="client-img">
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      {carouselItems.map((item, index) => (
                        <CarouselItem
                          key={index}
                          image={item.image}
                          quote={item.quote}
                          author={item.author}
                          company={item.company}
                          isActive={index === 0}
                        />
                      ))}
                    </div>
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-0 h-100">
              <div className="right-box h-100 display-center text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/client_images/horizontal_logo/Logo-white.png"
                  }
                  className="img-fluid w-25 text-center py-5 position-absolute top-0 d-md-none"
                  alt=""
                />
                <div className="inner-box m-auto h-auto">
                  <h2 className="text-white mb-4">Register</h2>
                  <RegistrationForm
                    onSubmit={handleSubmit}
                    isLoading={checkOtpMutation.isPending}
                  />
                  <p className="text-secondary mt-3 mb-0">
                    Already have an account?
                    <NavLink
                      to={SIGN_IN}
                      className="text-white ms-2 text-decoration-none"
                    >
                      Login
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Register;
