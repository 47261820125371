import React from "react";
import Insight from "../../Components/Insights/Insight";

function Insights() {
  return (
    <div id="connection_page">
      <div className="welcome-user">
        <h2 className=" content-title mb-0 exDemiBold mb-2">Insight</h2>
        {/* <p className="text-navy-light mb-4">
                          Lorem ipsum dolor sit amet, consectetur.
                      </p> */}
      </div>
      <Insight />
    </div>
  )
}

export default Insights;
