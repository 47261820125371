import { Button } from "primereact/button";
import { Link } from "react-router-dom";

const PlanNotification = (props) => {

  const { title, description, actionText, bgColor, action } = props;

  return (
    <div className={`PlanNotificationWrapper ${bgColor}`}>
      <div>
        <p className="m-0">{title}</p>
        <p className="m-0" style={{ fontSize: "12px" }}>
          {description}
        </p>
      </div>
      <div>
        {action ? (
          <Button
            label={actionText}
            className="btn button-light font-normal ms-3 color-black fs-9"
            onClick={action}
          />
        ) : (
          <Link
            to={process.env.REACT_APP_STRIPE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="btn button-light font-normal ms-3 color-black fs-9"
          >
            {actionText}
            <i className="pi pi-external-link ms-2" style={{ fontSize: "11px" }}></i>
          </Link>
        )}
      </div>
    </div>
  )
}

export default PlanNotification;
