import axios from "axios";
import { getToken, getToken2 } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";
import { 
    BASE_URL, 
    LOGS
} from "../../../Config/Config"

// Fetch log details  API
const fetchLogsApi = async () => {
    const response = await axios.get(
      BASE_URL + LOGS,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

  const logService = {
    fetchLogsApi
  }

  export default logService;