import React, { useState } from 'react'
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from 'primereact/tag';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../Components/TextError/index";
import { Checkbox } from 'primereact/checkbox';

const CouponList = (props) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [showEmailModel, setShowEmailModel] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const ListingLimitInitialValues = {
        couponCode: " ",
        discountType: " ",
        discountValue: " "
    };

    const handleEdit = () => {
        setShowEditModal(true);
        console.log("Edit button clicked");
    };
    const handleEmail = () => {
        setShowEmailModel(true);
        console.log("Edit button clicked");
    };

    const handleDelete = () => {
        console.log("Delete button clicked");
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const handleSaveCoupon = async (values) => {
    }

    const statusBodyTemplate = (varient) => {
        return (
            <Tag
                value={varient.status === true ? "Active" : varient.status === false ? "Inactive" : "Unknown"}
                className={
                    varient.status === true
                        ? "mint-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
                        : varient.status === false
                            ? "red-10 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
                            : "lime-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
                }
            ></Tag>
        );
    };
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Keyword Search"
                    />
                </IconField>
            </div>
        );
    };

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2 mb-3">
            <span className="font-bold white-space-nowrap ">Update Discount Coupon</span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-pencil"
                    className="fw-bolder"
                    tooltip="Edit coupon"
                    tooltipOptions={{ position: "top" }}
                    rounded
                    outlined
                    severity="primary"
                    onClick={handleEdit}
                />
                <Button
                    type="button"
                    icon="pi pi-envelope "
                    className="fw-bolder"
                    tooltip="Send email"
                    tooltipOptions={{ position: "top" }}
                    rounded
                    outlined
                    severity="primary"
                    onClick={handleEmail}
                />
                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="fw-bolder"
                    tooltip="Delete coupon"
                    tooltipOptions={{ position: "top" }}
                    rounded
                    outlined
                    severity="danger"
                    onClick={handleDelete}
                />
            </React.Fragment>
        );
    };
    const header = renderHeader();
    return (
        <>
            <div
                className="tab-pane fade shadow-sm mt-4 rounded-17"
                id="couponList"
                role="tabpanel"
                aria-labelledby="couponList-tab"
            >
                <h2>Coupon List</h2>
                <DataTable
                    value={props.getCouponApiData}
                    dataKey="id"
                    tableStyle={{ minWidth: "50rem" }}
                    size="small"
                    showGridlines
                    removableSort
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    filters={filters}
                    filterDisplay="row"
                    globalFilterFields={["couponCode", "discountType", "discountValue", "expiryDate", "minValue"]}
                    emptyMessage="No Coupon Found"
                    header={header}
                >
                    <Column
                        field="couponCode"
                        header="Coupon Code"
                        className="exMono"
                        sortable
                    ></Column>
                    <Column
                        field="couponType"
                        header="Coupon Type"
                        className="exMono"
                        sortable
                    ></Column>
                    <Column
                        field="discountType"
                        header="Discount Type"
                        sortable
                    ></Column>
                    <Column
                        field="discountValue"
                        header="Discount Value"
                        className="exMono"
                        sortable
                    ></Column>
                    <Column
                        field="minValue"
                        header="Minimum Value"
                        className="exMono"
                        sortable
                    ></Column>
                    <Column
                        field="expiryDate"
                        header="Expiration Date"
                        className="exMono"
                        sortable
                    ></Column>
                    <Column
                        field="status"
                        header="Status"
                        className="exMono"
                        body={statusBodyTemplate}
                        sortable
                    ></Column>
                    <Column
                        header="Action"
                        body={actionBodyTemplate}
                    ></Column>
                </DataTable>
            </div>

            <Dialog
                className="p-3 background-white rounded-17"
                visible={showEditModal}
                modal
                header={headerElement}
                style={{ maxWidth: "800px", minWidth: "400px" }}
                onHide={() => {
                    if (!showEditModal) return;
                    setShowEditModal(false);
                }}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={ListingLimitInitialValues}
                    // validationSchema={PropertyLimitSchema}
                    onSubmit={handleSaveCoupon}
                >
                    {({ handleReset }) => (
                        <Form className="px-2">
                            <div className="mb-3">
                                <label htmlFor="name">Coupon Code</label>
                                <Field
                                    className="form-control rounded-pill mt-1 color-dark"
                                    type="text"
                                    id="couponCode"
                                    name="couponCode"
                                />
                                <ErrorMessage name="couponCode" component={TextError} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="name">Coupon Code</label>
                                <Field
                                    className="form-control rounded-pill mt-1 color-dark"
                                    type="text"
                                    id="discountType"
                                    name="discountType"
                                />
                                <ErrorMessage name="discountType" component={TextError} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="name">Coupon Code</label>
                                <Field
                                    className="form-control rounded-pill mt-1 color-dark"
                                    type="text"
                                    id="discountValue"
                                    name="discountValue"
                                />
                                <ErrorMessage name="discountValue" component={TextError} />
                            </div>
                            <div className="d-flex justify-content-between cstm-btn-group mt-3">
                                <Button
                                    type="button"
                                    className="btn btn-lg btn-secondary rounded-pill me-2"
                                    onClick={() => {
                                        handleReset();
                                        setShowEditModal(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-lg button-navy rounded-pill"
                                >
                                    Update
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Dialog>

            <Dialog
                className="p-3 cstm-width background-white rounded-17"
                visible={showEmailModel}
                modal
                header={headerElement}
                onHide={() => {
                    if (!showEmailModel) return;
                    setShowEmailModel(false);
                }}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={ListingLimitInitialValues}
                    // validationSchema={PropertyLimitSchema}
                    onSubmit={handleSaveCoupon}
                >
                    {({ handleReset }) => (
                        <Form className="px-2">
                            <div className="mb-3 d-flex align-items-center pt-3 justify-content-end">
                                <div>
                                    <Button
                                        className="btn font-normal text-purple button btn-purple-outline"
                                        label="Select All"
                                    />
                                </div>
                                <ErrorMessage name="couponCode" component={TextError} />
                            </div>
                            <div className="mb-3 d-flex align-items-center justify-content-between">
                                <label htmlFor="name">Name</label>
                                <Checkbox
                                    className="  color-dark"
                                    type="checked"
                                    id="couponCode"
                                    name="couponCode"
                                    checked={true}
                                />
                                <ErrorMessage name="couponCode" component={TextError} />
                            </div>
                            <div className="mb-3 d-flex align-items-center justify-content-between">
                                <label htmlFor="name">Name</label>
                                <Checkbox
                                    className="  color-dark"
                                    type="checked"
                                    id="couponCode"
                                    name="couponCode"
                                />
                                <ErrorMessage name="couponCode" component={TextError} />
                            </div>
                            <div className="mb-3 d-flex align-items-center justify-content-between">
                                <label htmlFor="name">Name</label>
                                <Checkbox
                                    className="  color-dark"
                                    type="checked"
                                    id="couponCode"
                                    name="couponCode"
                                />
                                <ErrorMessage name="couponCode" component={TextError} />
                            </div>

                            <div className="d-flex  cstm-btn-group justify-content-between mt-3">
                                <Button
                                    type="button"
                                    className="btn btn-lg btn-secondary rounded-pill me-2"
                                    onClick={() => {
                                        handleReset();
                                        setShowEmailModel(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-lg button-navy rounded-pill"
                                >
                                    Send Email
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    )
}

export default CouponList
