import axiosInstance from "../axiosInstance";

export const fetchDashboardData = async () => {
  const url = "/userDashBoard";
  return axiosInstance.get(url);
}

export const fetchDashboardStats = async () => {
  const url = "/userDashBoard/stats";
  return axiosInstance.get(url);
}

export const fetchDashboardAccomplishments = async () => {
  const url = "/userDashBoard/accomplishments";
  return axiosInstance.get(url);
}

export const fetchDashboardTop = async () => {
  const url = "/userDashBoard/top";
  return axiosInstance.get(url);
}