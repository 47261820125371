import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextError from "../../Components/TextError";
import { useGenerateGuestyToken } from "../../hooks/mutations/tokens";
import { useCheckConnection } from "../../hooks/queries/auth";
import { setGuestyModalVisible } from "../../Redux/Features/stateReducer/stateSlice";
import { GuestyAPISchema } from "../../Utils/Validation/Validation";


function Guesty() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State for loading indicator

  const InitialValues = {
    guestyAccount_secret: "",
  };

  const [fieldType, setFieldType] = useState({
    key: "password",
    token: "password",
  });

  const { guestyModalVisible } = useSelector((state) => state.State);
  const checkConnection = useCheckConnection();

  function handleFieldFocus(value, value2) {

    if ("in" === value && "key" === value2) {
      setFieldType({ key: "text", token: "password" });
    } else if ("in" === value && "token" === value2) {
      setFieldType({ key: "passwprd", token: "text" });
    } else {
      setFieldType({ key: "password", token: "password" });
    }
  }

  const generateGuestyTokenMutation = useGenerateGuestyToken(setLoading);

  const onSubmit = (values) => {
    setLoading(true);
    generateGuestyTokenMutation.mutate({ integrationToken: values.guestyAccount_secret });
  };

  const guestyHeader = (
    <>
      <h4 className="color-black exMedium mb-3">Connect to Guesty Account</h4>
    </>
  );

  return (
    <>
      <Dialog
        className="p-4 background-white rounded-17 mx-3 listing-setting"
        header={guestyHeader}
        visible={guestyModalVisible}
        style={{ maxWidth: "550px", width: "100%" }}
        onHide={() => {
          if (!guestyModalVisible) return;
          dispatch(setGuestyModalVisible(false));
        }}
      >
        <div id="hostaway_connection">
          <Formik
            initialValues={InitialValues}
            validationSchema={GuestyAPISchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className="row py-4 mx-2 aligndiv">
                <div className="col-md-6 px-0">
                  <label for="hostawayAccount_secret" className="exMedium">
                    Integration Token <span className="strictly">*</span>
                  </label>
                </div>
                <div className="col-md-6 px-0">
                  <Field
                    type={fieldType.token}
                    className="w-100 form-control form-control-lg input-lg rounded-pill color-dark "
                    id="guestyAccount_secret"
                    placeholder="Enter Integration Token"
                    name="guestyAccount_secret"
                    onFocus={(e) => {
                      handleFieldFocus("in", "token");
                    }}
                    onBlur={(e) => {
                      handleFieldFocus("out", "key");
                    }}
                  />
                  <ErrorMessage name="guestyAccount_secret" component={TextError}
                  />
                </div>
              </div>
              {/* {CheckHoatawayConnectionData?.result?.guesty
                    ? (
                      <div className="login-card-bottom mt-4 text-end">
                        <button
                          type="submit"
                          className="btn btn-lg button-purple rounded-pill ms-auto mt-2"
                          onClick={() => navigate("/admin/dashboard")}
                        >
                          Go back to Dashboard
                        </button>
                      </div>
                    )
                    : */}
              <div className="login-card-bottom mt-4 text-end">
                <Button
                  type="submit"
                  className="btn btn-lg button-purple rounded-pill ms-auto mt-2"
                  disabled={loading} // Disable button while loading
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2 text-dark"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    checkConnection?.result?.guesty ? "Reset Token" : "Connect Guesty"
                  )}
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </Dialog>
    </>
  );
}

export default Guesty;
