import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import React from 'react';


const ActionItem = ({ label, icon, onClick, disabled, isSwitch, checked, onChange, background, type, property, noLabel }) => {
  const isAutomatedAndIsPaid = property?.autoUpdateStatus === 1 && property?.isPaid === true;

  return (
    <div className="d-flex align-items-center flex-nowrap gap-3">
      {!noLabel && (<p className="mb-0 fs-22 exMedium">{label}</p>)}
      {isSwitch ? (
        <InputSwitch
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={`${(!property?.isPaid && property?.autoUpdateStatus === 1)
            ? 'paid-active'
            : (isAutomatedAndIsPaid === true)
            && 'green-color'
            }`}
        />
      ) : (
        <Button
          icon={type !== "text" ? icon : null}
          className={`rounded-circle text-navy fw-bold display-center ${background}`}
          style={{ height: "2.5rem", width: "2.5rem" }}
          rounded
          severity="secondary"
          onClick={onClick}
          disabled={disabled}
          {...(type ? { [type]: true } : {})}
        >
          {type === "text" && <i className={`pi ${icon} fs-3`}></i>}
        </Button>
      )}
    </div>
  );
};

export default ActionItem;
