import { ErrorMessage, Field, Form, Formik } from "formik";
import { ResetPasswordSchema } from "../../Utils/Validation/Validation";
import TextError from "../TextError";

const ForgotPasswordForm = ({ onSubmit, isLoading }) => {
  const initialValues = {
    email: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ResetPasswordSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="mb-1">
            <div className="input-group">
              <span
                className="input-group-text bg-transparent border-end-0 input-border"
                id="basic-addon1"
              >
                <img
                  src={process.env.PUBLIC_URL + "/Assets/images/icons/email.svg"}
                  alt=""
                />
              </span>
              <Field
                type="email"
                className="form-control bg-transparent border-start-0 rounded-0 py-3 input-border ps-0"
                id="loginEmail"
                name="email"
                placeholder="E-mail address"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <ErrorMessage name="email" component={TextError} />

          <button
            type="submit"
            className="btn button-light btn-lg form-control rounded-pill mt-4 mb-5"
            disabled={isLoading}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm me-2 text-dark"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Next"
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm; 