import React from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { formatedDateTime } from "../../../Utils/constant/commonFunction";
import { useDispatch } from "react-redux";
import { markAllReadApi } from "../../../Redux/Features/notificationReducer/notificationSlice";
import { arrayBufferToBase64 } from "../../../Utils/constant/commonFunction";
import { Button } from "primereact/button";

function Notification(props) {

  const navigate = useNavigate();
  const { data } = props;
  const dispatch = useDispatch();

  const notificationLength = data.filter((item) => !item.isSeen);
  const markAllAsRead = () => {
    dispatch(markAllReadApi(data.map((item) => item.id)));
  };

  const handleDarkMode = () => {
    document.body.classList.toggle('dark');
    const isDarkMode = document.body.classList.contains('dark');
    if (isDarkMode) {
      localStorage.setItem('theme', 'dark');
    } else {
      localStorage.setItem('theme', 'light');
    }
  };

  const redirectToPage = (recordId) => {
    localStorage.setItem('EditdID', (recordId)); // Store data as a string
    navigate("/propertydetails");
  };

  // Initialize the theme based on the user's preference in localStorage
  window.onload = () => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      document.body.classList.add('dark');
    }
  };

  return (
    <ul className="d-flex align-items-center ms-auto p-0 mb-2 mb-lg-0">
      <li className="nav-item list-group">
        <Button
          className="nav-link pt-2 me-3"
          tooltip="Theme Mode"
          tooltipOptions={{ position: "left" }}
          onClick={handleDarkMode}
        >
          <div className="icon-outer display-center lime-30">
            <svg
              width="22"
              height="22"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1288_596"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="28"
                height="28"
              >
                <rect width="28" height="28" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1288_596)">
                <path
                  d="M14.0001 17.5003C14.9723 17.5003 15.7987 17.16 16.4792 16.4795C17.1598 15.7989 17.5001 14.9725 17.5001 14.0003C17.5001 13.0281 17.1598 12.2017 16.4792 11.5212C15.7987 10.8406 14.9723 10.5003 14.0001 10.5003C13.0279 10.5003 12.2015 10.8406 11.5209 11.5212C10.8404 12.2017 10.5001 13.0281 10.5001 14.0003C10.5001 14.9725 10.8404 15.7989 11.5209 16.4795C12.2015 17.16 13.0279 17.5003 14.0001 17.5003ZM14.0001 19.8337C12.3862 19.8337 11.0105 19.2649 9.873 18.1274C8.7355 16.9899 8.16675 15.6142 8.16675 14.0003C8.16675 12.3864 8.7355 11.0107 9.873 9.87324C11.0105 8.73574 12.3862 8.16699 14.0001 8.16699C15.614 8.16699 16.9897 8.73574 18.1272 9.87324C19.2647 11.0107 19.8334 12.3864 19.8334 14.0003C19.8334 15.6142 19.2647 16.9899 18.1272 18.1274C16.9897 19.2649 15.614 19.8337 14.0001 19.8337ZM5.83341 15.167H1.16675V12.8337H5.83341V15.167ZM26.8334 15.167H22.1667V12.8337H26.8334V15.167ZM12.8334 5.83366V1.16699H15.1667V5.83366H12.8334ZM12.8334 26.8337V22.167H15.1667V26.8337H12.8334ZM7.46675 9.04199L4.52091 6.21283L6.18341 4.49199L8.98341 7.40866L7.46675 9.04199ZM21.8167 23.5087L18.9876 20.5628L20.5334 18.9587L23.4792 21.7878L21.8167 23.5087ZM18.9584 7.46699L21.7876 4.52116L23.5084 6.18366L20.5917 8.98366L18.9584 7.46699ZM4.49175 21.817L7.43758 18.9878L9.04175 20.5337L6.21258 23.4795L4.49175 21.817Z"
                  fill="#202744"
                />
              </g>
            </svg>
          </div>
        </Button>
      </li>
      <li className="nav-item list-group dropdown pt-2 notify-dropdown">
        <button
          className="nav-link border-end border-muted icons"
          id="navbarDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        > 

          <div className="icon-outer display-center  ms-3 me-md-5 me-3 purple-15 position-relative">
            <img
              src={
                process.env.PUBLIC_URL +
                "/Assets/images/icons/notification_icon.svg"
              }
              alt=""
            />
            <div className="notification-number text-white color-purple rounded-circle display-center">
              {notificationLength?.length}
            </div>
          </div>
        </button>
        <div
          className="dropdown-menu dropdown-menu-end p-0 border-0 shadow-sm rounded-17 mt-2"
          aria-labelledby="navbarDropdown"
        >
          <div className="d-flex align-items-center justify-content-between purple-15 px-3 py-3">
            <h5 className="mb-0 me-auto exMedium">Notifications</h5>
            <div className="">
              <img
                src={
                  process.env.PUBLIC_URL + "/Assets/images/icons/check_all.svg"
                }
                alt=""

              />
              <button
                className="mark-read btn btn-link text-decoration-none text-purple ps-1 "
                onClick={markAllAsRead}
              >
                Mark all as read
              </button>
            </div>
            <li>
              <hr className="dropdown-divider m-0 text-purple" />
            </li>
          </div>
          {(
            <ul className="list-unstyled">
              {data.map((item, index) => (
                <>
                  <div
                    className={`${item?.isSeen == 1
                      ? "dropdown-item d-flex align-items-center justify-content-between py-3 text-decoration-none bg-notification"
                      : "dropdown-item d-flex align-items-center justify-content-between py-3 text-decoration-none bg-not-unread"
                      }`}
                    onClick={() => item.recordId && redirectToPage(item.recordId)}
                  >
                    <div className="notify-msg position-relative ps-4">
                      <p className="text-wrap mb-0" >{item.notificationMessage}</p>
                      <span>
                        <small className="color-muted">
                          {formatedDateTime(item.updatedAt)}
                        </small>
                      </span>
                    </div>
                    <img
                      src={
                        item?.webNotificationThamnail
                          ? item?.webNotificationThamnail
                          : arrayBufferToBase64(item?.webUserThamnail?.data)
                      }
                      onError={(e) => {
                        e.target.src =
                          process.env.PUBLIC_URL +
                          "/Assets/images/house_placeholder.png"; // replace with a default image
                      }}
                      alt=""
                    />
                  </div>
                  <li>
                    <hr className="dropdown-divider m-0 color-muted" />
                  </li>
                </>
              ))}
            </ul>
          )}
          <div className="purple-15 text-center w-100">
            <NavLink
              to={"/notification"}
              className=" text-purple py-3 exMedium text-center text-decoration-underline d-block"
              href="allNotifications.html"
            >
              View all notifications{" "}
            </NavLink>
          </div>
        </div>
      </li>
    </ul>
  );
}

export default Notification;
