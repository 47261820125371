import { useQuery } from "@tanstack/react-query";
import { fetchAllAttractions, fetchAllEvents, fetchAudits, fetchEventsById, fetchPropertyById, fetchPropertyMap, fetchPropertySettings, fetchPropertyStats } from "../../services/api/properties/queries";

export const usePopertyById = (recordId) => {
  return useQuery({
    queryKey: ['propertyById', recordId],
    queryFn: () => fetchPropertyById(recordId)
  });
};

export const usePropertyStats = (recordId) => {
  return useQuery({
    queryKey: ['propertyStats', recordId],
    queryFn: () => fetchPropertyStats(recordId)
  });
}

export const usePropertySettings = (recordId) => {
  return useQuery({
    queryKey: ['propertySettings', recordId],
    queryFn: () => fetchPropertySettings(recordId)
  });
};

export const usePropertyMap = () => {
  return useQuery({
    queryKey: ['propertyMap'],
    queryFn: fetchPropertyMap
  });
}

export const useEventsById = (id) => {
  return useQuery({
    queryKey: ['events', id],
    queryFn: () => fetchEventsById(id)
  });
}

export const useAllEvents = (recordId) => {
  return useQuery({
    queryKey: ['events', recordId],
    queryFn: () => fetchAllEvents(recordId)
  });
}

export const useAllAttractions = (recordId) => {
  return useQuery({
    queryKey: ['attractions', recordId],
    queryFn: () => fetchAllAttractions(recordId)
  });
}

export const useAudits = (recordId) => {
  return useQuery({
    queryKey: ['audits', recordId],
    queryFn: () => fetchAudits(recordId)
  });
}