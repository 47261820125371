import PmsStep from './pms-step';

const PmsSteps = () => {
  const steps = [


    {
      number: 1,
      description: "Choose Your PMS provider: Review the available PMS providers displayed on the screen (e.g., Hostaway, Guesty, Hostfully, PriceLabs). Click the 'Connection' button next to your selected proider to initiate the connection process."
    },
    {
      number: 2,
      description: "Enter API Credentials: In your property management system, locate Autorank in the marketplace. Gather your API credentials from there, then return to AutoRank and paste them into the appropriate fields."
    },
    {
      number: 3,
      description: "Add and Manage Listings: Add your property listings directly through the AutoRank interface. These will automatically sync with AutoRank for optimized management."
    }
  ];

  return (
    <div className="pms-outer">
      {steps.map((step, index) => (
        <PmsStep
          key={step.number}
          number={step.number}
          description={step.description}
          isLast={index === steps.length - 1}
        />
      ))}
    </div>
  );
};

export default PmsSteps;