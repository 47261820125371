import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from 'primereact/dialog';
import { setCouponPopup, setToOpenSubscriptionModel } from '../../Redux/Features/stateReducer/stateSlice';
import { PAYMENT_URL } from "../../Config/Config";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { setPaymentId,removePaymentId } from "../../Redux/Features/LocalStorage";
  import {autoUpdateListing, HostawayLIstings} from "../../Redux/Features/authReducer/authSlice";
  import { getPropertyById} from "../../Redux/Features/listReducer/listSlice";
import SubscriptionModal from "../../Pages/SubscriptionModal";


const RedeemCoupon = () => {
  const [loading, setLoading] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
  // const [toOpenSubscriptionModel, setToOpenSubscriptionModel] = useState(false);
  
  const { getCouponApiData } = useSelector((state) => state.Coupon);
  const { toOpenSubscriptionModel } = useSelector((state) => state.State);
  console.log("toOpenSubscriptionModel:::",toOpenSubscriptionModel);
    const { propertyDetail } = useSelector(
        (state) => state.List);
        console.log("Data>>",propertyDetail?.data)
    const {couponPopup} = useSelector((state)=> state?.State);
    const dispatch = useDispatch()

    const gotopayment=async (id)=>{
        try {            
              setLoading(true);
              removePaymentId();
              if (propertyDetail?.data?.autoUpdateStatus == 0) {
                // setIsLoading(true);
                const { data } = await dispatch(
                  autoUpdateListing({
                    primaryPropertyId: propertyDetail?.data?.id,
                    redirectUrl: PAYMENT_URL,
                    couponId: id,
                  })
                )
                  .unwrap();
                await dispatch(getPropertyById({
                  recordId: propertyDetail?.data?.recordId,
                }))
                  .unwrap();
                await dispatch(HostawayLIstings())
                  .unwrap();
                // setIsLoading(false);
                setLoading(false);
                dispatch(setCouponPopup(false));
                if (data?.checkoutUrl) {
                  setPaymentId(data?.id);
                  window.location.href = data?.checkoutUrl;
                } else {
                  console.log("Checkout URL is missing");
                  dispatch(setToOpenSubscriptionModel(true));     
                  console.log("Testing...........",setToOpenSubscriptionModel(true)) 
                  dispatch(setCouponPopup(false));
                  setLoading(false);
                }
              } else {
                confirm(propertyDetail?.data);
              }
            }
            catch (error) {
              console.log(error);
              setLoading(false);
              dispatch(setCouponPopup(false));
            }
    };
    const confirm = (row) => {
        confirmDialog({
          header: (
            <img
              className="propertyImg  me-2"
              src={
                row?.thumbnailUrl ||
                process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"
              }
              onError={(e) => {
                e.target.src =
                  process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"; // replace with a default image
              }}
              alt="No Image"
            />
          ),
          message: (
            <div className="mx-3 my-2 text-center">
              <h6 className="fs-18 text-navy mt-3 exMedium">
                {row?.name}
              </h6>
              {/* <p className="text-navy-light">Harvest Horizon Homestead</p> */}
              <h5 className="exDemiBold fs-2 text-center text-navy my-2">
                <i className="pi pi-exclamation-triangle"></i> &nbsp; Are you sure?</h5>
              <p className="text-navy-light text-center w-75 m-auto">
                If you disable this listing, you will not be able to see future
                updates.
              </p>
            </div>
          ),
          closable: false, // Add this line to prevent closing the dialog
          defaultFocus: "accept",
          accept: () => acceptBullet(row),
          reject: () => rejectBullet(row),
        });
      };
    
      const acceptBullet = async (row) => {
        setLoading(true);
        await dispatch(
          autoUpdateListing({
            primaryPropertyId: row?.id,
          })
        )
          .unwrap()
          .then((result) => {
            dispatch(getPropertyById({ recordId: row?.recordId }))
              .unwrap();
            dispatch(HostawayLIstings()).unwrap();
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      };
    
      const rejectBullet = (row) => {
        setLoading(false);
      };

    
    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2 mb-3">
            <span className="font-bold white-space-nowrap ">Coupon Lists</span>
        </div>
    );

    return (
        <>
            <Dialog
                className="p-3 background-white rounded-17"
                visible={couponPopup}
                modal
                header={headerElement}
                style={{ maxWidth: "500px", minWidth: "400px" }}
                onHide={() => {
                    if (!couponPopup) return;
                    dispatch(setCouponPopup(false))
                    
                }}
            >
                <div className="modal-body">
                    {getCouponApiData?.data?.map((event, index) => (
                        event?.status && event?.isUsed === false ? (
                
                            <div key={index} className={"coupon"}>
                                {event?.discountType === "percentage" ? (
                                    <div className="left">
                                        <h2 className="fw-bold mb-0 text-white">{event?.discountValue}% OFF</h2>
                                    </div>
                                ) : (
                                    <div className="left">
                                        <h2 className="fw-bold mb-0 text-white">€{event?.discountValue} FLAT OFF</h2>
                                    </div>
                                )}
                                <div className="center">
                                    <div className="d-flex justify-content-between couponcard-header">
                                        <div>
                                            <h3 className="mb-0">{event?.couponCode}</h3>
                                            {event?.discountType === "percentage" ? (
                                                <small className="text-success">Save {event?.discountValue}% OFF on this order!</small>
                                            ) : (
                                                <small className="text-success">Save €{event?.discountValue} FLAT OFF on this order!</small>
                                            )}
                                        </div>
                                            <button className="btn p-0" disabled={loading} onClick={() =>gotopayment(event?.id)}>Apply</button>
                                    </div>
                                    {event?.discountType === "percentage" ? (
                                        <div className="pt-3 couponcard-body">
                                            <p className="couponcard-text">
                                                {`Use Code ${event?.couponCode} & get ${event?.discountValue} % OFF on this order. For apply this coupon, Please automate minimum €${event?.minValue} listings.`}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="pt-3 couponcard-body">
                                            <p className="couponcard-text">
                                                {`Use Code ${event?.couponCode} & get €${event?.discountValue} FLAT OFF on this order. For apply this coupon, Please automate minimum €${event?.minValue} listings.`}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>

                        ) : (

                            <div key={index} className={"coupon"} style={{ pointerEvents: 'none', opacity: 0.5 }}>
                                {event?.discountType === "percentage" ? (
                                    <div className="left">
                                        <h2 className="fw-bold mb-0 text-white">{event?.discountValue}% OFF</h2>
                                    </div>
                                ) : (
                                    <div className="left">
                                        <h2 className="fw-bold mb-0 text-white">€{event?.discountValue} FLAT OFF</h2>
                                    </div>
                                )}
                                <div className="center">
                                    <div className="d-flex justify-content-between couponcard-header">
                                        <div>
                                            <h3 className="mb-0">{event?.couponCode}</h3>
                                            {event?.discountType === "percentage" ? (
                                                <small className="text-success">Save{event?.discountValue}% OFF on this order!</small>

                                            ) : (
                                                <small className="text-success">Save €{event?.discountValue} FLAT OFF on this order!</small>
                                            )}
                                        </div>
                                       
                                            <button className="btn p-0" style={{color:"red"}}>Not Valid</button>
                        
                                    </div>
                                    {event?.discountType === "percentage" ? (
                                        <div className="pt-3 couponcard-body">
                                            <p className="couponcard-text">
                                                {`Use Code ${event?.couponCode} & get ${event?.discountValue} % OFF on this order. For apply this coupon, Please automate minimum €${event?.minValue} listings.`}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="pt-3 couponcard-body">
                                            <p className="couponcard-text">
                                                {`Use Code ${event?.couponCode} & get €${event?.discountValue} FLAT OFF on this order. For apply this coupon, Please automate minimum €${event?.minValue} listings.`}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    ))}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" disabled={loading} onClick={() =>gotopayment()}>Proceed without Coupon</button>
                </div>
            </Dialog>
          <ConfirmDialog className="toggleOffAlert" />
      {toOpenSubscriptionModel ? <SubscriptionModal /> : ''}


        </>
    )
}

export default RedeemCoupon;
