import axios from "axios";
import { BASE_URL } from "../../Config/Config";
import { setUser } from "../../Redux/Features/authReducer/authSlice";
import { store } from "../../Redux/Store";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.params) {
      config.params = cleanQueryParams(config.params);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch(setUser(null));
    }
    return Promise.reject(error);
  }
);

const cleanQueryParams = (params) => {
  if (params) {
    for (const key in params) {
      if (params[key] === undefined || params[key] === null || params[key] === '') {
        delete params[key];
      }
    }
  }
  return params;
};

export default axiosInstance
