import { Button } from "primereact/button";

const ColumnActionsButton = ({ rowData, icon, tooltip, onClick, disabled }) => {
  return (
    <Button
    className="rounded-circle"
    icon={`pi ${icon}`}
    tooltip={tooltip}
    tooltipOptions={{ position: "top" }}
    rounded
    text
    severity="secondary"
    onClick={onClick}
    {...(rowData && { disabled: !rowData.isPaid || disabled })}
  />
  );
};

export default ColumnActionsButton;