import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { getAverageReviewScore } from "../../../Redux/Features/dashboard/dashboardSlice";

export default function AvgReviewScore(props) {
  const dispatch = useDispatch();
  const { avgReviewScore } = props;

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [selectedyearlyData, setSelectedyearlyData] = useState({
    name: "This year",
    code: "TY",
  });
  const weeklyData = [
    { name: "This year", code: "TY" },
    { name: "Last year", code: "LY" },
  ];

  useEffect(() => {
    if (avgReviewScore?.success) {
      generateDataSets(avgReviewScore);
    }
  }, [avgReviewScore]);

  const generateDataSets = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    const firstDataSet = [];
    const secondDataSet = [];
    for (const [key, value] of Object?.entries(avgReviewScore)) {
      switch (key) {
        case "OneStar":
          firstDataSet[4] = value.onePercentage;
          secondDataSet[4] = value.oneStarCount;
          break;
        case "twoStar":
          firstDataSet[3] = value.twoPercentage;
          secondDataSet[3] = value.twoStarCount;
          break;
        case "threeStar":
          firstDataSet[2] = value.threePercentage;
          secondDataSet[2] = value.threeStarCount;
          // firstDataSet[2] = value.totalPrecentage;
          // secondDataSet[2] = value.fourStarCount;
          break;
        case "fourStar":
          firstDataSet[1] = value.fourPercentage;
          secondDataSet[1] = value.fourStarCount;
          break;
        case "fiveStar":
          firstDataSet[0] = value.fivePercentage;
          secondDataSet[0] = value.fiveStarCount;
          break;
        default:
      }
    }
    const data = {
      labels: ["5", "4 ", "3 ", "2 ", "1 "],
      datasets: [
        {
          //   label: 'My First dataset',
          backgroundColor: documentStyle.getPropertyValue("--purple-40"),
          borderColor: documentStyle.getPropertyValue("--purple-40"),
          data: firstDataSet,
        },
        {
          // label: 'My Second dataset',
          backgroundColor: documentStyle.getPropertyValue("--grey"),
          borderColor: documentStyle.getPropertyValue("--grey"),
          data: secondDataSet,
        },
      ],
    };
    const options = {
      indexAxis: "y",
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      maxBarThickness: 16,
      plugins: {
        legend: {
          display: false,
          labels: {
            fontColor: textColor,
          },
        },
      },

      scales: {
        x: {
          stacked: true,
          display: false,
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  };

  const handleSelection = (value) => {
    setSelectedyearlyData(value);

    const previousYear = new Date(
      new Date().setFullYear(new Date().getFullYear() - 1)
    ).getFullYear();
    
    if (value.code === "TY") {
      dispatch(getAverageReviewScore());
    } else {
      dispatch(
        getAverageReviewScore({
          year: previousYear,
        })
      );
    }
  };

  return (
    <div className="avg_score card p-3 border-0 shadow-sm rounded-17 h-100">
      <div className="mb-2 d-flex align-items-center justify-content-between">
        <h5 className=" exDemiBold fs-22 mb-0">Average Review Score</h5>
        <div className="dropdown">
          <div>
            <Dropdown
              value={selectedyearlyData}
              onChange={(e) => handleSelection(e.value)}
              options={weeklyData}
              optionLabel="name"
              placeholder="Select Year"
              className="w-full md:w-14rem rounded-pill border ps-2"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 m-auto text-center">
          <div className="rating_star">
            <h5 className="display-4 exBold">
              {avgReviewScore?.totalPrecentage}
            </h5>
            <InputIcon className="pi pi-star-fill" />
            <InputIcon className="pi pi-star-fill" />
            <InputIcon className="pi pi-star-fill" />
            <InputIcon className="pi pi-star-fill" />
            <InputIcon className="pi pi-star" />
            <p className="mt-2">
              {avgReviewScore?.totalStarLikePoint}
              <span className="ms-2">Total Reviews</span>{" "}
            </p>
          </div>
        </div>

        <div className="col-md-8">
          <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
}
