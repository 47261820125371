import React, { createContext, useContext, useRef, useState } from 'react';
import { useManualUpdateListing } from "../hooks/mutations/properties";

const SyncContext = createContext();

export const SyncProvider = ({ children }) => {
  const [progressVisible, setProgressVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const timerRef = useRef(null);

  const manualUpdateListingMutation = useManualUpdateListing(setProgress, setProgressVisible, timerRef);

  const syncProperty = (recordIds) => {
    if (timerRef.current) clearInterval(timerRef.current);

    setProgressVisible(true);

    timerRef.current = setInterval(() => {
      setProgress((prevProgress) => Math.min(prevProgress + Math.floor(Math.random() * 11) + 5, 99));
    }, 1500);

    manualUpdateListingMutation.mutate(recordIds);
  };

  const syncDisabled = timerRef.current !== null;

  return (
    <SyncContext.Provider value={{ progress, progressVisible, syncDisabled, syncProperty }}>
      {children}
    </SyncContext.Provider>
  );
};

export const useSync = () => useContext(SyncContext);