import { Form, Formik } from "formik";
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";
import React from "react";
import * as Yup from 'yup';
import { useSavePropertySettings } from "../../hooks/mutations/properties";
import { usePropertySettings } from "../../hooks/queries/properties";
import SelectButtonSetting from "../PropertyListing/select-button-setting";
import SelectSetting from "../PropertyListing/select-setting";

function SettingDialog({ id, visible, setVisible }) {

  const getPropertySettings = usePropertySettings(id);
  const propertySetting = getPropertySettings?.data?.result;

  const propertySettingsMutation = useSavePropertySettings();

  const settingHeader = (
    <>
      <h4 className="color-black exMedium mb-3">Listing Content Settings</h4>
    </>
  );
  return (
    <>
      <Dialog
        className="p-4 background-white rounded-17 mx-3 listing-setting"
        header={settingHeader}
        visible={visible}
        style={{ maxWidth: "550px", width: "100%" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
        dismissableMask
      >
        <div>
          <div className="border-bottom modal-subadding">
            <label htmlFor="switch1" className="contentTxt">Content Overides</label>
            <p className="subaddingPTxt">The below toggle buttons allow you to turn off AutoRanks automated text updates for specific content areas.</p>
          </div>
          <Formik
            initialValues={{
              isTitle: propertySetting?.isTitle,
              isSummary: propertySetting?.isSummary,
              isSpace: propertySetting?.isSpace,
              format: propertySetting?.format,
              bulletOptions: propertySetting?.bulletOptions,
            }}
            validationSchema={Yup.object({
              isTitle: Yup.boolean().required(),
              isSummary: Yup.boolean().required(),
              isSpace: Yup.boolean().required(),
              format: Yup.number().required(),
              bulletOptions: Yup.string().required(),
            })}
            onSubmit={async (values) => {
              await propertySettingsMutation.mutate({
                recordId: propertySetting?.recordId,
                ...values,
              });
              setVisible(false);
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <SelectButtonSetting
                  label="Listing Title Automation"
                  value={values.isTitle}
                  name="isTitle"
                  handleChange={handleChange}
                />
                <SelectButtonSetting
                  label="Listing Summary Automation"
                  value={values.isSummary}
                  name="isSummary"
                  handleChange={handleChange}
                />
                <SelectButtonSetting
                  label="Listing Space Description"
                  value={values.isSpace}
                  name="isSpace"
                  handleChange={handleChange}
                />
                <div className="border-bottom">
                  <label htmlFor="switch1" className="contentTxt mt-4">Listing Style & Formatting </label>
                  <p className="subaddingPTxt">The below options allow you to customize the style and formatting of your listing content.</p>
                </div>
                <SelectSetting
                  label="Listing Space Title Format"
                  value={values.format}
                  name="format"
                  options={[{ label: "Standard", value: 1 }, { label: "Customize", value: 2 }]}
                  handleChange={handleChange}
                />
                <SelectSetting
                  label="Listing Space Format"
                  value={values.bulletOptions.toString()}
                  name="bulletOptions"
                  options={[{ label: "Bullet", value: "true" }, { label: "Paragraph", value: "false" }]}
                  handleChange={handleChange}
                />
                <Button
                  type="submit"
                  className="btn button-purple mt-3 float-end z-3"
                  disabled={propertySettingsMutation.isPending}>
                  {propertySettingsMutation.isPending ? (
                    <span
                      className="spinner-border spinner-border-sm text-dark"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    </>
  );
}
export default SettingDialog;
