import { store } from "../../../Redux/Store";
import axiosInstance from "../axiosInstance";

export const forgotPassword = async (data) => {
  const url = "/auth/forgetPassword";
  return axiosInstance.post(url, data);
}

export const resetPassword = async (data) => {
  const url = "/auth/resetPassword";
  const state = store.getState();
  const email = state.auth.email
  const formData = {
    email: email,
    password: data.newPassword,
    otp: data.otp,
  };
  return axiosInstance.post(url, formData);
}

export const registerOtp = async (data) => {
  const url = "/auth/otp";
  return axiosInstance.post(url, data);
}

export const updateProfile = async (data) => {
  const url = "/user/updateUserProfile";
  return axiosInstance.post(url, data);
}

export const changePassword = async (data) => {
  const url = "/auth/changePassword";
  return axiosInstance.post(url, data);
}

export const register = async (data) => {
  const url = "/auth/register";
  return axiosInstance.post(url, data);
}

export const logout = async () => {
  const url = "/auth/logout";
  return axiosInstance.post(url);
}

export const login = async (data) => {
  const url = "/auth/login";
  return axiosInstance.post(url, data);
}
