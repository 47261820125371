import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import TextError from "../../../Components/TextError/index";
import { EditUserSchema } from "../../../Utils/Validation/Validation";
// import { arrayBufferToBase64 } from "../../../Utils/constant/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateProfile } from "../../../hooks/mutations/auth";
import { useUserData } from "../../../hooks/queries/auth";
import { setUser } from "../../../Redux/Features/authReducer/authSlice";

function AccountSetting() {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [dateFormat, setDateFormat] = useState('');

  const getUserData = useUserData();
  const userData = getUserData?.data?.result;

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setDateFormat(user?.dateFormat)
  }, []);

  const initialValues = {
    fname: userData?.firstName || "",
    email: userData?.email,
    phoneNumber: userData?.phoneNumber || "",
    dateFormat: dateFormat ? dateFormat : null,
  };

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateProfileMutation = useUpdateProfile();

  const onSubmit = (values) => {
    updateProfileMutation.mutate(values);
    dispatch(setUser({
      ...user,
      dateFormat: values.dateFormat
    }));
  };

  const handlePhoneNumberChange = (e, setFieldValue) => {
    // Only allow digits
    const value = e.target.value.replace(/[^0-9\+\-]/g, "");
    // Set the value back to the field
    e.target.value = value;
    // Update Formik's value
    setFieldValue("phoneNumber", value);
  };

  return (
    <div
      className="tab-pane fade show active"
      id="account"
      role="tabpanel"
      aria-labelledby="account-tab"
      hostaway
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={EditUserSchema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="d-flex align-items-center justify-content-between py-3 border-bottom flex-wrap">
              <div>
                <h4 className="fs-22 exMedium">Account</h4>
                <p className="fs-14 text-navy-light exLight mb-2 mb-md-0">
                  View and update your account details.
                </p>
              </div>
              <button
                type="submit"
                className="btn btn-lg button-navy rounded-pill"
              >
                Save Changes
              </button>
            </div>
            {/* <div className="row py-3 border-bottom mx-1">
              <div className="col-md-6 px-0">
                <label className="exMedium mb-2" for="proPic">
                  Profile Picture
                </label>
              </div>
              <div className="col-md-6 px-0">
                <div className="upload_field input-group  d-flex align-items-center ">
                  {!selectedImage && (
                    <label
                      className="input-group-text background-white border-0 position-relative"
                      for="inputGroupFile01"
                    >
                      <img
                        src={
                          (userData?.result?.userImage?.data &&
                            arrayBufferToBase64(
                              userData?.result?.userImage?.data
                            )) ||
                          process.env.PUBLIC_URL +
                          "/Assets/images/user_placeholder.png"
                        }
                        className="profile-img border border-3 border-light shadow-sm "
                        alt=""
                      />
                      <div className="add-profile position-absolute">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <mask
                            id="mask0_482_1707"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="20"
                            height="20"
                          >
                            <rect width="20" height="20" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_482_1707)">
                            <path
                              d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z"
                              fill="black"
                            />
                          </g>
                        </svg>
                      </div>
                    </label>
                  )}
                  {selectedImage && (
                    <label
                      className="input-group-text background-white border-0 position-relative"
                      for="inputGroupFile01"
                    >
                      <img
                        src={selectedImage}
                        className="profile-img border border-3 border-light shadow-sm "
                        alt=""
                      />
                      <div className="add-profile position-absolute">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <mask
                            id="mask0_482_1707"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="20"
                            height="20"
                          >
                            <rect width="20" height="20" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_482_1707)">
                            <path
                              d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z"
                              fill="black"
                            />
                          </g>
                        </svg>
                      </div>
                    </label>
                  )}
                  <p className="mb-0 color-muted ms-4">JPG, or PNG. 1MB Max.</p>
                  <input
                    type="file"
                    className="form-control d-none"
                    id="inputGroupFile01"
                    name="image"
                    onChange={(event) => {
                      setFieldValue("image", event.currentTarget.files[0]);
                      handleImageChange(event);
                    }}
                  />
                </div>
                <ErrorMessage name="image" component={TextError} />
              </div>
            </div> */}
            <div className="row py-3 border-bottom mx-1">
              <div className="col-md-6 px-0">
                <label
                  className="exMedium mb-2"
                  for="compName"
                  aria-required="required"
                >
                  Name
                </label>
              </div>
              <div className="col-md-6 px-0">
                <div className="input-group input-48 border rounded-pill mb-1">
                  <span
                    className="input-group-text bg-transparent  border-0 ps-3 rounded-pill  "
                    id="basic-addon1"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_449_2711"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="-1"
                        width="24"
                        height="25"
                      >
                        <rect
                          y="-0.000976562"
                          width="24"
                          height="24"
                          fill="#212529"
                        />
                      </mask>
                      <g mask="url(#mask0_449_2711)"></g>
                      <path
                        d="M19.5 20.75V7H15.75V3.25H4.5V20.75H2V22H10.75V18.25H13.25V22H22V20.75H19.5ZM9.5 15.75H7V13.25H9.5V15.75ZM9.5 12H7V9.5H9.5V12ZM9.5 8.25H7V5.75H9.5V8.25ZM13.25 15.75H10.75V13.25H13.25V15.75ZM13.25 12H10.75V9.5H13.25V12ZM13.25 8.25H10.75V5.75H13.25V8.25ZM18.25 15.75H15.75V13.25H18.25V15.75ZM18.25 12H15.75V9.5H18.25V12Z"
                        fill="#8D909D"
                      />
                    </svg>
                  </span>
                  <Field
                    className=" form-control border-0 fs-6 ps-0  rounded-pill-end color-dark"
                    type="text"
                    id="compName"
                    name="fname"
                    placeholder="First Name"
                    maxLength={20}
                  />
                </div>
                <ErrorMessage name="fname" component={TextError} />
              </div>
            </div>
            <div className="row py-3 border-bottom mx-1">
              <div className="col-md-6 px-0">
                <label
                  className="exMedium mb-2"
                  for="compEmail"
                  aria-required="required"
                >
                  E-mail Address
                </label>
              </div>
              <div className="col-md-6 px-0">
                <div className="input-group input-48 border rounded-pill mb-1">
                  <span
                    className="input-group-text bg-transparent  border-0 ps-3 rounded-pill  "
                    id="basic-addon1"
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.5 5H3.5C3.30109 5 3.11032 5.07902 2.96967 5.21967C2.82902 5.36032 2.75 5.55109 2.75 5.75V18.5C2.75 18.8978 2.90804 19.2794 3.18934 19.5607C3.47064 19.842 3.85218 20 4.25 20H20.75C21.1478 20 21.5294 19.842 21.8107 19.5607C22.092 19.2794 22.25 18.8978 22.25 18.5V5.75C22.25 5.55109 22.171 5.36032 22.0303 5.21967C21.8897 5.07902 21.6989 5 21.5 5ZM9.75312 12.5L4.25 17.5438V7.45625L9.75312 12.5ZM10.8594 13.5219L11.9937 14.5531C12.132 14.6796 12.3126 14.7498 12.5 14.7498C12.6874 14.7498 12.868 14.6796 13.0062 14.5531L14.1406 13.5219L19.5687 18.5H5.43125L10.8594 13.5219ZM15.2469 12.5L20.75 7.45625V17.5438L15.2469 12.5Z"
                        fill="#212529"
                        fillOpacity="0.5"
                      />
                    </svg>
                  </span>

                  <Field
                    style={{ cursor: "not-allowed", backgroundColor: "#fff" }}
                    className=" form-control  border-0 fs-6 ps-0  rounded-pill-end   color-dark"
                    type="email"
                    id="compEmail"
                    name="email"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row py-3 border-bottom mx-1">
              <div className="col-md-6 px-0">
                <label
                  className="exMedium mb-2"
                  for="compPhone"
                  aria-required="required"
                >
                  Phone Number
                </label>
              </div>
              <div className="col-md-6 px-0">
                <div className="input-group input-48 border rounded-pill mb-1">
                  <span
                    className="input-group-text bg-transparent  border-0 ps-3 rounded-pill  "
                    id="basic-addon1"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_319_15433"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      >
                        <rect width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_319_15433)">
                        <path
                          d="M19.95 21C17.8667 21 15.8083 20.5458 13.775 19.6375C11.7417 18.7292 9.89167 17.4417 8.225 15.775C6.55833 14.1083 5.27083 12.2583 4.3625 10.225C3.45417 8.19167 3 6.13333 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.07917 8.725 3.2375C8.90833 3.39583 9.01667 3.58333 9.05 3.8L9.7 7.3C9.73333 7.56667 9.725 7.79167 9.675 7.975C9.625 8.15833 9.53333 8.31667 9.4 8.45L6.975 10.9C7.30833 11.5167 7.70417 12.1125 8.1625 12.6875C8.62083 13.2625 9.125 13.8167 9.675 14.35C10.1917 14.8667 10.7333 15.3458 11.3 15.7875C11.8667 16.2292 12.4667 16.6333 13.1 17L15.45 14.65C15.6 14.5 15.7958 14.3875 16.0375 14.3125C16.2792 14.2375 16.5167 14.2167 16.75 14.25L20.2 14.95C20.4333 15.0167 20.625 15.1375 20.775 15.3125C20.925 15.4875 21 15.6833 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21Z"
                          fill="#8D909D"
                        />
                      </g>
                    </svg>
                  </span>
                  <Field
                    className=" form-control  border-0 fs-6 ps-0  rounded-pill-end   color-dark"
                    type="text"
                    id="compPhone"
                    name="phoneNumber"
                    maxLength={14}
                    placeholder="Phone Number"
                    onChange={(e) =>
                      handlePhoneNumberChange(e, setFieldValue)
                    }
                  />
                </div>
                <ErrorMessage name="phoneNumber" component={TextError} />
              </div>
            </div>
            <div className="row py-3 border-bottom mx-1">
              <div className="col-md-6 px-0">
                <label
                  className="exMedium mb-2"
                  for="compPhone"
                  aria-required="required"
                >
                  Date Format
                </label>

              </div>
              <div className="col-md-6 px-0">
                <div className="input-group input-48 border rounded-pill mb-1">
                  <span
                    className="input-group-text bg-transparent  border-0 ps-3 rounded-pill  "
                    id="basic-addon1"
                  >
                    <svg id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 122.88 122.89"><path d="M81.61,4.73C81.61,2.12,84.19,0,87.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM66.11,105.66c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1ZM15.85,68.94c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14-10.1H107c.8,0,.8,10.1,0,10.1H91.25c-.8,0-.8-10.1,0-10.1ZM15.85,87.3c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1ZM41,87.3c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14,0c-.8,0-.8-10.1,0-10.1H107c.8,0,.8,10.1,0,10.1Zm-75.4,18.36c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1ZM29.61,4.73C29.61,2.12,32.19,0,35.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM6.4,43.47H116.47v-22a3,3,0,0,0-.86-2.07,2.92,2.92,0,0,0-2.07-.86H103a3.2,3.2,0,0,1,0-6.4h10.55a9.36,9.36,0,0,1,9.33,9.33v92.09a9.36,9.36,0,0,1-9.33,9.33H9.33A9.36,9.36,0,0,1,0,113.55V21.47a9.36,9.36,0,0,1,9.33-9.33H20.6a3.2,3.2,0,1,1,0,6.4H9.33a3,3,0,0,0-2.07.86,2.92,2.92,0,0,0-.86,2.07v22Zm110.08,6.41H6.4v63.67a3,3,0,0,0,.86,2.07,2.92,2.92,0,0,0,2.07.86H113.55a3,3,0,0,0,2.07-.86,2.92,2.92,0,0,0,.86-2.07V49.88ZM50.43,18.54a3.2,3.2,0,0,1,0-6.4H71.92a3.2,3.2,0,1,1,0,6.4Z" /></svg>

                  </span>
                  <Field
                    as="select"
                    name="dateFormat"
                    className="form-select rounded-pill ms-1"
                    aria-label="Default select example"
                    style={{ border: "none", outline: "none", boxShadow: "none" }}
                  >
                    (
                    <option value="MM-DD-YYYY" >
                      mm-dd-yyyy
                    </option>
                    <option value="DD-MM-YYYY"  >
                      dd-mm-yyyy
                    </option>
                    )
                  </Field>
                </div>
                <ErrorMessage name="dateFormat" component={TextError} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default AccountSetting;
