import { useMutation, useQueryClient } from "@tanstack/react-query";
import { impersonateUser, inactivateUser, increaseUserLimit, stopImpersonateUser } from "../../services/api/users/mutations";
import { errorToast, successSwal, successToast } from "../../Utils/constant/commonFunction";
import { useDispatch } from "react-redux";
import { setImpersonateUser, setUser } from "../../Redux/Features/authReducer/authSlice";

// TODO: make the impersonate update ui

export const useImpersonateUser = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (rowData) => impersonateUser({ impersonateUserId: rowData?.id }),
    onSuccess: async (_, rowData) => {
      queryClient.invalidateQueries("checkConnection")
      queryClient.invalidateQueries("listings");
      queryClient.invalidateQueries("userData");
      dispatch(setImpersonateUser(rowData?.email));
      await successSwal(`Impersonating ${rowData?.email}`);
      // dispatch(setUser(localStorage.getItem("ImpersonateUser")));
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    }
  });
};

export const useStopImpersonate = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: () => stopImpersonateUser(),
    onSuccess: async () => {
      queryClient.invalidateQueries("checkConnection")
      queryClient.invalidateQueries("listings");
      queryClient.invalidateQueries("userData");
      dispatch(setImpersonateUser(null));
      await successSwal("Admin access removed");
      // setUser(null);
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    }
  });
}

export const useInactivateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (rowData) => inactivateUser({
      userId: rowData.id,
      userEmail: rowData.email,
      userStatus: rowData?.status === "active" ? "inactive" : "active",
    }),
    onSuccess: (response) => {
      queryClient.invalidateQueries("users");
      successToast(response?.message);
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    }
  });
}

export const useIncreaseUserLimit = (handleIncrease, propertyLimit, setShowIncreaseModal) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => increaseUserLimit({ userId: handleIncrease?.id, propertyLimit: propertyLimit }),
    onSuccess: (response) => {
      queryClient.invalidateQueries("users");
      successToast(response?.message);
      setShowIncreaseModal(false);
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
      setShowIncreaseModal(false);
    }
  });
}