import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setGuestyModalVisible, setHostawayModalVisible } from "../../Redux/Features/stateReducer/stateSlice";
import { generateGuestyToken, generateHostawayToken } from "../../services/api/tokens/mutations";
import { errorToast, successToast } from "../../Utils/constant/commonFunction";

export const useGenerateGuestyToken = (setLoading) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (data) => generateGuestyToken(data),
    onSuccess: () => {
      queryClient.invalidateQueries("checkConnection");
      dispatch(setGuestyModalVisible(false));
      successToast("Connection established successfully");
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    },
    onSettled: () => {
      setLoading(false);
    }
  });
};

export const useGenerateHostawayToken = (setLoading) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (data) => generateHostawayToken(data),
    onSuccess: () => {
      queryClient.invalidateQueries("checkConnection");
      dispatch(setHostawayModalVisible(false));
      successToast("Connection established successfully");
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    },
    onSettled: () => {
      setLoading(false);
    }
  });
};