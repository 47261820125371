import axiosInstance from "../axiosInstance";

export const fetchConnections = async () => {
  const url = "/connection/health";
  return axiosInstance.get(url);
}

export const fetchUserData = async () => {
  const url = "/user/getUserDataOnId";
  return axiosInstance.get(url);
}

export const fetchGoogleLogin = async () => {
  const url = "/auth/googleLogin";
  return axiosInstance.get(url);
}