import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { arrayBufferToBase64, formatedDateTime } from "../../Utils/constant/commonFunction";
import { useInactivateNotifications } from "../../hooks/mutations/notification";

function AllNotification(props) {
  const navigate = useNavigate();
  const { data, handleMarkAllAsRead } = props;
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  //Mark as read function call
  const markAllAsRead = () => {
    handleMarkAllAsRead(data.map((item) => item.id));
  };

  const deleteNotificationMutation = useInactivateNotifications();

  //Delete Notification List
  const deleteAllNotification = (id) => {
    deleteNotificationMutation.mutate({ id });

  }

  const renderHeader = () => {
    return (
      <div className="d-flex align-items-center justify-content-between w-100">
        <h5 className="exMedium fs-22 color-black">Most Recent</h5>
        {/* <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField> */}
      </div>
    );
  };

  const renderFooter = (data) => {
    return (
      <button onClick={markAllAsRead} className="btn text-purple btn-purple-outline">
        Mark all as read
      </button>
    );
  };

  const rowBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="col-12 allread cstm-drk-bg">
          <div className={`${rowData?.isSeen === true ? 'card mb-2 border-0 border-bottom rounded-0 bg-notification ' : 'card mb-2 border-0 border-bottom rounded-0 cstm-unread'}`}
            onClick={() => navigate(`/propertydetails/${rowData?.recordId}`)}
          >
            <div className="card-body d-flex align-items-center ">
              <img className="notifyImage"
                src={
                  rowData?.webNotificationThamnail
                    ? rowData?.webNotificationThamnail
                    :
                    arrayBufferToBase64(rowData?.webUserThamnail?.data)
                }
                onError={(e) => {
                  e.target.src = process.env.PUBLIC_URL + "/Assets/images/img_placeholder.svg"; // replace with a default image
                }}

                alt=""
              />
              <div className="w-100">
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <p className="card-text color-muted fs-14 mb-0 ms-2">
                    {rowData.message}
                  </p>
                  <p className="card-text mb-0 text-end">
                    <small className="color-muted">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/Assets/images/icons/clock.svg"
                        }
                        alt=""
                        className="clock-icon me-2"
                      />
                      {formatedDateTime(rowData.updatedAt)}
                    </small>
                  </p>
                </div>
              </div>
              <button
                type="button"
                className="btn-close position-absolute position-absolute top-0 end-0 m-3"
                aria-label="Close"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteAllNotification(rowData?.id);
                }}
              ></button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const header = renderHeader();
  const footer = renderFooter();

  return (
    <DataTable
      value={data}
      dataKey="id"
      tableStyle={{ minWidth: "50rem" }}
      responsiveLayout="scroll"
      size="small"
      showGridlines
      removableSort
      paginator
      rows={5}
      paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
      currentPageReportTemplate="{first} to {last} of {totalRecords}"
      filters={filters}
      filterDisplay="row"
      globalFilterFields={["notificationMessage", "updatedAt"]}
      header={header}
      footer={footer}
      emptyMessage="No Notification found."
    >
      <Column field="notificationMessage" body={rowBodyTemplate}></Column>
    </DataTable>
  );
}

export default AllNotification;
