import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { COLORS } from "../../../Config/Config";
function LineChart(props) {
  const { totalRevenuMonthAndYearWise } = props;
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (totalRevenuMonthAndYearWise?.revenueDataMonthAndYear) {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--text-color-secondary"
      );
      const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

      const dataSet = [];
      let colorIndex = 0;
      for (const [key, value] of Object?.entries(
        totalRevenuMonthAndYearWise?.revenueDataMonthAndYear
      )) {
        let temp = [];
        for (const { month, totalPrice } of value) {
          switch (month) {
            case "January":
              temp[0] = totalPrice;
              break;
            case "February":
              temp[1] = totalPrice;
              break;
            case "March":
              temp[2] = totalPrice;
              break;
            case "April":
              temp[3] = totalPrice;
              break;
            case "May":
              temp[4] = totalPrice;
              break;
            case "June":
              temp[5] = totalPrice;
              break;
            case "July":
              temp[6] = totalPrice;
              break;
            case "August":
              temp[7] = totalPrice;
              break;
            case "September":
              temp[8] = totalPrice;
              break;
            case "October":
              temp[9] = totalPrice;
              break;
            case "November":
              temp[10] = totalPrice;
              break;
            case "December":
              temp[11] = totalPrice;
              break;
            default:
          }
        }
        dataSet.push({
          label: key,
          data: temp,
          fill: false,
          borderColor: documentStyle.getPropertyValue(
            `--${COLORS[colorIndex]}-500`
          ),
          tension: 0.4,
        });
        colorIndex++;
      }

      const data = {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: dataSet,
      };
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              display: false,
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
      };
      setChartData(data);
      setChartOptions(options);
    }
  }, [totalRevenuMonthAndYearWise]);

  return (
    <div className="card p-3 border-0 shadow-sm rounded-17 h-100">
      <h5 className="mb-2 exDemiBold fs-22 ">Top Revenue</h5>
      <Chart type="line" data={chartData} options={chartOptions} />
    </div>
  );
}
export default LineChart;
