const CarouselItem = ({ image, quote, author, company, isActive = false }) => {
  return (
    <div className={`carousel-item ${isActive && 'active'}`}>
      <div className="card border-purple cardimage">
        <img
          src={process.env.PUBLIC_URL + image}
          className="card-img-top"
          alt="..."
        />
        <div className="cardparagraph">
          <p className="card-text fs-md-4 fst-italic fw-normal">
            {quote}
          </p>
          <h5 className="card-title fs-md-4 fw-bold text-end">
            {author}
          </h5>
          <h6 className="text-end fw-light fst-italic">
            {company}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
