import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { successToast, errorToast } from "../../../Utils/constant/commonFunction";
import dashboardService from "./dashboardService"

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    automatedListingData: "",
    reviewScoreData: "",
    bookingPaceMom: "",
    totalRevenueforMonth: "",
    bookingPaceMomAirbnb: "",
    topBookingSites: "",
    totalRevenuMonthAndYearWise: "",
    avgReviewScore: "",
    automatedAndUnautomatedListing:"",
    insertUserSubscriptionTypeData:""
}

/* Fetch automated listing data Details   */
export const automatedListingDataApi = createAsyncThunk(
    "automatedListingData",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.automatedListingDataApi();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/* Fetch review score data Details   */
export const reviewScoreDataApi = createAsyncThunk(
    "reviewScoreData",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.reviewScoreDataApi();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/* Fetch booking pace momentum data Details   */
export const bookingPaceMomentumDataApi = createAsyncThunk(
    "bookingPaceMomentum",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.bookingPaceMomentumDataApi();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/* Fetch total revenu for month  */
export const totalRevenueforMonthDataApi = createAsyncThunk(
    "totalRevenueForMonth",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.totalRevenueforMonthDataApi();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/* Fetch booking pace momentum data Details for airbnb  */
export const bookingPaceMomentumAirbnbDataApi = createAsyncThunk(
    "bookingPaceMomentumAirbnbWeekly",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.bookingPaceMomentumAirbnbDataApi();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/* Fetch top booking sites data */
export const topBookingSitesDataApi = createAsyncThunk(
    "topBookingSites",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.topBookingSitesDataApi();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)
/* Fetch total revenue data based on the month and year */
export const totalRevenueMonthAndYearDataApi = createAsyncThunk(
    "totalRevenuMonthAndYearWise",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.totalRevenueMonthAndYearDataApi();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)
/* Fetch average review score */
export const getAverageReviewScore = createAsyncThunk(
    "getStarData",
    async (data, thunkAPI) => {
        try {
            return await dashboardService.getAverageReviewScore(data);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/* Fetch automated and unautomated listing data */
export const automatedAndUnautomatedListingData = createAsyncThunk(
    "getAutomatedAndUnautomatedData",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.automatedAndUnautomatedListingData();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Fetch Insert User Subscription Type
export const insertUserSubscriptionType = createAsyncThunk(
    "insertUserSubscriptionType",
    async (data, thunkAPI) => {
        try {
            return await dashboardService.insertUserSubscriptionType(data);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString(); errorToast(message);
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/*  For Slice */
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        reset: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isLoading = false;
            state.message = "";
        },
    },
    extraReducers: (builder) => {
        builder
            // Automated listing data
            .addCase(automatedListingDataApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(automatedListingDataApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.automatedListingData = payload;

            })
            .addCase(automatedListingDataApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;

            })

            // Review score data
            .addCase(reviewScoreDataApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(reviewScoreDataApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.reviewScoreData = payload;

            })
            .addCase(reviewScoreDataApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;

            })

            // booking pace momentum data 
            .addCase(bookingPaceMomentumDataApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(bookingPaceMomentumDataApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.bookingPaceMom = payload;

            })
            .addCase(bookingPaceMomentumDataApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;

            })

            // total revenue for month
            .addCase(totalRevenueforMonthDataApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(totalRevenueforMonthDataApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.totalRevenueforMonth = payload;

            })
            .addCase(totalRevenueforMonthDataApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;

            })

            // Weekly booking pace momentum data for airbnb
            .addCase(bookingPaceMomentumAirbnbDataApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(bookingPaceMomentumAirbnbDataApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.bookingPaceMomAirbnb = payload;

            })
            .addCase(bookingPaceMomentumAirbnbDataApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;

            })

            // Top booking sites data
            .addCase(topBookingSitesDataApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(topBookingSitesDataApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.topBookingSites = payload;

            })
            .addCase(topBookingSitesDataApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;
            })

            // Total revenue month and year wise data
            .addCase(totalRevenueMonthAndYearDataApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(totalRevenueMonthAndYearDataApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.totalRevenuMonthAndYearWise = payload;

            })
            .addCase(totalRevenueMonthAndYearDataApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;

            })

            // Average review score
            .addCase(getAverageReviewScore.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAverageReviewScore.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.avgReviewScore = payload;

            })
            .addCase(getAverageReviewScore.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;
            })

            // automated and unautomated listing data
            .addCase(automatedAndUnautomatedListingData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(automatedAndUnautomatedListingData.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.automatedAndUnautomatedListing = payload;

            })
            .addCase(automatedAndUnautomatedListingData.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;
            })

                 // Insert User Subscription Type
                 .addCase(insertUserSubscriptionType.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(insertUserSubscriptionType.fulfilled, (state, { payload }) => {
                    state.isError = false;
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.message = payload.message;
                    successToast(payload.message);
                    state.insertUserSubscriptionTypeData = payload;
    
                })
                .addCase(insertUserSubscriptionType.rejected, (state, { payload }) => {
                    state.isError = true;
                    state.isLoading = false;
                    state.isSuccess = false;
                    state.message = payload.message;
                })
    },
});

export const { reset } = dashboardSlice.actions;
export default dashboardSlice.reducer;