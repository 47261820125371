import React, { useState } from 'react';
import SettingDialog from '../../Dialogs/settings';
import ActionItem from './action-item';

const ListingSettings = ({ id }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <ActionItem
        label="Settings"
        icon="pi pi-cog"
        onClick={() => setVisible(true)}
        background="settings-btn"
        type="text"
      />

      <SettingDialog
        id={id}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  );
};

export default ListingSettings;