import axiosInstance from "../axiosInstance";

export const postListings = async () => {
  const url = "/listing";
  return axiosInstance.post(url);
};

export const updateListings = async (data) => {
  const url = "/listing/activate";
  return axiosInstance.post(url, data);
}