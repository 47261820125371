import { useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../services/api/axiosInstance";
import { fetchDashboardAccomplishments, fetchDashboardData, fetchDashboardStats, fetchDashboardTop } from "../../services/api/userDashboard/queries";


// TODO: maybe should become post and mutation
export const useDashboardRefresh = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['dashboardRefresh'],
    queryFn: async () => {
      return await axiosInstance.get('/userDashBoard/refresh');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('dashboardData');
      queryClient.invalidateQueries('dashboardStats');
      queryClient.invalidateQueries('dashboardAccomplishments');
      queryClient.invalidateQueries('dashboardTop');
      queryClient.invalidateQueries('propertyStats');
    }
  });
};

export const useDashboardData = () => {
  return useQuery({
    queryKey: ['dashboardData'],
    queryFn: fetchDashboardData
  });
}

export const useDashboardStats = () => {
  return useQuery({
    queryKey: ['dashboardStats'],
    queryFn: fetchDashboardStats
  });
}

export const useDashboardAccomplishments = () => {
  return useQuery({
    queryKey: ['dashboardAccomplishments'],
    queryFn: fetchDashboardAccomplishments
  });
}

export const useDashboardTop = () => {
  return useQuery({
    queryKey: ['dashboardTop'],
    queryFn: fetchDashboardTop
  });
}