import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

const persistConfig = {
  key: 'addListings',
  storage,
}

export const addListingsSlice = createSlice({
  name: 'addListings',
  initialState: {
    visible: false,
    selectedListings: [],
  },
  reducers: {
    setVisible: (state, action) => {
      state.visible = action.payload;
    },
    setSelectedListings: (state, action) => {
      state.selectedListings = action.payload;
    },
  },
})

const persistedReducer = persistReducer(persistConfig, addListingsSlice.reducer)

export const { setVisible, setSelectedListings } = addListingsSlice.actions;

export default persistedReducer
