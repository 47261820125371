import axios from "axios";
import {
    BASE_URL,
    CREATECOUPON,
    GETCOUPON,
    COUPON_PAYMENT_STATUS
} from "../../../Config/Config";
import { getToken } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";

// Create coupon API
const createCouponApi = async (userData) => {
    const response = await axios.post(
        BASE_URL + CREATECOUPON,
        userData,
        HeaderToken(getToken())
    );
    return response.data;
};

// Get all coupon API
const getCouponApi = async () => {
    const response = await axios.get(
        BASE_URL + GETCOUPON,
        HeaderToken(getToken())
    );
    return response.data;
};

// check User Have Active Transaction On Behalf Of Property
const checkUserHaveActiveTransaction = async (userData) => {
    const response = await axios.post(
        BASE_URL + COUPON_PAYMENT_STATUS,
        userData,
        HeaderToken(getToken())
    );
    return response.data;
};

const couponService = {
    createCouponApi,
    getCouponApi,
    checkUserHaveActiveTransaction

};

export default couponService;