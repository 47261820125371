import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routes/Routing";
import { SyncProvider } from "./contexts/sync-context";

function App() {

  // for removing all the console
  // console.log = function() {};

  return (
    <SyncProvider>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </SyncProvider>
  );
}
export default App;
