import React from 'react';
import { useResetProperty } from '../../../hooks/mutations/properties';
import PropertyResetConfirm from '../../Dialogs/reset';
import ActionItem from './action-item';

const ResetListing = ({ primaryIds }) => {
  const resetPropertyMutation = useResetProperty(primaryIds);

  return (
    <ActionItem
      label="Reset"
      icon="pi pi-undo"
      onClick={() => PropertyResetConfirm({ onConfirm: () => resetPropertyMutation.mutate({ primaryIds }) })}
      disabled={resetPropertyMutation.isPending}
      background="mint-31"
    />
  );
};

export default ResetListing;
