import { ErrorMessage, Field, Form, Formik } from "formik";
import { SignUpSchema } from "../../Utils/Validation/Validation";
import TextError from "../TextError";
import PasswordField from "../ui/password-field";

const RegistrationForm = ({ onSubmit, isLoading }) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    termsCond: false,
  };

  const handlePhoneNumberChange = (e, setFieldValue) => {
    const value = e.target.value.replace(/[^0-9\+\-]/g, "");
    e.target.value = value;
    setFieldValue("phoneNumber", value);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignUpSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleChange, handleBlur }) => (
        <Form>
          <div className="mb-1">
            <div className="input-group">
              <span className="input-group-text bg-transparent border-end-0 input-border">
                <img src={process.env.PUBLIC_URL + "/Assets/images/icons/person.svg"} alt="" />
              </span>
              <Field
                type="text"
                className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                name="firstName"
                placeholder="First Name"
                maxLength={20}
              />
            </div>
          </div>
          <ErrorMessage name="firstName" component={TextError} />

          <div className="mb-1 mt-3">
            <div className="input-group">
              <span className="input-group-text bg-transparent border-end-0 input-border">
                <img src={process.env.PUBLIC_URL + "/Assets/images/icons/person.svg"} alt="" />
              </span>
              <Field
                type="text"
                className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                name="lastName"
                placeholder="Last Name"
                maxLength={20}
              />
            </div>
          </div>
          <ErrorMessage name="lastName" component={TextError} />

          <div className="mb-1 mt-3">
            <div className="input-group">
              <span className="input-group-text bg-transparent border-end-0 input-border">
                <img src={process.env.PUBLIC_URL + "/Assets/images/icons/email.svg"} alt="" />
              </span>
              <Field
                type="email"
                className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                name="email"
                autoComplete="off"
                placeholder="E-mail address"
              />
            </div>
          </div>
          <ErrorMessage name="email" component={TextError} />

          <div className="mt-3 mb-1">
            <PasswordField
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <ErrorMessage name="password" component={TextError} />

          <div className="mb-1 mt-3">
            <div className="input-group">
              <span className="input-group-text bg-transparent border-end-0 input-border">
                <img src={process.env.PUBLIC_URL + "/Assets/images/icons/call.svg"} alt="" />
              </span>
              <Field
                type="text"
                className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                name="phoneNumber"
                placeholder="Phone Number"
                maxLength={14}
                onChange={(e) => handlePhoneNumberChange(e, setFieldValue)}
              />
            </div>
          </div>
          <ErrorMessage name="phoneNumber" component={TextError} />

          <div className="mb-1 mt-3 form-check text-start">
            <Field
              type="checkbox"
              className="form-check-input"
              name="termsCond"
            />
            <label className="form-check-label text-secondary">
              I accept&nbsp;
              <a className="text-white text-decoration-none" href="https://www.autorank.com/general-terms-and-conditions" target="blank">
                Terms and conditions.
              </a>
            </label>
          </div>
          <ErrorMessage name="termsCond" component={TextError} />

          <button
            type="submit"
            className="btn button-light btn-lg form-control rounded-pill mt-3"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="spinner-border spinner-border-sm me-2 text-dark" role="status" aria-hidden="true"></span>
            ) : (
              "Sign Up"
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default RegistrationForm; 