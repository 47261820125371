import { createSlice } from '@reduxjs/toolkit';

export const stateSlice = createSlice({
  name: 'state',
  initialState: {
    proSubscriptionModel: false,
    subscriptionYearlyItem: "",
    subscriptionSelectPlan: false,
    guestyModalVisible: false,
    hostawayModalVisible: false,
    userSelectedPlanStatus: false,
    userSelectedSubscriptionListing: {},
    selectedProperties: [],
    freeListingAddButtonLoading: false,
    saveCardButtonLoading: false,
    disabledButton: false,
    freeProperty: [],
    expiredListing: [],
    isPropertyListingLoader: false,
    cardVisible: false,
    checked: true,
    isPaymentLoading: false,
    disabledSidebarButton: false,
    selectPlanLoader: false,
  },
  reducers: {
    setProSubscriptionModel: (state, action) => {
      state.proSubscriptionModel = action.payload;
    },
    setSubscriptionYearlyData: (state, { payload }) => {
      state.subscriptionYearlyItem = payload;
    },
    setSubscriptionSelectPlan: (state, action) => {
      state.subscriptionSelectPlan = action.payload;
    },
    setGuestyModalVisible: (state, action) => {
      state.guestyModalVisible = action.payload;
    },
    setHostawayModalVisible: (state, action) => {
      state.hostawayModalVisible = action.payload;
    },
    setSelectedPlanListing(state, action) {
      state.userSelectedPlanStatus = true;
      state.userSelectedSubscriptionListing = action.payload;
    },
    setSelectedProperties(state, action) {
      state.selectedProperties = action.payload;
    },

    setFreeListingAddButtonLoading: (state, action) => {
      state.freeListingAddButtonLoading = action.payload;
    },
    setSaveCardButtonLoading: (state, action) => {
      state.saveCardButtonLoading = action.payload;
    },

    setDisabledButton: (state, action) => {
      state.disabledButton = action.payload;
    },
    setFreeProperty: (state, action) => {
      state.freeProperty = action.payload;
    },
    setExpiredListing: (state, action) => {
      state.expiredListing = action.payload;
    },
    setIsPropertyListingLoader: (state, action) => {
      state.isPropertyListingLoader = action.payload;
    },
    setCardVisible: (state, action) => {
      state.cardVisible = action.payload;
    },
    setChecked: (state, action) => {
      state.checked = action.payload;
    },
    setIsPaymentLoading: (state, action) => {
      state.isPaymentLoading = action.payload;
    },
    setDisabledSidebarButton: (state, action) => {
      state.disabledSidebarButton = action.payload;
    },
    setSelectPlanLoader: (state, action) => {
      state.selectPlanLoader = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProSubscriptionModel, setSubscriptionSelectPlan, setGuestyModalVisible, setHostawayModalVisible, setSelectedPlanListing,
  setSelectedProperties, setFreeListingAddButtonLoading, setSaveCardButtonLoading, setDisabledButton, setSubscriptionPlanModalVisible, setFreeProperty,
  setExpiredListing, setIsPropertyListingLoader, setCardVisible, setSubscriptionYearlyData, setChecked,
  setIsPaymentLoading, setDisabledSidebarButton, setSelectPlanLoader } = stateSlice.actions;

export default stateSlice.reducer
