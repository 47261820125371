import { confirmDialog } from 'primereact/confirmdialog';

const ActionDialog = ({ title, message, onAccept, acceptLabel = "Proceed", rejectLabel = "Cancel", hasHeader, imageUrl, subtitle, extraInfo, danger }) => {
  confirmDialog({
    header: hasHeader && (
      <div className="dialog-header">
        {imageUrl && (
          <img
            className="propertyImg me-2"
            src={imageUrl}
            onError={(e) => {
              e.target.src = process.env.PUBLIC_URL + "/Assets/images/img_placeholder.svg";
            }}
            alt="Header"
          />
        )}
      </div>
    ),
    message: (
      <div className="mx-3 my-2 text-center">
        {subtitle && (
          <h6 className="fs-18 text-navy mt-3 exMedium">
            {subtitle}
          </h6>
        )}
        <h5 className="exDemiBold fs-2 text-center text-navy my-2">
          {danger ? (<i className="pi pi-exclamation-circle"></i>) : (<i className="pi pi-exclamation-triangle"></i>)}
          &nbsp; {title}
        </h5>
        <p className="text-navy-light text-center w-75 m-auto">
          {message}
        </p>
        {extraInfo && (
          <p className="text-navy-light text-center w-75 m-auto mt-3">
            <i className="pi pi-info-circle me-2 text-secondary fw-bold" />
            {extraInfo}
          </p>
        )}
      </div>
    ),
    closable: false,
    dismissableMask: true,
    defaultFocus: "accept",
    accept: onAccept,
    acceptLabel: acceptLabel,
    rejectLabel: rejectLabel
  });
};

export default ActionDialog;