import PropertySyncConfirm from "../../../Components/Dialogs/sync";
import { useSync } from "../../../contexts/sync-context";
import ColumnActionsButton from './button';

const ColumnSync = ({ data }) => {
  const { syncDisabled, syncProperty } = useSync();

  const handleSync = () => {
    PropertySyncConfirm({ onConfirm: () => syncProperty({ recordIds: [data.recordId] }) });
  };

  return (
    <ColumnActionsButton
      rowData={data}
      icon="pi-sync"
      tooltip="Sync"
      onClick={handleSync}
      disabled={syncDisabled}
    />
  );
}

export default ColumnSync;
