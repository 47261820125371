import { useQueryClient } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Freeicon from '../../Assets/images/free-icon.svg';
import PropertyListingModal from "../../Components/PropertyListing/PropertyListingModal";
import { useAddListings } from "../../hooks/mutations/listings";
import { useCheckConnection } from "../../hooks/queries/auth";
import { useListings } from "../../hooks/queries/listings";
import { useCheckSubscription } from "../../hooks/queries/payment";
import { setSelectedListings, setVisible } from "../../Redux/Features/addListingsReducer/addListingsSlice";
import { capitalize } from "../../Utils/constant/commonFunction";
import ListingCard, { SkeletonListingCard } from "./listing-card";
import { trialLength } from "../../Utils/constant";

const AddListingsDialog = () => {
  const queryClient = useQueryClient();

  const { visible, selectedListings } = useSelector((state) => state.AddListings);

  const initialSelectedStatus = { name: "New listings", code: "inactive" };

  const addListingsMutation = useAddListings();

  const [selectedStatus, setSelectedStatus] = useState(initialSelectedStatus);
  const [selectedPms, setSelectedPms] = useState();
  const [pmsOptions, setPmsOptions] = useState([]);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [mutated, setMutated] = useState(false);

  const shouldFetch = selectedPms?.code !== undefined && mutated;

  const getAllInactiveProperties = useListings({ status: 'inactive' });
  const getAllActiveProperties = useListings({ status: 'active' });
  const getProperties = useListings({ status: selectedStatus?.code, dataSource: selectedPms?.code }, shouldFetch);

  const connectedPms = useCheckConnection();
  const checkSubscription = useCheckSubscription();

  const inactiveProperties = getAllInactiveProperties?.data?.result;
  const totalInactiveProperties = getAllInactiveProperties?.data?.result?.length;
  const totalActiveProperties = getAllActiveProperties?.data?.result?.length;
  const subscription = checkSubscription?.data?.result;
  const properties = getProperties?.data?.result;

  useEffect(() => {
    if (visible) {
      setMutated(properties?.length > 0);
      addListingsMutation.mutate(null,
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries("listings");
            await getProperties.refetch();
            setMutated(true);
          }
        }
      );
    }
  }, [visible]);

  useEffect(() => {
    if (shouldFetch) {
      getProperties.refetch();
    }
  }, [selectedPms?.code, selectedStatus?.code, shouldFetch]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (connectedPms?.data?.result) {
      const pmsObject = Object.keys(connectedPms?.data?.result).map((item) => ({ name: capitalize(item), code: item }));
      if (pmsObject?.length > 1) {
        setPmsOptions([{ name: "All", code: "" }, ...pmsObject]);
        setSelectedPms({ name: "All", code: "" });
      } else {
        setPmsOptions(pmsObject);
        setSelectedPms(pmsObject[0]);
      }
    }
  }, [connectedPms?.data?.result]);

  const closeAddNewListing = () => {
    dispatch(setVisible(false));
    dispatch(setSelectedListings([]));
    setSelectedStatus(initialSelectedStatus);
  }

  const toggleSelectAll = (e) => {
    if (selectedListings.length === totalInactiveProperties) {
      dispatch(setSelectedListings([]));

    } else {
      dispatch(setSelectedListings(inactiveProperties?.map((item) => item?.id)));
    }
  }

  const handleCheckbox = (e, item) => {
    if (e.target.checked) {
      dispatch(setSelectedListings([...selectedListings, item?.id]));
    } else {
      dispatch(setSelectedListings(selectedListings.filter((id) => id !== item?.id)));
    }
  }

  // const manageSidebarModal = () => {
  //   setConfirmationVisible(true);
  // }

  const header = (
    <>
      <div className="p-4 pb-3">
        <div className="bothbtn gap-2">
          <h4 className="exDemiBold " style={{ margin: "unset" }}> Connected PMS:</h4>
          <div>
            <Dropdown
              value={selectedPms}
              onChange={(e) => setSelectedPms(e.value)}
              options={pmsOptions}
              optionLabel="name"
              placeholder="Select PMS"
              className="btn guesty-btn rounded-pill display-center listing_type"
            />
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 bothbtn">
          <Dropdown
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.value)}
            options={[
              { name: "New listings", code: "inactive" },
              { name: "Added listings", code: "active" },
            ]}
            optionLabel="name"
            placeholder="Select listings"
            className="btn btn-outline-secondary rounded-pill display-center listing_type"
          />

          {selectedStatus?.code === "inactive" && totalInactiveProperties > 0 && (
            <>
              <Button
                className="btn font-normal text-purple button btn-purple-outline"
                label={selectedListings.length === totalInactiveProperties ? "Deselect All" : "Select All"}
                onClick={(e) => toggleSelectAll(e)}
              />
            </>
          )}
        </div>
      </div>
    </>
  );

  const footerContent = (
    <div className="p-4 pt-3 border-top">
      <div className="freeicon-outer">
        {selectedStatus?.code === "inactive" && (
          subscription?.freeTrial ? (
            <div className='freeicon-inner' >
              <div className='freeicon-content'>
                <img src={Freeicon} alt="" />
                <div className="freecontent-inner text-center mb-0 fs-12">
                  <p>{selectedListings.length}/{totalInactiveProperties}</p>
                  <p>Free</p>
                </div>
              </div>
              <p>
                {subscription?.validPaymentMethod ? (
                  <>
                    Enjoy a {trialLength}-day free trial! After the trial period, you will be automatically charged.
                    {!!subscription?.trialDaysLeft && ` (${subscription.trialDaysLeft} days left)`}
                  </>
                ) : `Add a payment method to enjoy a ${trialLength}-day free trial on selected listings!`}
              </p>
            </div>
          ) : (
            <div>
              <p className={selectedListings.length + totalActiveProperties > subscription?.quantity && 'text-red'}>
                Current plan: {selectedListings.length + totalActiveProperties}/{subscription?.quantity}
              </p>
            </div>
          )
        )}

        <div>
          {!subscription?.validPaymentMethod && selectedStatus?.code === "inactive" ? (
            <Link
              to={process.env.REACT_APP_STRIPE_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="btn button-purple rounded-pill font-normal"
            >
              Add payment
              <i className="pi pi-external-link ms-2" style={{ fontSize: "11px" }}></i></Link>
          ) : selectedStatus?.code === "inactive" && totalInactiveProperties > 0 && selectedListings?.length > 0 ? (
            <Button
              className="btn button-purple rounded-pill font-normal"
              onClick={() => setConfirmationVisible(true)}
            >
              {!subscription?.freeTrial && selectedListings?.length + totalActiveProperties > subscription?.quantity ? "Add & Upgrade Plan" : "Add Listings"}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );

  const contentBody = (
    <div className="p-3">
      {selectedStatus?.code === "active" &&
        (getProperties.isPending || !shouldFetch ? (
          <div className="skeleton-container">
            {Array.from({ length: 6 }).map((_, index) => (
              <SkeletonListingCard key={index} />
            ))}
          </div>
        ) : properties?.length > 0 ? (
          properties?.map((item, index) => (
            <ListingCard
              key={index}
              item={item}
              itemClass="border-purple"
            />
          ))
        ) : (
          <div className="empty-state">
            <p className="my-3">No added listings found!</p>
          </div>
        ))}
      {
        selectedStatus?.code === "inactive" &&
        (getProperties.isPending || !shouldFetch ? (
          <div className="skeleton-container">
            {Array.from({ length: 6 }).map((_, index) => (
              <SkeletonListingCard key={index} />
            ))}
          </div>
        ) : properties?.length > 0 ? (
          properties?.map((item, index) => {
            let itemClass = "";
            let checkboxitemClass = "";
            let ItemdiscountClass = "";

            if (selectedListings.includes(item?.id)) {
              if (subscription?.freeTrial) {
                itemClass = "border-green";
                checkboxitemClass = "green-checkbox";
                ItemdiscountClass = "discountclassblock";
              } else {
                itemClass = "border-purple";
              }
            }

            return (
              <ListingCard
                key={index}
                item={item}
                showCheckbox={true}
                itemClass={itemClass}
                checkboxitemClass={checkboxitemClass}
                ItemdiscountClass={ItemdiscountClass}
                onCheckboxChange={(e, item) => handleCheckbox(e, item)}
                selectedListings={selectedListings}
              />
            );
          })
        ) : (
          <div className="empty-state">
            <p className="my-3">No new listings found!</p>
          </div>
        ))
      }
    </div>
  );

  return (
    <>
      <Dialog
        className="rounded-17 add_listing with-border"
        header={header}
        visible={visible}
        onHide={() => closeAddNewListing()}
        footer={footerContent}
        dismissableMask
      >
        {contentBody}
      </Dialog>
      <PropertyListingModal
        confirmationVisible={confirmationVisible}
        setConfirmationVisible={setConfirmationVisible}
        closeAddNewListing={closeAddNewListing}
        getProperties={getProperties}
      />
    </>
  );
};

export default AddListingsDialog;
