import React from 'react';

import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import PropertySyncConfirm from '../../Dialogs/sync';
import ActionItem from './action-item';
import { useSync } from '../../../contexts/sync-context';

const SyncNow = ({ properties, isPaid }) => {
  const { syncDisabled, syncProperty } = useSync();

  const handleSync = () => {
    PropertySyncConfirm({ onConfirm: () => syncProperty({ recordIds: properties})});
  };

  return (
    <>
      <ActionItem
        label="Sync"
        icon="pi pi-sync"
        onClick={handleSync}
        disabled={syncDisabled || !isPaid}
        background="mint-31"
      />
    </>
  );
};

export default SyncNow;
