import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { Dropdown } from "primereact/dropdown";

function BarChart(props) {
  const { bookingPaceMomAirbnb } = props;
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const [selectedWeeklyData, setSelectedWeeklyData] = useState({
    name: "This week",
    code: "TW",
  });
  const weeklyData = [
    { name: "This week", code: "TW" },
    { name: "Last week", code: "LW" },
  ];

  useEffect(() => {
    if (bookingPaceMomAirbnb && bookingPaceMomAirbnb?.currentWeekData) {
      generateDataOptions(bookingPaceMomAirbnb?.currentWeekData);
    }
  }, [bookingPaceMomAirbnb]);

  const generateDataOptions = (weekData) => {
    if (weekData) {
      const keys = [],
        values = [];
      for (const [key, value] of Object?.entries(weekData)) {
        keys.push(key);
        values.push(value);
      }

      const data = {
        labels: keys,
        datasets: [
          {
            label: "Sales",
            data: values,
            backgroundColor: [
              "rgba(126,108,244,0.4)",
              "rgba(126,108,244,0.4)",
              "rgba(126,108,244,0.4)",
              "rgba(126,108,244,0.4)",
              "rgba(126,108,244,0.4)",
              "rgba(126,108,244,0.4)",
              "rgba(126,108,244,0.4)",
            ],
            width: [10, 20, 10],
          },
        ],
      };

      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.4,
        maxBarThickness: 30,
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false, // Hides the legend
          },
          // tooltip: {
          //   callbacks: {
          //     label: function (tooltipItem) {
          //       return `Sales: ${tooltipItem.raw}`; // Customize the tooltip label
          //     },
          //   },
          // },
        },
      };
      setChartData(data);
      setChartOptions(options);
    }
  };
  const handleSelection = (value) => {
    setSelectedWeeklyData(value);
    if (value.code === "TW") {
      generateDataOptions(bookingPaceMomAirbnb?.currentWeekData);
    } else {
      generateDataOptions(bookingPaceMomAirbnb?.lastWeekData);
    }
  };
  return (
    <div className="booking_pace card p-3 border-0 shadow-sm rounded-17 h-100">
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <h5 className=" exDemiBold fs-22 mb-0">
          Booking Pace Momentum (Airbnb)
        </h5>
        <div className="dropdown">
          <div className="card flex justify-content-center border-0">
            <Dropdown
              value={selectedWeeklyData}
              onChange={(e) => handleSelection(e.value)}
              options={weeklyData}
              optionLabel="name"
              placeholder="Select Week"
              className="w-full md:w-14rem rounded-pill border ps-2"
            />
          </div>
        </div>
      </div>
      <Chart
        type="bar"
        data={chartData}
        options={chartOptions}
        className="booking_chart h-100"
      />
    </div>
  );
}

export default BarChart;
