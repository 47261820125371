import React from "react";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "../Pages/Authentication/ForgotPassword";
import ResetPassword from "../Pages/Authentication/ForgotPassword/ResetPassword";
import SignUp from "../Pages/Authentication/Register";
import SignIn from "../Pages/Authentication/SignIn";
import GoogleCallback from "../Pages/Authentication/SignIn/GoogleCallback";
import Dashboards from "../Pages/Dashboards";
import PageNotFound from "../Pages/Page_Not_Found/PageNotFound";
import PropertyListing from "../Pages/PropertyListing";
import UserList from "../Pages/Users/UserList";
import * as Constants from "./Constant";
import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
// import Logs from "../Pages/Logs";
import Connection from "../Pages/Connection";
import Insights from "../Pages/Insights";
import Notification from "../Pages/Notification";
import UnauthorizedPage from "../Pages/Page_Not_Found/unauthorized-page";
import PropertyDetails from "../Pages/PropertyDetail";
import Settings from "../Pages/Settings";
import UpdatedHistory from "../Pages/UpdatedHistory";
import CheckAuthRoutes from "./ProtectedRoutes/CheckAuthRoutes";

const Routing = () => {

  return (
    <>
      <Routes>
        <Route element={<CheckAuthRoutes />} >
          <Route path={Constants.SIGN_IN} element={<SignIn />} />
          <Route path={Constants.CALLBACK} element={<GoogleCallback />} />
          <Route path={Constants.INDEX} element={<SignIn />} />
          <Route path={Constants.SIGN_UP} element={<SignUp />} />
          <Route path={Constants.FORGET_PASSWORD} element={<ForgotPassword />} />
          <Route path={Constants.RESET_PASSWORD} element={<ResetPassword />} />
        </Route>
        <Route path={Constants.PAGE_NOT_FOUND} element={<PageNotFound />} />
        <Route path={Constants.UNAUTHRIZED_USER} element={<UnauthorizedPage />} />

        {/* Protected routes */}
        <Route element={<ProtectedRoutes />}>
          <Route path={Constants.DASHBOARD} element={<Dashboards />} />
          <Route path={Constants.CONNECTION} element={<Connection />} />
          <Route path={Constants.LISTING} element={<PropertyListing />} />
          <Route
            element={
              <ProtectedRoutes requiredRole={1} />
            }
          >
            {/* <Route path={Constants.LOGS} element={<Logs />} /> */}
            <Route path={Constants.USERLIST} element={<UserList />} />
          </Route>

          <Route path={Constants.SETTINGS} element={<Settings />} />
          <Route path={Constants.INSIGHTS} element={<Insights />} />
          <Route path={Constants.NOTIFICATION} element={<Notification />} />
          <Route path={Constants.UPDATED_HISTORY} element={<UpdatedHistory />} />
          <Route path={Constants.PROPERTY_DETAILS} element={<PropertyDetails />} />
        </Route>
      </Routes>
    </>
  );
};

export default Routing;
