const PmsCard = ({
  imageSrc,
  title,
  buttonText,
  onClick,
  isComingSoon = false,
  isExternalLink = false,
}) => {
  const renderButton = () => {
    if (isExternalLink && typeof onClick === 'string') {
      return (
        <a
          style={{ textDecoration: "none" }}
          href={onClick}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="request-btn btn rounded-pill px-3">
            {buttonText}
          </button>
        </a>
      );
    }

    return (
      <button
        className="Powerup-btn btn rounded-pill px-3"
        onClick={onClick}
      >
        {buttonText}
      </button>

    );
  };

  return (
    <div className="col-xl-3 col-lg-6 col-md-6">
      <div className="card p-2">
        <img
          src={process.env.PUBLIC_URL + imageSrc}
          className="card-img-top img-fluid m-auto"
          alt={title}
        />
        <div className="card-body">
          <h4 className="text-subtitle1 card-title exMedium">{title}</h4>
          {renderButton()}
        </div>
        {isComingSoon && (
          <div className="card-banner position-absolute start-0">
            <p className="px-2 py-1 fs-12 exMedium text-purple purple-15">
              Coming Soon
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PmsCard;
